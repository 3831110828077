import { Injectable } from "@angular/core";
@Injectable()
export class PaginatorHelper {
  constructor() {
  }

  public movePaginatorCluster() {
    setTimeout(() => {
      const paginator: any = document.querySelector('.p-paginator-top');
      const parent: any = document.querySelector('.analytics-map-container');
      const table: any = document.querySelector('.data-table-wrapper');
      if (paginator && paginator.parentElement && parent && table) {
        parent.insertBefore(paginator.parentElement, table);
      }
    }, 0);
  }

  public sortDataTable(event, data) {
    data.sort((data1, data2) => {
      const value1 = data1[event.sortField];
      const value2 = data2[event.sortField];
      const result = PaginatorHelper.sortTable(value1, value2);
      return (event.sortOrder * result);
    });
  }

  public sortDataTableNoLazy(event, data) {
    return data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      const result = PaginatorHelper.sortTable(value1, value2);
      return (event.order * result);
    });
  }

  private static sortTable(value1: any, value2: any) {
    let result = null;
    if ((value1 == null || value1 === 'NA') && value2 != null) {
      result = -1;
    } else if (value1 != null && (value2 == null || value2 === 'NA')) {
      result = 1;
    } else if ((value1 == null && value2 == null) || (value1 === 'NA' && value2 === 'NA')) {
      result = 0;
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }
    return result;
  }
}
