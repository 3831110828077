<div class="tiles-list">
 <div class="tiles-list-wrapper" *ngIf="activeMarkers">
   <div *ngFor="let marker of activeMarkers" class="tile">
    <div class="tile-main-content">
      <div class="image">
        <img [src]="marker['file']" draggable="false"/>
      </div>
    <div class="active-markers__rating">
        <div *ngIf="marker['rating'] === 1">
            <span>
                <span class="fa fa-star star-active" style="color:rgb(255, 0, 0);"></span>
                <span class="fa fa-star star-inactive" style="color:rgb(145, 145, 145);"></span>
                <span class="fa fa-star star-inactive" style="color:rgb(145, 145, 145);"></span>
            </span>
        </div>
        <div *ngIf="marker['rating'] === 2">
            <span>
                <span class="fa fa-star star-active" style="color:rgb(230, 213, 9);"></span>
                <span class="fa fa-star star-active" style="color:rgb(230, 213, 9);"></span>
                <span class="fa fa-star star-inactive" style="color:rgb(145, 145, 145);"></span>
            </span>
        </div>
        <div *ngIf="marker['rating'] === 3">
            <span>
                <span class="fa fa-star star-active" style="color:rgb(7, 178, 0);"></span>
                <span class="fa fa-star star-active" style="color:rgb(7, 178, 0);"></span>
                <span class="fa fa-star star-active" style="color:rgb(7, 178, 0);"></span>
            </span>
        </div>
    </div>
      <div class="active-marker__title">
        <span>Marker:</span>{{marker['name']}}
      </div>
      <div class="active-marker__date">
        <span>Created:</span> {{marker['created_at'] | formatDatePipe}}
      </div>
     </div>
    </div>
   </div>
 </div>
