import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable()
export class MobileAppService {

  constructor(
    private httpService: HttpClient,
    private configService: ConfigService,
  ) {
  }

  public getAppList() {
    const url = this.configService.config.getAppList;
    return this.httpService.get(url);
  }

  public getAppByAccountId(id) {
    const url = this.configService.config.getAppByAccountId;
    return this.httpService.get(url + id);
  }

  public createMobileApp(app) {
    delete app.id;
    const url = this.configService.config.createMobileApp;
    return this.httpService.post(url, { app });
  }

  public updateMobileApp(app) {
    const url = this.configService.config.updateMobileApp;
    return this.httpService.put(url, { app });
  }

  public deleteMobileApp(id) {
    const url = this.configService.config.deleteMobileApp;
    return this.httpService.delete(url + id);
  }

  public getScanTargetsList(id) {
    const url = this.configService.config.getScanTargetByAccount;
    return this.httpService.get(url + id);
  }

  public createMobileAppItem(target) {
    delete target.name;
    const url = this.configService.config.createScanTarget;
    return this.httpService.post(url, { scan_target: target });
  }

  public updateMobileAppItem(target) {
    delete target.name;
    const url = this.configService.config.updateScanTarget;
    return this.httpService.put(url, { scan_target: target });
  }

  public deleteMobileAppItem(id) {
    const url = this.configService.config.deleteScanTarget;
    return this.httpService.delete(url + id);
  }

  public setScanTargetsOrder(targets) {
    const url = this.configService.config.setScanTargetsOrder;
    return this.httpService.put(url, { scan_target_positions: targets });
  }
}
