import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home';
import { HomeResolver } from './resolvers/home-resolver.service';
import { HomeResolverOutService } from './resolvers/home-resolverOut.service';
import { InstancesResolver } from './resolvers/instances-resolver.service';
import { AccountsResolver } from './resolvers/accounts-resolver.service';
import { UsersResolver } from './resolvers/users-resolver.service';
import { UserResolver } from './resolvers/user-resolver.service';
import { AccountResolver } from './resolvers/account-resolver.service';
import { InstanceResolver } from './resolvers/instance-resolver.service';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule),
  },
  // {
  //   path: 'sign_up',
  //   loadChildren: './components/sign-up/sign-up.module#SignUpModule',
  // },
  {
    path: 'trial',
    loadChildren: () => import('./components/sign-up/sign-up.module').then(m => m.SignUpModule),
  },
  {
    path: 'forgot',
    loadChildren: () => import('./components/forgotPassword/forgotPassword.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'createpassword/:id/:hash',
    loadChildren: () => import('./components/createPassword/createPassword.module').then(m => m.CreatePasswordModule),
  },
  {
    path: 'desktop_version',
    loadChildren: () => import('./components/desktopVersion/desktopVersion.module').then(m => m.DesktopVersionModule),
  },
  {
    path: 'support',
    loadChildren: () => import('./components/support-non-register/support-non-register.module').then(m => m.SupportNonRegisterModule),
  },
  {
    path: 'home',
    component: HomeComponent,
    resolve: [HomeResolver],
    children: [
      {
        path: '',
        pathMatch: 'full',
        resolve: [HomeResolverOutService],
        children: [],
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'instances',
        loadChildren: () => import('./components/instances/instances.module').then(m => m.InstancesModule),
        resolve: [InstancesResolver],
      },
      {
        path: 'accounts',
        loadChildren: () => import('./components/accounts/accounts.module').then(m => m.AccountsModule),
        resolve: [AccountsResolver],
      },
      {
        path: 'users',
        loadChildren: () => import('./components/users/users.module').then(m => m.UsersModule),
        resolve: [UsersResolver],
      },
      {
        path: 'createinstance',
        loadChildren: () => import('./components/instance/instance.module').then(m => m.InstanceModule),
        resolve: [InstanceResolver],
      },
      {
        path: 'createaccount',
        loadChildren: () => import('./components/account/account.module').then(m => m.AccountModule),
        resolve: [AccountResolver],
      },
      {
        path: 'createuser',
        loadChildren: () => import('./components/user/user.module').then(m => m.UserModule),
        resolve: [UserResolver],
      },
      {
        path: 'media',
        loadChildren: () => import('./components/media/media.module').then(m => m.MediaModule),
      },
      {
        path: 'campaigns',
        loadChildren: () => import('./components/campaigns/campaigns.module').then(m => m.CampaignsModule),
      },
      {
        path: 'support',
        loadChildren: () => import('./components/support/support.module').then(m => m.SupportModule),
      },
      {
        path: 'developers',
        loadChildren: () => import('./components/developers/developers.module').then(m => m.DevelopersModule),
      },
      {
        path: 'analytics',
        loadChildren: () => import('./components/analytics/analytics.module').then(m => m.AnalyticsModule),
      },
      {
        path: 'reporting',
        loadChildren: () => import('./components/analytics2/analytics.module').then(m => m.Analytics2Module),
      },
      {
        path: 'reports',
        loadChildren: () => import('./components/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'budgets',
        loadChildren: () => import('./components/budgets/budgets.module').then(m => m.BudgetsModule),
      },
      {
        path: 'applications',
        loadChildren: () => import('./components/applications/applications.module').then(m => m.ApplicationsModule),
      },
      {
        path: 'white-label',
        loadChildren: () => import('./components/white-label/white-label.module').then(m => m.WhiteLabelModule),
      },
      {
        path: 'games',
        loadChildren: () => import('./components/games/games.module').then(m => m.GamesModule),
      }
    ],
  },
  {
    path: '',
    pathMatch: 'full',
    resolve: [HomeResolverOutService],
    children: [],
  },
  {
    path: '404',
    loadChildren: () => import('./components/page404/page404.module').then(m => m.Page404Module),
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutes {
}
