import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { SystemFiltersService } from '../../services/systemFilters.service';
import { AuthService } from '../../services/auth.service';
import { CampaignService } from '../../services/campaign.service';
import { LocationService } from '../../services/location.sevice';
import { CampaignsService } from '../../services/campaigns.service';
import { MarkerbasedService } from '../../services/markerbased.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'activeMarkers',
    styleUrls: ['./active-markers.component.scss'],
    templateUrl: './active-markers.component.html',
  })

  export class ActiveMarkersComponent implements OnInit{
    private user: any = null;
    private authSubscription: Subscription = null;
    private systemFilter: any = null;
    public activeMarkers: any[];
    private markerbasedService: MarkerbasedService;
    @Input() campaignId;

    constructor(
      private authService: AuthService,
      private campaignsService: CampaignsService,
      private campaignService: CampaignService,
      private systemFiltersService: SystemFiltersService,
      private router: Router
    ) { 

    }

    public ngOnInit() {
      this.user = this.authService.user;
      this.systemFilter = this.systemFiltersService.systemFilter;
      this.authSubscription = this.authService.user$.subscribe(
        (res: any) =>{
          this.user.res;
        });

        this.campaignId = this.campaignService.selectedId


        this.campaignsService.getCampaignMarkers(this.campaignId).subscribe(
          (res: any) =>{
            this.activeMarkers = []
            for (var i=0; i < res.data.activeMarkers.length; i++) {
              this.activeMarkers.push(res.data.activeMarkers[i])
            }
            console.log(this.activeMarkers)
          });
    }

  }
