import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class AccountsService {
  public accounts: any = [];
  public accountsList: any = [];

  constructor(
    private httpService: HttpClient,
    private configService: ConfigService,
  ) {
  }

  public getAccounts() {
    return this.httpService.get(this.configService.config.getAccounts).pipe(
      map(
      (res: any) => {
        if (res.message && res.message === 'success') {
          this.accounts = res.data;
        }
        return res;
      }));
  }
}
