import { Injectable } from "@angular/core";
@Injectable()
export class WindowResizeMobile {
  public mobileStyle: boolean = false;

  constructor() {
  }

  public onResize() {
    this.mobileStyle = document.documentElement.clientWidth <= 768;
    this.mobileStyle ? WindowResizeMobile.mobileValue() : WindowResizeMobile.resetValue();
  }

  public static resetValue() {
    document.body.style.minHeight = '740px';
    document.body.style.minWidth = '1200px';
  }

  public static mobileValue() {
    document.body.style.minHeight = '0';
    document.body.style.minWidth = '0';
  }
}
