import { Component, OnDestroy } from '@angular/core';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Proof } from '../../helpers/proof';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { UploadFileToS3 } from '../../helpers/uploadFileToS3';
import { Location } from '@angular/common';
import { MarkerbasedService } from '../../services/markerbased.service';
import { CampaignService } from '../../services/campaign.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'webArQrCode',
  styleUrls: ['webArQrCode.component.scss'],
  templateUrl: 'webArQrCode.component.html',
})

export class WebArQrCodeComponent {
  public msgs: Message[];
  public isProd: boolean;
  public campaignSubscription;
  public campaignId

  constructor(
    private location: Location,
    private messageService: MessageService,
    public qrCode: Proof,
    private formBuilder: UntypedFormBuilder,
    private uploadFileToS3: UploadFileToS3,
    private markerbasedService: MarkerbasedService,
    private campaignService: CampaignService,
  ) {
    this.isProd = environment.ENV === 'prod';
    this.msgs = [];
  }

  public openQRCodePopup(id) {
    this.campaignService.selectedId = id
    this.campaignId = id
    this.qrCode.loading = true;
    this.qrCode.currentStateProof = 'campaign';
    if (!this.campaignService.campaign) {
      this.campaignService.getCampaign(id).subscribe(
        (res: any) => { this.logicGenerateData(id); }
      )
    } else {
      this.logicGenerateData(id);
    }
  }

  private logicGenerateData(id) {
    const campaign = this.campaignService.campaign.data;
    const link = campaign.webar_app && campaign.webar_app.domain
      ? `https://${campaign.webar_app.domain}?campaign=${id}` + (this.isProd ? '' : `&env=${environment.ENV}`)
      : `https://webar${this.isProd ? '' : '-' + environment.ENV}.realityblu.com?campaign=${id}` + (this.isProd ? '' : `&env=${environment.ENV}`);
    this.campaignService.generateShortUrl(link, campaign.account_id).subscribe((res: any) => {
      if (res.url) {
        this.qrCode.visibleQRData = res.short_url;
        this.qrCode.linkShare = res.short_url;
        this.qrCode.visibleWebArQrCode = true;
        this.qrCode.loading = false;
      }
    });
  }

  public closeQrCodePopup() {
    if (!this.qrCode.saveDataQrCode) {
      this.qrCode.saveDataQrCode = false;
      this.qrCode.visibleWebArQrCode = false;
      this.qrCode.data = { id: null };
      this.qrCode.imageQrCode = '';
      this.qrCode.visibleQRData = '';
      this.qrCode.linkShare = '';
    }
  }

  public sendEmail() {
    this.qrCode.saveDataQrCode = true;
    this.qrCode.visibleWebArQrCode = false;
    this.qrCode.sendEmailForm = this.formBuilder.group({
      email_to: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.uploadFileToS3.emailRegex)]),
      email_subject: new UntypedFormControl(`Proof of ${this.qrCode.data.title} ${this.qrCode.currentStateProof} from RealityBLU`, Validators.required),
      email_notes: new UntypedFormControl(null),
      experience: new UntypedFormControl(this.qrCode.currentStateProof === 'experience' ? this.qrCode.data.id : null),
      sub_experience: new UntypedFormControl(this.qrCode.currentStateProof === 'sub_experience' ? this.qrCode.data.id : null),
      campaign: new UntypedFormControl(this.qrCode.currentStateProof === 'campaign' ? this.qrCode.data.id : null),
      qr_code: new UntypedFormControl(this.qrCode.imageQrCode, Validators.required),
      app_name: new UntypedFormControl(this.qrCode.data.app, Validators.required),
      ios_link: new UntypedFormControl(this.qrCode.mobileAppLinks.ios),
      android_link: new UntypedFormControl(this.qrCode.mobileAppLinks.android),
    });
    this.qrCode.visibleSendEmail = true;
  }

  private generateQrCode() {
    if (!this.qrCode.imageQrCode) {
      setTimeout(() => {
        const canvas: any = document.createElement('canvas');
        canvas.className = 'canvas-qr-code';
        canvas.width = 200;
        canvas.height = 200;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        const img = new Image();
        img.onload = () => {
          ctx.drawImage(img, 10, 10);
          this.qrCode.imageQrCode = canvas.toDataURL('image/png');
          canvas.remove();
        };
        // img.src = document.getElementsByTagName('qrcode')[0].children[1]['src'];
        // console.log(img.src)
      }, 0);
    }
  }

  public shareLink() {
    const el = document.createElement('textarea');
    document.body.appendChild(el);
    el.value = this.qrCode.linkShare;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.messageService.add({severity:'info', summary:'Proof Code', detail: 'Link was copied to the clipboard' });
  }

  private downloadPng() {
    const link: any = document.createElement('a');
    link.style.display = 'none';
    let qrcodeElement = document.getElementById('webArQrCodeImage');
    let canvasElement = qrcodeElement.querySelector('canvas');
    link.href = canvasElement.toDataURL('image/png');
    link.download = `proofing_code_${this.campaignService.campaign.data.campaign_name}.png`;
    link.click();
    link.remove();
  }

  public ngOnDestroy() {
    if (this.campaignSubscription) {
      this.campaignSubscription.unsubscribe();
    }
  }
}
