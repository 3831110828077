import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class IsLoginRouteGuard implements CanActivate {
  private isLogin: boolean = true;

  constructor() {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkIfLoginConsist();
  }

  public checkIfLoginConsist(): boolean {
    return this.isLogin;
  }

  public setIsLogin(isLogin: boolean): void {
    this.isLogin = isLogin;
  }
}
