import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'formatDatePipe',
})
export class FormatDatePipe implements PipeTransform {
  transform(date, check?): any {
    if (date && date !== '') {
      if (moment(date).isValid()) {
        if (check) {
          return moment(date).tz(moment.tz.guess(true)).format('MM/DD/YYYY hh:mm:ss A z');
        }
        return moment(date).format('MM/DD/YYYY');
      }
    }
  }
}
