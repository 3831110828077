import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable()
export class UserResolver implements Resolve<any> {
  constructor(
    private userService: UserService,
    private routerService: Router,
    private authService: AuthService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (route.paramMap.get('id')) {
      return this.userService.getUser(route.paramMap.get('id')).pipe(
        map(
        (res: any) => {
          if (this.authService.accessLevel === 'system') {
            if (res.message && res.message === 'success') {
              this.userService.user = res;
              return true;
            }
            this.routerService.navigate(['/404']);
            return false;
          }
          if (this.authService.accessLevel === 'account') {
            if (res.data.user && res.data.user['account_id']) {
              if (this.authService.accessLevelId === res.data.user.account_id && res.message && res.message === 'success') {
                this.userService.user = res;
                return true;
              }
              this.routerService.navigate(['/404']);
              return false;
            }
            this.routerService.navigate(['/404']);
            return false;
          }
          this.routerService.navigate(['/404']);
          return false;
        }));
    }
    return null;
  }
}
