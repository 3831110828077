import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { WhiteLabelApiService } from './white-label-api'

@Injectable()
export class SystemFiltersService {
  public systemFilter: object = { instance: -1, account: -1, campaign: -1 };
  public systemFilter$: Observable<any> = null;
  private systemFilterSource = new Subject<any>();

  constructor(
    public whiteLabelApi: WhiteLabelApiService,) {
    this.systemFilter$ = this.systemFilterSource.asObservable();
  }

  public setSystemFilter(res) {
    this.systemFilter = res;
    if (this.systemFilter['instance'] === -1) {
      localStorage.removeItem('selectedInstance');
      localStorage.removeItem('selectedAccount');
      localStorage.removeItem('selectedCampaign');
    } else {
      localStorage.setItem('selectedInstance', this.systemFilter['instance']);
      if (this.systemFilter['account'] === -1) {
        localStorage.removeItem('selectedAccount');
        localStorage.removeItem('selectedCampaign');
      } else {
        localStorage.setItem('selectedAccount', this.systemFilter['account']);
        if (this.systemFilter['campaign'] === -1) {
          localStorage.removeItem('selectedCampaign');
        } else {
          localStorage.setItem('selectedCampaign', this.systemFilter['campaign']);
        }
      }
    }
    
    this.systemFilterSource.next(res);
  }

}
