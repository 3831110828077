import { Component, Input } from '@angular/core';

@Component({
  selector: 'loader',
  styleUrls: ['loader.component.scss'],
  template: `
    <div class="loading">
      <div class="spinner">
        <div class="spinner-icon"></div>
        <div class="loading-text">{{text}}</div>
      </div>
    </div>
  `,
})

export class LoaderComponent {
  @Input('text') text: string = 'Loading...';
}
