import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Title } from '../models/title';
import { Observable } from 'rxjs';

@Injectable()
export class HomeService {
  public title$: Observable<any> = null;
  private titleSource = new Subject<Title>();
  public pagePermissions$: Observable<any> = null;
  private pagePermissionsSource = new Subject<any>();
  public noInternetConnectionMode$: Observable<any> = null;
  public noInternetConnectionModeSource = new Subject<void>();
  public isErrorMode$: Observable<any> = null;
  public isErrorModeSource = new Subject<boolean>();

  constructor() {
    this.title$ = this.titleSource.asObservable();
    this.pagePermissions$ = this.pagePermissionsSource.asObservable();
    this.isErrorMode$ = this.isErrorModeSource.asObservable();
    this.noInternetConnectionMode$ = this.noInternetConnectionModeSource.asObservable();
  }

  public setTitle(res) {
    this.titleSource.next(res);
  }

  public setPagePermissions(res) {
    this.pagePermissionsSource.next(res);
  }
}
