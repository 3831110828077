import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService {
  constructor() {
  }

  private hosts = {
    local: 'http://localhost:5000/local-',
    dev: 'https://api-internal-eu.realityblu.com/dev-',
    test: 'https://api-blu-test.realityblu.com/test-',
    stage: 'https://api-blu-stage.arcontentapi.com/stage-',
    prod: 'https://api-blu-prod.realityblu.com/prod-',
  };

  private urls = {
    // overall
    login: `web-auth/login`, // post
    changePassRequest: `web-auth/changePassRequest`, // post
    getNewToken: `web-auth/getNewToken`, // get

    // users
    impersonate: 'web-auth/impersonate', // post
    createUser: `web-users/createUser`, // post
    getUsers: `web-users/getAllUsers/`, // get
    getSearchList: `web-users/getSearchList/`, // post
    getUser: `web-users/getByIdUser/`, // get
    checkPassRequest: 'web-users/checkPassRequest/', // get
    updateUser: `web-users/updateUser`, // put
    setPassword: 'web-users/setPass', // put
    deleteUser: `web-users/deleteUser/`, // delete
    sign_up: `web-users/signUp`, // post
    conversionTrialUser: `web-users/conversionTrialUser`, // post
    acceptConditions: `web-users/acceptTerms`, // post

    // instances
    createInstance: `web-instances/createInstance`, // post
    getInstances: `web-instances/getAll/`, // get
    getInstance: `web-instances/getInstanceById/`, // get
    updateInstance: `web-instances/updateInstance`, // put
    deleteInstance: `web-instances/deleteInstance/`, // delete

    // accounts
    createAccount: `web-accounts/createAccount`, // post
    getAccount: `web-accounts/getAccountById/`, // get
    updateAccount: `web-accounts/updateAccount`, // put
    deleteAccount: `web-accounts/deleteAccount/`, // delete
    getAccounts: `web-accounts/getAllAccount`, // get
    getAccountCounter: `web-accounts/experiences`, // get
    sendUpgradeEmail: `web-accounts/sendUpgradeEmail`, // post

    // campaigns
    createCampaign: `web-campaigns/createNewCampaign`, // post
    getAllCampaigns: `web-campaigns/getAllCampaigns`, // get
    getCampaignById: `web-campaigns/getCampaignById/`, // get
    getCampaignsByAccount: `web-campaigns/getMeta`, // get
    updateCampaign: `web-campaigns/updateCampaign`, // put
    updateCampaignStatus: `web-campaigns/updateCampaignStatus`, // put
    deleteCampaign: `web-campaigns/deleteCampaign/`, // delete
    syncCampaign: `web-campaigns/syncCampaign`, // put
    getCampaignMarkers: `web-campaigns/getCampaignMarkers`,

    // roles
    createRole: `web-roles/createNewRole`, // post
    getRoles: `web-roles/getAllRoles/`, // get
    updateRole: `web-roles/updateRole`, // put
    deleteRole: `web-roles/deleteRole/`, // delete

    // mobile-apps
    getAppList: `web-mobile-apps/getGeneralAppList`, // get
    getAppByAccountId: `web-mobile-apps/getAppByAccountId/`, // get
    createMobileApp: `web-mobile-apps/createApp`, // post
    updateMobileApp: `web-mobile-apps/updateApp`, // put
    deleteMobileApp: `web-mobile-apps/deleteApp/`, // delete

    // webar apps
    getWebarApps: `web-webar/apps`, // get
    assignWebarApp: `web-webar/apps/assign`, // post
    unAssignWebarApp: `web-webar/apps/unassign`, // post
    createWebarApp: `web-webar/apps`, // post
    updateWebarApp: `web-webar/apps`, // put
    deleteWebarApp: `web-webar/apps/`, // delete

    // scan targets
    getScanTargetByAccount: `web-scan-target/getByAccount/`, // get
    createScanTarget: `web-scan-target/create`, // post
    updateScanTarget: `web-scan-target/update`, // put
    deleteScanTarget: `web-scan-target/delete/`, // delete
    setScanTargetsOrder: `web-scan-target/order`, // put

    // locations
    createCampaignLocation: `web-campaign-locations/createCampaignLocation`, // post
    getCampaignLocations: `web-campaign-locations/getCampaignLocations/`, // get
    updateCampaignLocation: `web-campaign-locations/updateCampaignLocation`, // put
    updateCampaignLocationOrder: `web-campaign-locations/updateCampaignLocationOrder`, // put
    deleteCampaignLocation: `web-campaign-locations/deleteCampaignLocation/`, // delete

    // budgets
    createPriceLevel: `web-price-levels/createPriceLevel`, // post
    getAllPriceLevels: `web-price-levels/getAllPriceLevels`, // get
    updatePriceLevel: `web-price-levels/updatePriceLevel`, // put
    deletePriceLevel: `web-price-levels/deletePriceLevel/`, // delete
    createPayment: `web-payments/createPayment`, // post
    getAllPayments: `web-payments/getAllPayments`, // get
    updatePayment: `web-payments/updatePayment`, // put
    calculationPrice: `web-payments/calculationPrice`, // post
    calculationUpgradePrice: `web-payments/calculationUpgradePrice`, // post
    deletePayment: `web-payments/deletePayment/`, // delete
    createPaymentIntents: `web-payments/createPaymentIntents`, // post
    checkPaymentStatus: `web-payments/checkPaymentStatus`, // post
    cancelTransactions: `web-payments/cancelTransactions`, // post
    getAllPurchases: `web-purchases/getAllPurchases`, // get
    purchaseUpdate: `web-purchases/purchaseUpdate`, // put
    assignAmountToAccount: `web-purchases/assignAmountToAccount`, // post
    assignAmountUpdateToAccount: `web-purchases/assignAmountUpdateToAccount`, // put
    assignAmountDeleteFromAccount: `web-purchases/assignAmountDeleteFromAccount/`, // delete


    // remove audio
    convertVideo: `web-portals/convertVideo`, // post

    // permissions
    getPermissions: `web-permissions/getPermissions/`, // get

    // qr-code
    sendEmailQrCode: `web-system/sendProofingCodeMessage`, // post
    // qr-code markerless
    sendEmailQrCodeMarkerless: `web-system/sendMarkerlessProofingCodeMessage`, // post

    // analytics
    analyticsMarkerless: 'web-analytics/analytics/markerless', // post
    analytics: `web-analytics/analytics/data`, // post
    analyticsWorldview: 'web-analytics/analytics/worldview', // post
    analyticsTrialUserReport: `web-analytics/analytics/getLightUsersReports`, // get
    analyticsGetFilter: `web-analytics/analytics/getFilters`, // get
    getAnalyticsWorldview: `web-analytics/analytics/getAnalyticsWorldview`, //post
    getAnalyticsWorldviewByExperience: `web-analytics/analytics/getAnalyticsWorldviewByExperience`, //post
    getAnalyticsMapMarkers: `web-analytics/analytics/getAnalyticsMapMarkers`, //post
    getAnalyticsExperience: `web-analytics/analytics/getAnalyticsExperience`, //post
    getAnalyticsExperienceBrowsers: `web-analytics/analytics/getAnalyticsExperienceBrowsers`, //post
    getAnalyticsExperienceDevices: `web-analytics/analytics/getAnalyticsExperienceDevices`, //post
    getAnalyticsExperienceReferals: `web-analytics/analytics/getAnalyticsExperienceReferals`, //post
    getAnalyticsWorldviewDetail: `web-analytics/analytics/getAnalyticsWorldviewDetail`, //post
    createReport: `web-analytics/analytics/createReport`, //post
    getReportList: `web-analytics/analytics/getReportList`, //get
    addToReport: `web-analytics/analytics/addToReport`, //post
    modifyReport: `web-analytics/analytics/modifyReport`, //post
    deleteReport: `web-analytics/analytics/deleteReport`, //delete
    removeFromGroup: `web-analytics/analytics/removeFromGroup`, //post
    getAccountWorldviewArrays: `web-analytics/analytics/getAccountWorldviewArrays`, //get
    getLeaderBoard: `web-games/getScore`, //get

    // support
    createRequest: `web-support/sendFeedback/`, // post

    // dashboard
    getReport: `web-dashboard/getReportForDashboard`, // get

    // quotas
    getQuotas: `web-quotas/getQuotas/`, // get

    // markerbased
    uploadNewFile: `web-editor-markerbased/markerbased/object/add`, // post
    getMediaFilesList: `web-editor-markerbased/markerbased/objects/getList/`, // get
    getMediaFilesListForPopup: `web-editor-markerbased/markerbased/objects/getListForPopup/`, // get
    updateItem: `web-editor-markerbased/markerbased/object/update`, // put
    deleteItem: `web-editor-markerbased/markerbased/object/delete/`, // delete
    getFreeMarkers: `web-editor-markerbased/markerbased/objects/getFreeMarkers/`, // get
    checkMarkersRating: `web-editor-markerbased/markerbased/object/checkrating/markers/`, // get

    // markerless
    createMarkerlessObject: `web-editor-markerless/markerless/object/create`, // post
    createMarkerlessCategory: `web-editor-markerless/markerless/category/create`, // post
    createMarkerlessSubCategory: `web-editor-markerless/markerless/subCategory/create`, // post
    getMarkerLessCategories: `web-editor-markerless/markerless/categories/get/`, // get
    getMarkerlessSubcategories: `web-editor-markerless/markerless/subCategories/get/`, // get
    getMarkerlessNoCategory: `web-editor-markerless/markerless/objects/getNoCategory/`, // get
    getMarkerlessObjects: `web-editor-markerless/markerless/objects/get/`, // get
    updateMarkerless: `web-editor-markerless/markerless/object/update`, // put
    updateMarkerlessStatus: `web-editor-markerless/markerless/object/updateStatus`, // put
    updateMarkerlessCategory: `web-editor-markerless/markerless/category/update`, // put
    updateMarkerlessSubCategory: `web-editor-markerless/markerless/subCategory/update`, // put
    deleteMarkerlessSubCategory: `web-editor-markerless/markerless/subCategory/delete/`, // delete
    deleteMarkerlessCategory: `web-editor-markerless/markerless/category/delete/`, // delete
    deleteMarkerlessObject: `web-editor-markerless/markerless/object/delete/`, // delete
    deleteFileFromModel: `web-editor-markerless/markerless/object/deleteFile/`,
    convertMarkerlessObject: `web-editor-markerless/markerless/object/convert/`, // delete

    // experiences
    createExperience: `web-experiences/experience/create`, // post
    cloneExperience: `web-experiences/experience/cloneExperience`, // post
    getExperiencesWithoutCampaign: `web-experiences/getExperiences/`, // get
    getRecent: `web-experiences/getRecent/`, // get
    getExperience: `web-experiences/getExperienceById/`, // get
    getExperiencesByCampaign: `web-experiences/getExperienceByCampaignId/`, // get
    uniqueMarkerId: `web-experiences/uniqueExperienceMarker/`, // get
    updateExperience: `web-experiences/updateExperience`, // put
    addExperienceToCampaign: `web-experiences/updateToCampaign`, // put
    deleteExperienceFromCampaign: `web-experiences/deleteExperienceFromCampaign/`, // delete
    deleteExperience: `web-experiences/deleteExperience/`, // delete

    // sub-experiences
    createSubExperience: `web-sub-experiences/createSubExperience`, // post
    getSubByExperienceId: `web-sub-experiences/getSubByExperienceId/`, // get
    getBySubExperienceId: `web-campaign-locations/getBySubExperienceId/`, // get
    updateSubExperience: `web-sub-experiences/updateSubExperience`, // put
    deleteSubExperience: 'web-sub-experiences/deleteSubExperience/', // delete
    changeLocationSubExperience: `web-sub-experiences/changeLocationSubExperience`, // put

    // qr code generator
    qrCodeGenerate: `web-webar/getShortenedLink`, // get

    // splash screens
    postSplash: `web-splash-screen/postSplash`, // post
    getSplashes: `web-splash-screen/getSplashes`, // get
    putSplash: `web-splash-screen/putSplash`, // put
    deleteSplash: `web-splash-screen/deleteSplash`, // delete

    // reticles
    postReticle: `web-reticle/postReticle`, // post
    getReticle: `web-reticle/getReticle`, // get
    putReticle: `web-reticle/putReticle`, // put
    deleteReticle: `web-reticle/deleteReticle`, // delete

    // holotwins
    getHolotwins: `web-holotwin/getHolotwins`, // get
    getHolotwin: `web-holotwin/getHolotwin`, // get
    deleteHolotwin: `web-holotwin/deleteHolotwin`, // delete
    postHolotwin: `web-holotwin/postHolotwin`, // post
    putHolotwin: `web-holotwin/putHolotwin`, // put
    updateHolotwinStatus: `web-holotwin/updateHolotwinStatus`, // put

    // portals
    getPortals: `web-portals/getPortals`, // get
    getPortal: `web-portals/getPortal`, // get
    deletePortal: `web-portals/deletePortal`, // delete
    postPortal: `web-portals/postPortal`, // post
    putPortal: `web-portals/putPortal`, // put
    updatePortalStatus: `web-portals/updatePortalStatus`, // put

    // doors
    postDoor: `web-doors/postDoor`, // post
    getDoors: `web-doors/getDoors`, // get
    putDoor: `web-doors/putDoor`, // put
    deleteDoor: `web-doors/deleteDoor`, // delete

    // lights
    getLights: `web-lights/getLights`, // get
    getLighting: `web-lights/getLighting`, // get
    deleteLights: `web-lights/deleteLights`, // delete
    postLights: `web-lights/postLights`, // post
    putLights: `web-lights/putLights`, // put

    //things
    getThings: `web-things/getThings`, // get
    getThing: `web-things/getThing`, // get
    deleteThings: `web-things/deleteThings`, // delete
    postThings: `web-things/postThings`, // post
    putThings: `web-things/putThings`, // put
    updateThingsStatus: `web-things/updateThingStatus`, // put

    getWhiteLabel: `web-white-label/getWhiteLabel`, // get
    getWhiteLabels: `web-white-label/getWhiteLabels`, // get
    deleteWhiteLabel: `web-white-label/deleteWhiteLabel`, // delete
    postWhiteLabel: `web-white-label/postWhiteLabel`, // post
    putWhiteLabel: `web-white-label/putWhiteLabel`, // put
    updateStatusWhiteLabel: `web-white-label/updateStatus`, // put
    setPermission: `web-white-label/setPermission`, // put

    getGames:  `web-games/getGames`, // post
    getSlugs:  `web-games/getSlugs`, // post
    getGame:  `web-games/getGame`, // get
    putGame:  `web-games/putGame`, // get
    postGame:  `web-games/postGame`, // get
    getPromotions:  `web-games/getPromotions`, // get
    getPromotion:  `web-games/getPromotion`, // get
    postPromotion:  `web-games/postPromotion`, // post
    putPromotion:  `web-games/putPromotion`, // put
    deletePromotion:  `web-games/deletePromotion`, // delete
    deleteGame:  `web-games/deleteGame`, // delete

    // notifications
    getNotifications: `web-notifications/getNotifications`, // get
    getNotificationList: `web-notifications/getNotificationList`, // get
    getNotification: `web-notifications/getNotification`, // get
    postNotifications: `web-notifications/postNotifications`, // post
    putNotifications: `web-notifications/putNotifications`, // post
    deleteNotifications: `web-notifications/deleteNotifications`, // delete

    // emailSets
    getEmailSets: `web-email-sets/getEmailSets`, // get
    getEmailSetsList: `web-email-sets/getEmailSetsList`, // get
    getEmailSet: `web-email-sets/getEmailSet`, // get
    postEmailSets: `web-email-sets/postEmailSets`, // post
    putEmailSets: `web-email-sets/putEmailSets`, // post
    deleteEmailSets: `web-email-sets/deleteEmailSets`, // delete
    cloneEmailSets: `web-email-sets/cloneEmailSets`, // clone

    // emails
    getEmail: `web-emails/getEmail`, // get
    getEmails: `web-emails/getEmails`, // get
    getEmailsList: `web-emails/getEmailsList`, // get
    postEmails: `web-emails/postEmails`, // post
    putEmails: `web-emails/putEmails`, // post
    deleteEmails: `web-emails/deleteEmails`, // delete

    // urls
    getUrls: `web-url/getUrls`, // get
    getUrlsList: `web-url/getUrlsList`, // get
    getUrl: `web-url/getUrl`, // get
    postUrls: `web-url/postUrl`, // post
    putUrls: `web-url/putUrl`, // post
    deleteUrls: `web-url/deleteUrl`, // delete

    //qr config
    getQrConfig: `web-qr-config/getQrConfig`, // get
    getQrConfigs: `web-qr-config/getQrConfigs`, // get
    postQrConfig: `web-qr-config/postQrConfig`, // post
    putQrConfig: `web-qr-config/putQrConfig`, // put
    deleteQrConfig: `web-qr-config/deleteQrConfig`, // delete
    updateStatusQrConfig: `web-qr-config/updateStatus`, // put

  };

  public config = null;

  private generateUrls(host) {
    const urls = { ...this.urls };
    for (const key in urls) {
      urls[key] = host + urls[key];
    }
    return urls;
  }

  public setConfig(env) {
    this.config = this.generateUrls(this.hosts[env]);
  }
}
