import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
declare var Stripe: any;

@Injectable()
export class StripeService {
  private readonly testKey = 'pk_test_Mw1U6XK69t3NQfwJ2ND3BjWd00W99gJlqI';
  private readonly publicKey = 'pk_live_ItkBbQFOQfB2VxIRef3LQfzX00cfdahYzP';
  public stripe = Stripe(environment.ENV === 'prod' ? this.publicKey : this.testKey);
}
