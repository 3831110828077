import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { AuthService } from './auth.service';
import { UploadFileToS3 } from '../helpers/uploadFileToS3';

@Injectable()
export class SupportService {
  constructor(
    private httpService: HttpClient,
    private configService: ConfigService,
    private authService: AuthService,
    private uploadFileToS3: UploadFileToS3,
  ) {
  }

  public sendEmailQrCode(form, isMarkerless) {
    const url = isMarkerless
      ? this.configService.config.sendEmailQrCodeMarkerless
      : this.configService.config.sendEmailQrCode;
    return this.httpService.post(url, form);
  }

  public sendSupportMessage(check, data) {
    data.token = check ? this.authService.token : null;
    const url = this.configService.config.createRequest;
    return this.httpService.post(url, data);
  }

  public acceptConditions(date) {
    const url = this.configService.config.acceptConditions;
    return this.httpService.post(url, { accept_terms_time: date });
  }

  public updateYoutubeVideo() {
    return this.httpService.get(`https://www.googleapis.com/youtube/v3/search?key=${this.uploadFileToS3.youtubeKey}&channelId=${this.uploadFileToS3.youtubeChannelId}&part=snippet,id&order=date&maxResults=10`);
  }

  public getS3YoutubeVideo() {
    return this.httpService.get(`https://${this.uploadFileToS3.albumBucketName}.s3.${this.uploadFileToS3.bucketRegion}.amazonaws.com/youtube-video/infoVideo.json`);
  }
}
