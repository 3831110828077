import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';

@Injectable()
export class LoginService {
  public failAuthorization$: Observable<any> = null;
  public failAuthorizationSource = new Subject<any>();

  constructor(
    private httpService: HttpClient,
    private configService: ConfigService,
  ) {
    this.failAuthorization$ = this.failAuthorizationSource.asObservable();
  }

  public authorization(user, password) {
    const url = this.configService.config.login;
    return this.httpService.post(url, { password, user });
  }

  public sign_up(data) {
    const url = this.configService.config.sign_up;
    return this.httpService.post(url, data);
  }

  public checkPassRequest(id, hash) {
    const url = this.configService.config.checkPassRequest;
    return this.httpService.get(url + id + '/' + hash);
  }

  public setPassword(user) {
    const url = this.configService.config.setPassword;
    return this.httpService.put(url, { user });
  }

  public changePassword(email) {
    const url = this.configService.config.changePassRequest;
    return this.httpService.post(url, { email });
  }
}
