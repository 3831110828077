import { Injectable } from '@angular/core';

@Injectable()
export class ErrorsService {
  private codes: any = [15, 21, 37, 38, 39, 43, 44, 45, 51, 56, 57, 60];

  constructor() {
  }

  public showErrors(res) {
    let message = null;
    if (res.error) {
      if (res.error.code === 20) {
        message = /email/i.test(res.error.message) ? 'please enter a valid email address' : res.error.message;
      } else if (this.codes.includes(res.error.code)) {
        message = res.error.message;
      } else {
        message = this.errors[res.error.code]['message'];
      }
    } else if (res.cause && res.cause.errno === 1452) {
      message = this.errors[62]['message'];
    } else {
      message = this.errors[0]['message'];
    }
    return message;
  }

  private errors: any = {
    0: {
      backendError: '',
      message: 'Something is wrong. Try again',
    },
    1: {
      backendError: 'Can\'t find the active campaign',
      message: 'Can\'t find the active campaign',
    },
    2: {
      backendError: 'More than 1 active campaign found',
      message: 'More than 1 active campaign found',
    },
    3: {
      backendError: 'Invalid input',
      message: 'Invalid input',
    },
    4: {
      backendError: 'Target collection was not created',
      message: 'Target collection was not created',
    },
    5: {
      backendError: 'Error parse response',
      message: 'Something is wrong with cloud recognition service. Please try again later',
    },
    6: {
      backendError: 'Wikitude error',
      message: 'Something is wrong with cloud recognition service. Please try again later',
    },
    7: {
      backendError: 'Can\'t get describe rule',
      message: 'Can\'t get describe rule',
    },
    8: {
      backendError: 'This account have mobile app',
      message: 'This account has mobile app',
    },
    9: {
      backendError: 'You can not delete active campaign',
      message: 'You can not delete active campaign',
    },
    10: {
      backendError: 'Unknown error',
      message: 'Unknown error',
    },
    11: {
      backendError: 'You can not change status this campaign',
      message: 'You can not change status this campaign',
    },
    12: {
      backendError: 'You can not change activation type for this campaign',
      message: 'You can not change activation type for this campaign',
    },
    13: {
      backendError: 'Scheduled campaign mast have start and end date',
      message: 'Scheduled campaign must have start and end date',
    },
    14: {
      backendError: 'Access denied',
      message: 'Access denied',
    },
    16: {
      backendError: 'Account has users',
      message: 'Account has users',
    },
    17: {
      backendError: 'Resource not found',
      message: 'Resource not found',
    },
    18: {
      backendError: 'Subcategory is not empty',
      message: 'Subcategory is not empty',
    },
    19: {
      backendError: 'json_web_token_error',
      message: 'Something is wrong Please, update the page',
    },
    20: {
      backendError: 'Validation error',
      message: 'Validation error',
    },
    21: {
      backendError: 'Marker already in use',
      message: 'Marker already in use',
    },
    22: {
      backendError: 'Category is not empty',
      message: 'Category is not empty',
    },
    23: {
      backendError: 'Can\'t delete as linked to campaign',
      message: 'This experience can\'t be removed since it\'s being used in some campaign. Please, delete this experience from campaign',
    },
    24: {
      backendError: 'Instance has accounts and users',
      message: 'Instance has accounts or users',
    },
    25: {
      backendError: 'This campaign does not have mobile app',
      message: 'This campaign does not have mobile app',
    },
    26: {
      backendError: 'Object in use',
      message: 'Object is in use',
    },
    27: {
      backendError: 'This location use in other sub_experience',
      message: 'This location is used in other sub_experience',
    },
    28: {
      backendError: 'Role can\'t update',
      message: 'Role can\'t be updated',
    },
    29: {
      backendError: 'Account has campaigns',
      message: 'Account has campaigns',
    },
    30: {
      backendError: 'Account has users and campaigns',
      message: 'Account has users and campaigns',
    },
    31: {
      backendError: 'You can not archive this campaign',
      message: 'You can not archive this campaign',
    },
    32: {
      backendError: 'User isn\'t valid',
      message: 'User isn\'t valid',
    },
    33: {
      backendError: 'You can\'t assign this role',
      message: 'You can not assign this role',
    },
    34: {
      backendError: 'You can\'t activate campaign without experiences',
      message: 'You can\'t activate campaign without experiences',
    },
    35: {
      backendError: 'You can only sync the active campaign',
      message: 'You can only sync the active campaign',
    },
    36: {
      backendError: 'Application can\'t be deleted because it is already in use in one or more campaigns',
      message: 'Application can\'t be deleted because it is already in use in one or more campaigns',
    },
    38: {
      backendError: 'You can not upload more items',
      message: 'You can not upload more items',
    },
    39: {
      backendError: 'You can not delete item',
      message: 'You can not delete item',
    },
    40: {
      backendError: 'Captcha error',
      message: 'Captcha error',
    },
    41: {
      backendError: 'Experience isn\'t assigned to a campaign',
      message: 'Experience isn\'t assigned to a campaign',
    },
    51: {
      backendError: `Experience can't be copied, you need to add mobile app to destination account`,
      message: `Experience can't be copied, you need to add mobile app to destination account`,
    },
    61: {
      backendError: 'Start or end date is incorrect. Please, change the dates and try again',
      message: 'Start or end date is incorrect. Please, change the dates and try again',
    },
    62: {
      backendError: 'Instance should be selected',
      message: 'Instance should be selected',
    },
  };
}
