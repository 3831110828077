import { Injectable } from '@angular/core';

@Injectable()
export class WorkflowService {
  private workflow: string | null = null;

  constructor() {}

  public getWorkflow() {
    return this.workflow;
  }

  public setWorkflow(type) {
    this.workflow = type;
  }

  public isWebAR() {
    return this.workflow === 'webar';
  }

  public isWikitude() {
    return this.workflow === 'wikitude';
  }

  public deleteWorkflow() {
    this.workflow = null;
  }
}
