import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'vectorString'
})
export class VectorStringPipe implements PipeTransform {
  transform(x: number = 0, y: number = 0, z: number = 0): string {
    return `${x} ${y} ${z}`;
  }
}
