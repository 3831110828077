<div class="system-filters">
  <div *ngIf="!user['instance_id'] && !user['account_id']"
       class="dropdown-wrapper"
       [ngClass]="{error : dropdownsErrors['instance']}">
    <p-autoComplete #instancesDropdown
                    scrollHeight='500px'
                    field="label"
                    acctest="instancesDropdown"
                    emptyMessage='There are no instances'
                    placeholder="All instances"
                    [dropdown]="true"
                    [suggestions]="filteredInstances"
                    [minLength]="2"
                    [(ngModel)]="instance"
                    (onSelect)="instancesFilterChange($event)"
                    (completeMethod)="autoCompleteInstances($event)">
      <div>{{instance['label']}}</div>
    </p-autoComplete>
  </div>
  <div *ngIf="!user['account_id']"
       class="dropdown-wrapper"
       [ngClass]="{error : dropdownsErrors['account']}">
    <p-autoComplete #accountsDropdown
                    scrollHeight='500px'
                    field="label"
                    acctest="accountsDropdown"
                    emptyMessage='There are no accounts'
                    placeholder="Not available"
                    [dropdown]="true"
                    [suggestions]="filteredAccounts"
                    [minLength]="2"
                    [disabled]="!dropdownsAvailability['account']"
                    [(ngModel)]="account"
                    (onSelect)="accountsFilterChange($event)"
                    (completeMethod)="autoCompleteAccounts($event)">
      <div>{{account['label']}}</div>
    </p-autoComplete>
  </div>
  <div class="dropdown-wrapper"
       [ngClass]="{error : dropdownsErrors['campaign']}">
    <p-autoComplete #campaignsDropdown
                    scrollHeight='500px'
                    field="label"
                    placeholder="Not available"
                    acctest="campaignsDropdown"
                    emptyMessage='There are no campaigns'
                    [dropdown]="true"
                    [disabled]="!dropdownsAvailability['campaign']"
                    [suggestions]="filteredCampaigns"
                    [minLength]="2"
                    [(ngModel)]="campaign"
                    (onSelect)="campaignsFilterChange($event)"
                    (completeMethod)="autoCompleteCampaigns($event)">
      <div>{{campaign['label']}}</div>
    </p-autoComplete>
  </div>
</div>
