import { Injectable } from '@angular/core';

export type InternalStateType = {
  [key: string]: any,
};

@Injectable()
export class AppState {

  public state: InternalStateType = {};

  /**
   * Already return a clone of the current state.
   */
  public get setState() {
    return this.state = AppState.clone(this.state);
  }

  /**
   * Never allow mutation
   */
  public static set setState(value) {
    throw new Error('do not mutate the `.state` directly');
  }

  public get(prop?: any) {
    /**
     * Use our state getter for the clone.
     */
    const state = this.state;
    return state.hasOwnProperty(prop) ? state[prop] : state;
  }

  public set(prop: string, value: any) {
    /**
     * Internally mutate our state.
     */
    return this.state[prop] = value;
  }

  private static clone(object: InternalStateType) {
    return JSON.parse(JSON.stringify(object));
  }
}
