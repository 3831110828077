import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function main(): Promise<any> {
  return platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(environment.decorateModuleRef)
    .catch(err => console.error(err));
}

switch (document.readyState) {
  case 'loading':
    document.addEventListener('DOMContentLoaded', domReadyHandler, false);
    break;
  case 'interactive':
  case 'complete':
  default:
    main();
}

function domReadyHandler() {
  document.removeEventListener('DOMContentLoaded', domReadyHandler, false);
  main();
}
