import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTimePipe',
})
export class FormatTimePipe implements PipeTransform {
  transform(time): any {
    let hr: any = Math.floor(time / 3600);
    let min: any = Math.floor((time - (hr * 3600)) / 60);
    let sec: any = Math.floor(time - (hr * 3600) - (min * 60));
    if (hr < 10) {
      hr = `0${hr}`;
    }
    if (min < 10) {
      min = `0${min}`;
    }
    if (sec < 10) {
      sec = `0${sec}`;
    }
    return `${hr}:${min}:${sec}`;
  }
}
