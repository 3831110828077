export class Title {
  name: string = '';
  'users_count': number = null;
  'instancesCount': number = null;
  'accounts_count': number = null;
  'campaigns_count': number = null;
  'experiences_count': number = null;
  'rolesCount': number = null;
  'clicksCount': number = null;
  'itemsCount': number = null;
  'state': string = '';
  'page': string = '';
  'text': string = '';
  'instance': string = '';
  'account': string = '';
  'tab': number = 0;
  'models': number = 0;
  'total': number = 0;
  userTrialExperiences: number = null;
  userTrialLoggedIn: number = null;
  userTrialRegistered: number = null;
  userTrialValidated: number = null;
  userTrialViewed: number = null;

  constructor(res: any) {
    for (const key in res) {
      this[key] = res[key];
    }
  }
}
