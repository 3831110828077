import { Component, ViewChild } from '@angular/core';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Proof } from '../../helpers/proof';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { UploadFileToS3 } from '../../helpers/uploadFileToS3';
import { SupportService } from '../../services/support.service';
import { MobileAppService } from '../../services/mobile.service';
import { MarkerbasedService } from '../../services/markerbased.service';
import { ExperienceService } from '../../services/experience.service';
import { AccountsService } from '../../services/accounts.service';
import { ExperiencesService } from '../../services/experiences.service';
import { Location } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { ErrorsService } from '../../services/errors.service';
import { CampaignService } from '../../services/campaign.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'proofHelper',
  styleUrls: ['proofHelper.component.scss'],
  templateUrl: 'proofHelper.component.html',
})

export class ProofHelperComponent {
  public msgs: Message[] = [];
  private errorValidation: boolean = false;
  @ViewChild('expName') private expName: any;

  constructor(
    private location: Location,
    private messageService: MessageService,
    public qrCode: Proof,
    private formBuilder: UntypedFormBuilder,
    private uploadFileToS3: UploadFileToS3,
    private supportService: SupportService,
    private mobileAppService: MobileAppService,
    private markerbasedService: MarkerbasedService,
    private errorsService: ErrorsService,
    private experienceService: ExperienceService,
    private experiencesService: ExperiencesService,
    private accountsService: AccountsService,
    private authService: AuthService,
    private campaignService: CampaignService,
  ) {
  }

  public openQRCodePopup(state, title, id, accountId, existSubs, value) {
    this.qrCode.loading = true;
    this.mobileAppService.getAppByAccountId(accountId).subscribe(
      (res: any) => {
        if (res.message && res.message === 'success') {
          this.qrCode.mobileAppLinks = {
            ios: res.data.ios_link, android: res.data.android_link,
          };
          switch (state) {
            case 'pCampaignId':
              this.qrCode.currentStateProof = 'campaign';
              this.logicGenerateData(state, title, id, res.data.name, value, accountId);
              break;
            case 'pExperienceId':
              this.qrCode.currentStateProof = 'experience';
              if (res.data.name !== null && existSubs) {
                this.markerbasedService.getSubExperiences(id).subscribe(
                  (resSub: any) => {
                    if (resSub.message && resSub.message === 'success') {
                      const value = [];
                      resSub.data.forEach(item => value.push(`sub;${item.id};${item.name}`));
                      this.logicGenerateData(state, title, id, res.data.name, value.length > 0 ? value : null, accountId);
                    }
                  });
              } else {
                this.logicGenerateData(state, title, id, res.data.name, value, accountId);
              }
              break;
            case 'pSubExpId':
              this.qrCode.currentStateProof = 'sub-experience';
              if (res.data.name !== null) {
                this.markerbasedService.getSubExperiences(existSubs).subscribe(
                  (resSub: any) => {
                    if (resSub.message && resSub.message === 'success') {
                      resSub.data = resSub.data.filter(item => item.id !== id);
                      resSub.data.forEach(item => value.push(`sub;${item.id};${item.name}`));
                      this.logicGenerateData(state, title, id, res.data.name, value, accountId);
                    }
                  });
              } else {
                this.logicGenerateData(state, title, id, res.data.name, value, accountId);
              }
              break;
          }
        }
      });
  }

  public cloneExperience(data) {
    switch (this.authService.user.role_level) {
      case 1: // system
        this.qrCode.accountListClone = this.accountsService.accountsList.filter(res => res.instanceId !== 73 && res.instanceName !== 'Trial Instance');
        break;
      case 2: // instance
        this.qrCode.accountListClone = this.accountsService.accountsList.filter(res => res.instanceId === this.authService.user.instance_id);
        break;
      case 3: // account
        this.qrCode.accountListClone = [{
          value: this.authService.user.account_id, label: data.account_name,
        }];
        break;
    }
    this.qrCode.currentExperience = data;
    this.qrCode.sendCloneExperienceForm = this.formBuilder.group({
      experienceId: new UntypedFormControl(data.id, Validators.required),
      toAccountId: new UntypedFormControl(data.account_id, Validators.required),
      newExperienceName: new UntypedFormControl(data.name + '-copy', Validators.required),
    });
    this.qrCode.visibleCloneExperience = true;
  }

  private submitCloneExperience(form) {
    this.qrCode.loading = true;
    this.experienceService.cloneExperience(form).subscribe(
      (res: any) => {
        if (res.message && res.message === 'success') {
          const url = this.location.path().split('/');
          if (url && url[2] && url[2] === 'dashboard') {
            this.experiencesService.getExperiences(null, '');
          } else {
            this.experiencesService.getExperiences(this.qrCode.currentExperience.account_id, '');
          }
          this.qrCode.experiencesSubscription = this.experiencesService.experiences$.subscribe(
            (resExp: any) => {
              if (resExp.message && resExp.message === 'success') {
                this.qrCode.visibleCloneExperience = false;
                const message = `Experience ${this.qrCode.currentExperience.name} with new name ${form.newExperienceName} was cloned to ${this.qrCode.accountListClone.find(res => res.value === form.toAccountId).label} account`;
                this.messageService.add({severity:'success', summary:'Success!', detail: message})
              } else {
                this.messageService.add({severity:'error', summary:'Error', detail: this.errorsService.showErrors(res) })
              }
              if (this.qrCode.experiencesSubscription) {
                this.qrCode.experiencesSubscription.unsubscribe();
                this.qrCode.experiencesSubscription = null;
              }
              this.qrCode.loading = false;
            });
        } else {
          if (res.error && res.error.code === 15) {
            this.messageService.add({severity:'error', summary:'Error', detail: res.error.message })
          } else {
            this.messageService.add({severity:'error', summary:'Error', detail: this.errorsService.showErrors(res) })
          }
          this.qrCode.loading = false;
        }
      });
  }

  private showChangeExpName() {
    this.qrCode.experienceChangedName = true;
    setTimeout(() => {
      this.expName.nativeElement.select();
      this.qrCode.experienceInputNameBeforeChanged = this.expName.nativeElement.value;
    }, 0);
  }

  private checkAccount() {
    (this.qrCode.currentExperience.account_id === this.qrCode.sendCloneExperienceForm.value.toAccountId
      && this.qrCode.currentExperience.name === this.qrCode.sendCloneExperienceForm.value.newExperienceName.trim())
      ? this.errorValidation = true : this.errorValidation = false;
  }

  private blurExperienceName(event) {
    if (event.keyCode !== 13 && event.keyCode !== 27) {
      this.qrCode.experienceChangedName = false;
      if (this.qrCode.currentExperience.account_id !== this.qrCode.sendCloneExperienceForm.value.toAccountId) {
        if (this.qrCode.sendCloneExperienceForm.value.newExperienceName.trim() !== '') {
          if (this.qrCode.experienceInputNameBeforeChanged !== this.qrCode.sendCloneExperienceForm.value.newExperienceName.trim()) {
            this.errorValidation = false;
            this.messageService.add({severity:'success', summary:'Success!', detail: 'Experience name was changed' })
          }
        } else {
          this.errorValidation = false;
          this.messageService.add({severity:'error', summary:'Error', detail: 'Experience name is not be empty' })
          this.qrCode.experienceChangedName = true;
          this.expName.nativeElement.select();
          return false;
        }
      } else {
        if (this.qrCode.currentExperience.name !== this.qrCode.sendCloneExperienceForm.value.newExperienceName.trim()) {
          if (this.qrCode.sendCloneExperienceForm.value.newExperienceName.trim() !== '') {
            if (this.qrCode.experienceInputNameBeforeChanged !== this.qrCode.sendCloneExperienceForm.value.newExperienceName.trim()) {
              this.errorValidation = false;
              this.messageService.add({severity:'success', summary:'Success!', detail: 'Experience name was changed' })
            }
            this.qrCode.experienceChangedName = false;
            this.qrCode.experienceInputNameBeforeChanged = '';
          } else {
            this.errorValidation = false;
            this.messageService.add({severity:'error', summary:'Error', detail: 'Experience name is not be empty' })
            this.qrCode.experienceChangedName = true;
            this.expName.nativeElement.select();
            return false;
          }
        } else {
          this.errorValidation = true;
          this.messageService.add({severity:'error', summary:'Error', detail: 'Experience name is not unique' })
          this.expName.nativeElement.select();
          this.qrCode.experienceChangedName = true;
          return false;
        }
      }
    }
  }

  private cancelCloneExperience() {
    this.qrCode.visibleCloneExperience = false;
    this.qrCode.sendCloneExperienceForm.reset();
    this.qrCode.currentExperience = null;
    this.qrCode.accountListClone = null;
    this.qrCode.experienceChangedName = false;
    this.qrCode.experiencesSubscription = null;
  }

  private logicGenerateData(state, title, id, app, value, accountId) {
    this.qrCode.data = { state, title, id, app, value };
    this.qrCode.visibleQRData = encodeURIComponent(JSON.stringify(this.qrCode.data));
    this.campaignService.generateShortUrl(`${ProofHelperComponent.initEnv()}${this.qrCode.visibleQRData}`, accountId)
      .subscribe((res: any) => {
        if (res.url) {
          this.qrCode.visibleQRData = res.short_url;
          this.qrCode.linkShare = res.short_url;
          this.qrCode.visibleDisplayQrCode = true;
          this.qrCode.loading = false;
        }
      });
  }

  private static initEnv() {
    let url: string = '';
    switch (environment.ENV) {
      case 'prod':
        url = `https://bluairspace.realityblu.com/#/proof?`;
        break;
      case 'stage':
        url = `https://bluairspace-staging.realityblu.com/#/proof?`;
        break;
      default:
        url = `https://bluairspace-${environment.ENV}.realityblu.com/#/proof?`;
        break;
    }
    return url;
  }

  private cancelSendEmail() {
    this.qrCode.saveDataQrCode = false;
    this.qrCode.visibleSendEmail = false;
    this.qrCode.sendEmailForm.reset();
    this.qrCode.visibleDisplayQrCode = true;
  }

  public closeQRCodePopup() {
    if (!this.qrCode.saveDataQrCode) {
      this.qrCode.saveDataQrCode = false;
      this.qrCode.visibleDisplayQrCode = false;
      this.qrCode.data = { state: '', title: '', id: null, app: null, value: null };
      this.qrCode.mobileAppLinks = { ios: null, android: null };
      this.qrCode.imageQrCode = '';
      this.qrCode.visibleQRData = '';
      this.qrCode.linkShare = '';
    }
  }

  private submitSendEmail(form, isMarkerless) {
    this.qrCode.loading = true;
    this.supportService.sendEmailQrCode(form, isMarkerless).subscribe(
      (res: any) => {
        if (res.message && res.message === 'success') {
          this.qrCode.saveDataQrCode = false;
          this.qrCode.visibleSendEmail = false;
          this.qrCode.sendEmailForm.reset();
          this.closeQRCodePopup();
          this.messageService.add({severity:'success', summary:'Success!', detail: 'Message was sent' })
        } else {
          this.messageService.add({severity:'error', summary:'Error', detail: 'Something is wrong. Try again' })
        }
        this.qrCode.loading = false;
      });
  }

  public sendEmail() {
    this.qrCode.saveDataQrCode = true;
    this.qrCode.visibleDisplayQrCode = false;
    this.qrCode.sendEmailForm = this.formBuilder.group({
      email_to: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.uploadFileToS3.emailRegex)]),
      email_subject: new UntypedFormControl(`Proof of ${this.qrCode.data.title} ${this.qrCode.currentStateProof} from RealityBLU`, Validators.required),
      email_notes: new UntypedFormControl(null),
      experience: new UntypedFormControl(this.qrCode.currentStateProof === 'experience' ? this.qrCode.data.id : null),
      sub_experience: new UntypedFormControl(this.qrCode.currentStateProof === 'sub_experience' ? this.qrCode.data.id : null),
      campaign: new UntypedFormControl(this.qrCode.currentStateProof === 'campaign' ? this.qrCode.data.id : null),
      qr_code: new UntypedFormControl(this.qrCode.imageQrCode, Validators.required),
      app_name: new UntypedFormControl(this.qrCode.data.app, Validators.required),
      ios_link: new UntypedFormControl(this.qrCode.mobileAppLinks.ios),
      android_link: new UntypedFormControl(this.qrCode.mobileAppLinks.android),
    });
    this.qrCode.visibleSendEmail = true;
  }

  private generateQrCode() {
    if (this.qrCode.data.app && !this.qrCode.imageQrCode) {
      setTimeout(() => {
        const canvas: any = document.createElement('canvas');
        canvas.className = 'canvas-qr-code';
        canvas.width = 200;
        canvas.height = 200;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        const img = new Image();
        img.onload = () => {
          ctx.drawImage(img, 10, 10);
          this.qrCode.imageQrCode = canvas.toDataURL('image/png');
          canvas.remove();
        };
        // img.src = document.getElementsByTagName('qrcode')[0].children[1]['src'];
      }, 0);
    }
  }

  public shareLink() {
    const el = document.createElement('textarea');
    document.body.appendChild(el);
    el.value = this.qrCode.linkShare;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.messageService.add({severity:'info', summary:'Proof Code', detail: 'Link was copied to the clipboard' });
  }

  private downloadPng() {
    const link: any = document.createElement('a');
    link.style.display = 'none';
    link.href = this.qrCode.imageQrCode;
    link.download = `proofing_code_${this.qrCode.data.title}.png`;
    link.click();
    link.remove();
  }
}
