import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'extractUser'
})
export class ExtractUserPipe implements PipeTransform {
  transform(data: any): string {
    if (data && data.hasOwnProperty('user-id') && data.hasOwnProperty('user-name')) {
      if (data['user-id'] && data['user-name']) {
        return `(Id: ${data['user-id']}) ${data['user-name']}`;
      }
    }

    return '---';
  }
}
