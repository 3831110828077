import { Component } from '@angular/core';

@Component({
  selector: 'error500',
  styleUrls: ['./error500.component.scss'],
  template: `
    <div class="inner-content">
      <div class="text">500</div>
      <span class="reload-text">Please reload page</span>
    </div>
    <div class="bgr">
      <div class="bgr-wrapper"></div>
    </div>
  `,
})
export class Error500Component {
}
