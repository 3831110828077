import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { InstanceService } from '../services/instance.service';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class InstanceResolver implements Resolve<any> {
  constructor(
    private instanceService: InstanceService,
    private routerService: Router,
    private authService: AuthService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (route.paramMap.get('id')) {
      if (this.authService.accessLevel === 'system') {
        return this.instanceService.getInstance(route.paramMap.get('id')).pipe(
          map(
          (res: any) => {
            if (res.message && res.message === 'success') {
              this.instanceService.instance = res;
              return true;
            }
            this.routerService.navigate(['/404']);
            return false;
          }));
      }
      if (this.authService.accessLevel === 'account') {
        this.routerService.navigate(['/404']);
        return null;
      }
    }
    return null;
  }
}
