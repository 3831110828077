<loader *ngIf="loading" [ngClass]="{page_loading: loading}"></loader>
<div *ngIf="analyticsConstructionShow" id="analytics_construction"  class="analytics-construction-wrapper">
  <img src="../../../assets/img/under_construction.png" width="275px;" style="padding: 5px;"/>
  Analytics Section is currently being rebuilt. <br><br>If you need a usage report this can be supplied to you free of charge, please contact us at <a href="mailto:info@realityblu.com?subject=Offline Reporting Request" style="color: #ae68c4; font-weight: bold;">info@realityblu.com</a> and we will deliver the reports to you outside of the application.
  <br><br>
  <div class="analytics-construction-button-div">
    <button class="all-nav footer-btn btn-secondary worldview-nav hidden-nav" style="background-color: #ae68c4; border-color: #ae68c4;" (click)="closeConstruction()">Back</button>
  </div>
</div>
<aside class="sidebar" [ngClass]="{collapsed: isSidebarCollapsed}">
  <div class="logo">
    <a [routerLink]="['/home']" class="logo-link">
      isSidebarCollapsed
      <img *ngIf="!isSidebarCollapsed" class="wl-logo-main" alt="image" draggable="false"/>
      <img *ngIf="isSidebarCollapsed" class="wl-logo-minified" alt="image" draggable="false"/>
    </a>
  </div>
  <div class="sidebar-menu">
    <ul class="sidebar-navigation">
      <li routerLinkActive="active" class="list-item dashboard-link">
        <a [routerLink]="['/home/dashboard']" class="list-link" id="dashboard-link">
          <span class="text" >dashboard</span>
        </a>
      </li>
      <li routerLinkActive="active" *ngIf="!user['instance_id'] && user['statics'].includes(29)" [routerLinkActiveOptions]="{ exact: false, __change_detection_hack__: currentRoute }"
          class="list-item instances-link">
        <a [routerLink]="['/home/instances']" class="list-link">
          <span class="text">instances</span>
        </a>
      </li>
      <li routerLinkActive="active" *ngIf="user['instance_id'] && user['statics'].includes(7)"
          class="list-item accounts">
        <a [routerLink]="['/home/instances/instance', user['instance_id']]" class="list-link">
          <span class="text">instance</span>
        </a>
      </li>
      <li *ngIf="!user['account_id'] && user['statics'].includes(30)" routerLinkActive="active"
          class="list-item accounts">
        <a [routerLink]="['/home/accounts']" class="list-link">
          <span class="text">accounts</span>
        </a>
      </li>
      <li routerLinkActive="active" *ngIf="user['account_id'] && user['statics'].includes(10)"
          class="list-item accounts">
        <a [routerLink]="['/home/accounts/account', user['account_id']]" class="list-link">
          <span class="text">account</span>
        </a>
      </li>
      <li *ngIf="user['statics'].includes(31)" routerLinkActive="active"
          class="list-item campaigns">
        <a [routerLink]="['/home/campaigns']" class="list-link">
          <span class="text">campaigns</span>
        </a>
      </li>
      <li *ngIf="user['statics'].includes(32) || user['statics'].includes(33) || user['statics'].includes(34) || user['statics'].includes(35) || user['statics'].includes(36)"
          routerLinkActive="active" class="list-item builder">
        <a [routerLink]="['/home/media']" class="list-link">
          <span class="text">xr builder</span>
        </a>
      </li>
      <li *ngIf="user['statics'].includes(37)" routerLinkActive="active"
          class="list-item analytics analytics-styling">
        <a [routerLink]="['/home/reports']" class="list-link analytics-styling">
          <span class="text analytics-styling">analytics</span>
        </a>
        <a [routerLink]="['/home/analytics']" class="list-link" style="display: none">
          <span class="text">analytics</span>
        </a>
        <a [routerLink]="['/home/reporting']" class="list-link" style="display: none">
          <span class="text">analytics</span>
        </a>
      </li>
      <li *ngIf="user['statics'].includes(38)" routerLinkActive="active" class="list-item budgets">
        <a [routerLink]="['/home/budgets']" class="list-link">
          <span class="text">budgets</span>
        </a>
      </li>
      <li routerLinkActive="active" *ngIf="!user['account_id'] && user['statics'].includes(39)"
          class="list-item users">
        <a [routerLink]="['/home/users']" class="list-link">
          <span class="text">users</span>
        </a>
      </li>
      <li *ngIf="wlAccess" routerLinkActive="active"
        class="list-item white-label">
        <a [routerLink]="['/home/white-label']" class="list-link">
          <span class="text">white label</span>
        </a>
      </li>
      <!-- <li *ngIf="!user['instance_id'] && user['statics'].includes(29)" routerLinkActive="active"
      class="list-item white-label">
      <a [routerLink]="['/home/games']" class="list-link">
        <span class="text">games</span>
      </a>
    </li> -->
      <li routerLinkActive="active" *ngIf="user['statics'].includes(43)"
          class="list-item developers">
          <a target="_blank" href="https://gitlab.com/realityblu_sdk" class="list-link">
          <span class="text">developers</span>
        </a>
      </li>
      <li routerLinkActive="active" *ngIf="user['statics'].includes(45)"
          class="list-item applications">
        <a [routerLink]="['/home/applications']" class="list-link">
          <span class="text">applications</span>
        </a>
      </li>
    </ul>
  </div>

  <div class="sidebar-btns">
    <span class="toggle-sidebar-btn" (click)="isSidebarCollapsed = !isSidebarCollapsed"></span>
    <div class="sidebar-menu">
      <ul class="sidebar-navigation navi-bottom">
        <li routerLinkActive="active" class="list-item support" (click)="openSupport()" style="cursor: pointer;">
          <a class="list-link">
            <span class="text">support</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="sidebar-user-panel">
    <div class="sidebar-user" [ngClass]="{impersonate: user['isImpersonated'] === true}">
      <div class="user-photo" [ngClass]="{default_image: !user['image']}">
        <img *ngIf="user['image']" [src]="user['image']" alt="image" draggable="false">
      </div>
      <div (click)="openSupport()" class="support-btn">
        <span class="ti-shift-left"></span>
        <span class="text">Support</span>
      </div>
      <div class="user-title">
        <span class="user-name">{{user['first_name']}}</span>
        <span class="user-role">{{user.role}}</span>
      </div>
    </div>
    <div *ngIf="user['isImpersonated'] === true" (click)="leaveImpersonation()" class="leaveImpersonate-btn">
      <span class="ti-shift-left"></span>
      <span class="text">Leave Impersonation</span>
    </div>
    <div (click)="logout()" class="logout-btn">
      <span class="ti-shift-left"></span>
      <span class="text">Log out</span>
    </div>

  </div>
</aside>
