<!-- <p-toast  ></p-toast> -->
<loader *ngIf="qrCode.loading" [ngClass]="{page_loading: qrCode.loading}"></loader>
<p-dialog *ngIf="qrCode.visibleWebArQrCode" header="Web-AR" [responsive]="true"
          (onShow)="generateQrCode()"
          [(visible)]="qrCode.visibleWebArQrCode" (onHide)="closeQrCodePopup()" modal="modal"
          [draggable]="false" [resizable]="false" class="builder-custom-dialog">
  <div class="qrContainer">
    <div class="form-content qr-popup" >
      <div class="qr-code-container">
        <qrcode id="webArQrCodeImage" [qrdata]="qrCode.visibleQRData" [allowEmptyString]="true" [size]="180"
                [level]="'M'">
        </qrcode>
      </div>
      <div class="text-container main-popup-proof">
          Please open this URL on your mobile device to activate WebAR experiences for this campaign.
          <!-- <br/> caused by ngprime p-dialog issue with recalculating top property -->
          <br/><br/>
          <br/><br/>
      </div>
      <div class="form-footer"  style="display: block;" *ngIf="qrCode.linkShare !== null">
        <input #inputCode class="input-code-footer" value="{{qrCode.linkShare}}"
               placeholder="WebAR URL" type="text" (click)="inputCode.select()" readonly>
        <span class="icons-footer">
          <i class="fa fa-clipboard" title="Copy Link" (click)="shareLink()"></i>
          <i class="fa fa-envelope" title="Send Email" (click)="sendEmail()"></i>
          <i class="fa fa-download" title="Download PNG" (click)="downloadPng()"></i>
        </span>
      </div>
  </div>
</div>
<activeMarkers class="markerContainer" > </activeMarkers>
</p-dialog>

