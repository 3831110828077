import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';
import { ConfigService } from './config.service';
import { IsLoginRouteGuard } from '../guards/is-login';
import { NoLoginRouteGuard } from '../guards/no-login';
import { of } from 'rxjs';

@Injectable()
export class AuthService {
  public user: any = null;
  public idleTimeout: boolean = false;
  public accessLevelId: any = null;
  public accessLevel: string = null;
  public token: any = null;
  public interval: any = null;
  public accessLevel$: Observable<string> = null;
  public user$: Observable<any> = null;
  public userSource = new Subject<string>();

  constructor(
    private routerService: Router,
    private loginService: LoginService,
    private configService: ConfigService,
    private httpService: HttpClient,
    private location: Location,
    private isLoginRouteGuard: IsLoginRouteGuard,
    private noLoginRouteGuard: NoLoginRouteGuard,
  ) {
    const token = localStorage.getItem('token');
    const curURL = this.location.path().split('/');
    this.user$ = this.userSource.asObservable();
    try {
      this.user = JSON.parse(localStorage.getItem('user'));
    } catch (err) {
      this.loginService.failAuthorizationSource.next(true);
      if (curURL[1] !== 'createpassword' && curURL[1] !== 'proof' && curURL[1] !== 'forgot' && curURL[1] !== 'support' && curURL[1] !== 'sign_up' && curURL[1] !== 'trial' && curURL[1] !== 'desktop_version') {
        this.clearTokenNavigate('login');
      }
    }
    if (token && this.user) {
      if (this.user.message && this.user.message === 'failed') {
        this.clearTokenNavigate('login');
      } else {
        this.token = token;
        AuthService.setUserToken(this.user, this.token);
        if (this.user.account_id) {
          this.accessLevelId = this.user.account_id;
          this.accessLevel = 'account';
        } else {
          this.accessLevelId = null;
          this.accessLevel = 'system';
        }
        this.accessLevel$ = of(this.accessLevel);
        this.isLoginRouteGuard.setIsLogin(true);
        this.noLoginRouteGuard.setNoLogin(true);
        this.startUpdateToken();
      }
    } else {
      switch (curURL[1]) {
        case 'forgot':
        case 'sign_up':
        case 'trial':
        case 'support':
        case 'proof':
          this.clearTokenNavigate(curURL[1]);
          break;
        case 'createpassword':
          this.clearTokenNavigate(`${curURL[1]}/${curURL[2]}/${curURL[3]}`);
          break;
        default:
          this.clearTokenNavigate('login');
      }
    }
  }

  public useJwtHelper(res) {
    // if (!localStorage.getItem('token') || res.isImpersonated) {
      if (res.message && res.message === 'failed') {
        this.loginService.failAuthorizationSource.next(true);
        this.clearTokenNavigate('login');
      } else {
        this.user = res;
        this.token = this.user.token;
        AuthService.setUserToken(this.user, this.token);
        this.userSource.next(this.user);
        this.isLoginRouteGuard.setIsLogin(true);
        this.noLoginRouteGuard.setNoLogin(true);
        this.startUpdateToken();
        if (this.user.account_id) {
          this.accessLevelId = this.user.account_id;
          this.accessLevel = 'account';
          this.accessLevel$ = of(this.accessLevel);
        } else {
          this.accessLevelId = null;
          this.accessLevel = 'system';
          this.accessLevel$ = of(this.accessLevel);
        }
        this.routerService.navigate(['/home/dashboard']);
      }
    // } else {
    //   this.clearTokenNavigate('login');
    // }
  }

  public startUpdateToken() {
    clearInterval(this.interval);
    this.interval = setInterval(() => this.updateToken(), 1000 * 60);
  }

  public updateToken() {
    this.httpService.get(this.configService.config.getNewToken).subscribe(
      (res: any) => {
        if (res.message && res.message === 'success') {
          if (!this.idleTimeout) {
            this.user.token = res.data.token;
            this.user.accept_terms_time = res.data.accept_terms_time;
            this.token = this.user.token;
            AuthService.setUserToken(this.user, this.token);
            this.userSource.next(this.user);
          }
        } else {
          this.clearTokenNavigate('login');
        }
        this.idleTimeout = false;
      },
    );
  }

  public clearTokenNavigate(res: string) {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.isLoginRouteGuard.setIsLogin(false);
    this.noLoginRouteGuard.setNoLogin(false);
    localStorage.clear();
    this.routerService.navigate([`/${res}`], { replaceUrl: true });
  }

  public static setUserToken(res, token) {
    localStorage.removeItem('user');
    localStorage.setItem('user', JSON.stringify(res));
    localStorage.removeItem('token');
    localStorage.setItem('token', token);
  }
}
