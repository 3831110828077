import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class AccountService {
  public account: any = null;
  public campaignsCountAccount: any = null;

  private accountUpdatedSource: Subject<void> = new Subject<any>();
  public accountUpdated$: Observable<any> = this.accountUpdatedSource.asObservable();

  constructor(
    private httpService: HttpClient,
    private configService: ConfigService,
  ) {
  }

  public getAccount(id) {
    return this.httpService.get(this.configService.config.getAccount + id);
  }

  public createAccount(account) {
    return this.httpService.post(this.configService.config.createAccount, { account }).pipe(
      tap(() => this.accountUpdatedSource.next()),
    );
  }

  public getAccountCounter(accountID) {
    const url = this.configService.config.getAccountCounter;
    return this.httpService.get(`${url}/${accountID}`);
  }

  public editAccount(account) {
    const url = this.configService.config.updateAccount;

    return this.httpService.put(url, { account }).pipe(
      tap(() => this.accountUpdatedSource.next()),
    );
  }

  public deleteAccount(id) {
    const url = this.configService.config.deleteAccount;

    return this.httpService.delete(url + id).pipe(
      tap(() => this.accountUpdatedSource.next())
    );
  }

  public sendUpgradeEmail(email, first, last, instance, account) {
    const url = this.configService.config.sendUpgradeEmail

    return this.httpService.post(url, {email, first, last, instance, account}).subscribe( res => {
      console.log(res)
    })
  }
}
