<p-toast  ></p-toast>
<loader *ngIf="qrCode.loading" [ngClass]="{page_loading: qrCode.loading}"></loader>
<p-dialog *ngIf="qrCode.visibleDisplayQrCode" header="PROOFING CODE" [responsive]="true"
          (onShow)="generateQrCode()"
          [(visible)]="qrCode.visibleDisplayQrCode" (onHide)="closeQRCodePopup()" modal="modal"
          [draggable]="false" [resizable]="true" class="builder-custom-dialog">
  <div class="form-content qr-popup" *ngIf="qrCode.data.app === null">
    <div class="text-container main-popup-proof no-mobile-app">
      No proofing mode is available for accounts with no application assigned. <br/>
      Please assign a mobile application to your account and try again
    </div>
  </div>
  <div class="form-content qr-popup" *ngIf="qrCode.data.app !== null">
    <div class="text-container main-popup-proof">
      <span>To see a proof of this {{qrCode.currentStateProof}}: <br/><br/>
        1. Open the <b>{{qrCode.data.app}}</b> application and long press the SCAN button until the app asks for verification to be placed into Proofing mode. <br/><br/>
        2. After answering in the affirmative, scan the QR code on your screen, activating the proofing scanner. <br/><br/>
        3. Scan the marker associated with this {{qrCode.currentStateProof}} to proof the AR content
      </span>
    </div>
    <div class="qr-code-container">
      <qrcode [qrdata]="qrCode.visibleQRData" [allowEmptyString]="true" [size]="180"
              [level]="'M'">
      </qrcode>
    </div>
  </div>
  <div class="form-footer" *ngIf="qrCode.data.app !== null">
    <input #inputCode class="input-code-footer" value="{{qrCode.linkShare}}"
           placeholder="Proofing Code" type="text" (click)="inputCode.select()" readonly>
    <span class="icons-footer">
      <i class="fa fa-clipboard" title="Copy Link" (click)="shareLink()"></i>
      <i class="fa fa-envelope" title="Send Email" (click)="sendEmail()"></i>
      <i class="fa fa-download" title="Download PNG" (click)="downloadPng()"></i>
    </span>
  </div>
</p-dialog>
<p-dialog *ngIf='qrCode.visibleSendEmail' header="SEND PROOFING CODE"
          [(visible)]="qrCode.visibleSendEmail" (onHide)="cancelSendEmail()"
          modal="modal" [responsive]="true" [draggable]="false" [resizable]="true">
  <form [formGroup]="qrCode.sendEmailForm"
        (ngSubmit)="submitSendEmail(qrCode.sendEmailForm.getRawValue(), qrCode.isMarkerless)">
    <div class="form-content">
      <div class="form-row">
        <label class="required">
          <span>To</span>
        </label>
        <div class="field">
          <input type="text" formControlName="email_to" placeholder="Enter email"
                 [ngClass]="{error : qrCode.sendEmailForm.get('email_to').touched && qrCode.sendEmailForm.get('email_to').hasError('pattern')}"/>
          <div class="validation-field"
               *ngIf="qrCode.sendEmailForm.get('email_to').touched && qrCode.sendEmailForm.get('email_to').hasError('pattern')">
            Email to is not valid
          </div>
          <i *ngIf="qrCode.sendEmailForm.get('email_to').touched && qrCode.sendEmailForm.get('email_to').hasError('pattern')"
             class="fa fa-exclamation-triangle error-main-container" aria-hidden="true">
          </i>
        </div>
      </div>
      <div class="form-row">
        <label class="required">
          <span>Subject</span>
        </label>
        <div class="field">
          <input type="text" formControlName="email_subject"
                 [ngClass]="{error : qrCode.sendEmailForm.get('email_subject').touched && qrCode.sendEmailForm.get('email_subject').hasError('required')}"/>
          <div class="validation-field"
               *ngIf="qrCode.sendEmailForm.get('email_subject').touched && qrCode.sendEmailForm.get('email_subject').hasError('required')">
            Subject is required
          </div>
          <i *ngIf="qrCode.sendEmailForm.get('email_subject').touched && qrCode.sendEmailForm.get('email_subject').hasError('required')"
             class="fa fa-exclamation-triangle error-main-container" aria-hidden="true">
          </i>
        </div>
      </div>
      <div class="form-row">
        <label>
          <span>Notes</span>
        </label>
        <div class="field">
          <textarea rows="3" type="text" formControlName="email_notes"></textarea>
        </div>
      </div>
    </div>
    <div class="form-footer">
      <button type="button" class="btn btn-delete" (click)="cancelSendEmail()">
        <i class="fa fa-arrow-left"></i> Back
      </button>
      <button type="submit" class="btn"
              [disabled]="qrCode.sendEmailForm.pristine || qrCode.sendEmailForm.invalid">
        Send
      </button>
    </div>
  </form>
</p-dialog>
<p-dialog *ngIf='qrCode.visibleCloneExperience' header="CLONE EXPERIENCE"
          styleClass="clone-popup overflow-visible-popup"
          [(visible)]="qrCode.visibleCloneExperience" (onHide)="cancelCloneExperience()"
          modal="modal" [responsive]="true" [draggable]="false" [resizable]="true">
  <form [formGroup]="qrCode.sendCloneExperienceForm"
        (ngSubmit)="submitCloneExperience(qrCode.sendCloneExperienceForm.getRawValue())">
    <div class="form-content">
      <div class="form-row row-input" *ngIf="qrCode.experienceChangedName">
        <div class="field">
          <input type="text" autofocus (blur)="blurExperienceName($event)" #expName
                 formControlName="newExperienceName" placeholder="Enter experience name"/>
        </div>
      </div>
      <div class="form-row" *ngIf="!qrCode.experienceChangedName">
        <label>
          <span>Experience</span>
        </label>
        <div class="field">
          <span class="text">{{qrCode.sendCloneExperienceForm.value['newExperienceName']}}</span>
          <i class="fa fa-pencil-square-o icon-set-exp" aria-hidden="true"
             title="Change Experience Name" (click)="showChangeExpName()">
          </i>
        </div>
      </div>
      <div class="form-row row-account">
        <label>
          <span>Account</span>
        </label>
        <div class="field">
          <span class="text">
            {{qrCode.currentExperience['account_name']}}
          </span>
        </div>
      </div>
      <div class="form-row">
        <label>
          <span>Clone to account</span>
        </label>
        <div class="field" *ngIf="qrCode.accountListClone.length > 1">
          <p-dropdown placeholder="Select Account" formControlName="toAccountId"
                      [options]="qrCode.accountListClone" scrollHeight="200px"
                      [ngClass]="{error : errorValidation}" (onChange)="checkAccount()">
          </p-dropdown>
          <div *ngIf="errorValidation" class="validation-field">
            Please, choose another account
          </div>
        </div>
        <div class="field" *ngIf="qrCode.accountListClone.length === 1">
          <input type="text" formControlName="toAccountId"
                 [value]="qrCode.accountListClone[0].label" disabled/>
        </div>
      </div>
    </div>
    <div class="form-footer one-button-footer">
      <div class="form-row row-text">
        <div class="field">
          Experience assets will be added to the gallery of target account
        </div>
      </div>
      <button type="submit" class="btn"
              [disabled]="qrCode.sendCloneExperienceForm.invalid || qrCode.experienceChangedName || errorValidation">
        Clone
      </button>
    </div>
  </form>
</p-dialog>
