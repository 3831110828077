import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { ConfigService } from './config.service';
import {HolotwinSendData} from '../interfaces/HolotwinSendData';
import {Utils} from '../utils/Utils';

@Injectable({
  providedIn: 'root'
})
export class WhiteLabelApiService {
  public helpBody: string;
  public helpHeader: string;
  public helpAddress: string;
  public helpPhone: string;
  public helpEmail: string;
  public helpPage: string;
  public tosPage: string;
  public privacyPage: string;
  public pageTitle: string;
  public masthead: string;
  @Output() getWhiteLabelEvent: EventEmitter<any> = new EventEmitter();
  @Output() getWhiteLabelsEvent: EventEmitter<any> = new EventEmitter();
  @Output() postWhiteLabelEvent: EventEmitter<any> = new EventEmitter();
  @Output() putWhiteLabelEvent: EventEmitter<any> = new EventEmitter();
  @Output() deleteWhiteLabelEvent: EventEmitter<any> = new EventEmitter();
  @Output() updateStatusEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient,
              private configService: ConfigService,) {
  }

  // region Holotwins
  public getWhiteLabel(whiteLabelId): void {
    // TODO: Set userId

    const url = this.configService.config.getWhiteLabel;
    this.http.get<any>(`${url}/${whiteLabelId}`).subscribe(
      (result) => {
        this.getWhiteLabelEvent.emit(result);
      },
      (error) => {
        console.error(error);
        this.getWhiteLabelEvent.emit({error});
      }
    );
  }

  public getWhiteLabels(instanceId, accountId, domain?): void {
    const url = this.configService.config.getWhiteLabels;
    this.http.get<any>(`${url}/instance/${instanceId}/account/${accountId}/url/${domain ? domain : null}`).subscribe(
      (result) => {
        this.getWhiteLabelsEvent.emit(result);
      },
      (error) => {
        console.error(error);
        this.getWhiteLabelsEvent.emit({error});
      }
    );
  }

  public postWhiteLabel( formData, instanceId, accountId, userId): void {
    const sendData = new FormData();
    for ( var key in formData ) {
      sendData.append(key, formData[key]);
    }

    const url = this.configService.config.postWhiteLabel;
    this.http.post<any>(`${url}/instance/${instanceId}/account/${accountId}/user/${userId}`, sendData, {
      reportProgress: true,
      observe: 'events'
    }).subscribe(
      (event) => {
        if (event.type === HttpEventType.Response) {
          this.postWhiteLabelEvent.emit(event.body);
        }
      },
      (error) => {
        console.error(error);
        this.postWhiteLabelEvent.emit({error});
      }
    );
  }

  public putWhiteLabel( formData, whiteLabelId, instanceId, accountId, userId): void {
    const sendData = new FormData();
    for ( var key in formData ) {
      sendData.append(key, formData[key]);
    }

    const url = this.configService.config.putWhiteLabel;
    this.http.put<any>(`${url}/${whiteLabelId}/instance/${instanceId}/account/${accountId}/user/${userId}`, sendData, {
      reportProgress: true,
      observe: 'events'
    }).subscribe(
      (event) => {
        if (event.type === HttpEventType.Response) {
          this.putWhiteLabelEvent.emit(event.body);
        }
      },
      (error) => {
        console.error(error);
        this.putWhiteLabelEvent.emit({error});
      }
    );
  }

  public updateStatus(whiteLabelId: number, accountId, status, instanceId): void {
    const sendData = new FormData();
    sendData.append('status', status);
    sendData.append('instanceId', instanceId);
    const url = this.configService.config.updateStatusWhiteLabel;
    this.http.put<any>(`${url}/${whiteLabelId}/account/${accountId}`, sendData).subscribe(
      (result) => {
        this.updateStatusEvent.emit(result);
      },
      (error) => {
        console.error(error);
        this.updateStatusEvent.emit({error});
      }
    );
  }

  public setPermission(instanceId, accountId, status): void {
    const sendData = new FormData();
    sendData.append('status', status);
    sendData.append('instanceId', instanceId);
    sendData.append('accountId', accountId);
    const url = this.configService.config.updateStatusWhiteLabel;
    this.http.put<any>(`${url}`, sendData).subscribe(
      (result) => {
        this.updateStatusEvent.emit(result);
      },
      (error) => {
        console.error(error);
        this.updateStatusEvent.emit({error});
      }
    );
  }

  public deleteWhiteLabel(holotwinId: number): void {
    const url = this.configService.config.deleteWhiteLabel;
    this.http.delete<any>(`${url}/${holotwinId}`).subscribe(
      (result) => {
        this.deleteWhiteLabelEvent.emit(result);
      },
      (error) => {
        console.error(error);
        this.deleteWhiteLabelEvent.emit({error});
      }
    );
  }

  public setWhiteLabel(data): void {

    this.pageTitle = data.filter(item => item['variable_name'] === 'wl_page_title')[0].value;

    document.getElementsByTagName('title')[0].innerHTML = this.pageTitle
    this.helpBody = data.filter(item => item['variable_name'] === 'wl_help_body')[0].value;
    this.helpAddress = data.filter(item => item['variable_name'] === 'wl_help_address')[0].value;
    this.helpEmail = data.filter(item => item['variable_name'] === 'wl_help_email')[0].value;
    this.helpPage = data.filter(item => item['variable_name'] === 'wl_help_webpage')[0].value;
    this.helpPhone = data.filter(item => item['variable_name'] === 'wl_help_phone')[0].value;
    this.helpHeader = data.filter(item => item['variable_name'] === 'wl_help_header')[0].value;
    this.masthead = data.filter(item => item['variable_name'] === 'wl_masthead')[0].value;
    this.privacyPage = data.filter(item => item['variable_name'] === 'wl_privacy')[0].value;
    this.tosPage = data.filter(item => item['variable_name'] === 'wl_tos')[0].value;

    document.documentElement.style.setProperty('--wl-data-accent-1',    data.filter(item => item['variable_name'] === 'wl_data_accent_1')[0].value);
    document.documentElement.style.setProperty('--wl-data-accent-2',    data.filter(item => item['variable_name'] === 'wl_data_accent_2')[0].value);

    document.documentElement.style.setProperty('--wl-btn-background-1', data.filter(item => item['variable_name'] === 'wl_button_background')[0].value);
    document.documentElement.style.setProperty('--wl-btn-border-1', data.filter(item => item['variable_name'] === 'wl_button_border')[0].value);
    document.documentElement.style.setProperty('--wl-btn-text-1', data.filter(item => item['variable_name'] === 'wl_button_text')[0].value);
    document.documentElement.style.setProperty('--wl-btn-background-2', data.filter(item => item['variable_name'] === 'wl_button_background_hover')[0].value);
    document.documentElement.style.setProperty('--wl-btn-border-2', data.filter(item => item['variable_name'] === 'wl_button_border_hover')[0].value);
    document.documentElement.style.setProperty('--wl-btn-text-2', data.filter(item => item['variable_name'] === 'wl_button_text_hover')[0].value);


    document.documentElement.style.setProperty('--wl-primary-1',        data.filter(item => item['variable_name'] === 'wl_primary')[0].value);
    document.documentElement.style.setProperty('--wl-light-1',          data.filter(item => item['variable_name'] === 'wl_primary_light')[0].value);
    document.documentElement.style.setProperty('--wl-dark-1',           data.filter(item => item['variable_name'] === 'wl_primary_dark')[0].value);
    document.documentElement.style.setProperty('--wl-primary-2',        data.filter(item => item['variable_name'] === 'wl_secondary')[0].value);
    document.documentElement.style.setProperty('--wl-light-2',          data.filter(item => item['variable_name'] === 'wl_secondary_light')[0].value);
    document.documentElement.style.setProperty('--wl-dark-2',           data.filter(item => item['variable_name'] === 'wl_secondary_dark')[0].value);
    document.documentElement.style.setProperty('--wl-primary-3',        data.filter(item => item['variable_name'] === 'wl_tertiary')[0].value);
    document.documentElement.style.setProperty('--wl-light-3',          data.filter(item => item['variable_name'] === 'wl_tertiary_light')[0].value);
    document.documentElement.style.setProperty('--wl-dark-3',           data.filter(item => item['variable_name'] === 'wl_tertiary_dark')[0].value);
    document.documentElement.style.setProperty('--wl-gradient-lower',   data.filter(item => item['variable_name'] === 'wl_gradient_lower')[0].value);
    document.documentElement.style.setProperty('--wl-gradient-middle',  data.filter(item => item['variable_name'] === 'wl_gradient_middle')[0].value);
    document.documentElement.style.setProperty('--wl-gradient-upper',   data.filter(item => item['variable_name'] === 'wl_gradient_upper')[0].value);

    document.documentElement.style.setProperty('--wl-login-logo',       `url("${data.filter(item => item['variable_name'] === 'wl_login_logo')[0].value}")`);
    document.documentElement.style.setProperty('--wl-login-background', `url("${data.filter(item => item['variable_name'] === 'wl_login_background')[0].value}")`);
    document.documentElement.style.setProperty('--wl-logo-minified',    `url("${data.filter(item => item['variable_name'] === 'wl_logo_minified')[0].value}")`);
    document.documentElement.style.setProperty('--wl-logo-main',        `url("${data.filter(item => item['variable_name'] === 'wl_logo_main')[0].value}")`);
    document.documentElement.style.setProperty('--wl-logo-footer',      `url("${data.filter(item => item['variable_name'] === 'wl_logo_footer')[0].value}")`);

  }
}
