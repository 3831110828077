import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class HomeResolverOutService implements Resolve<any> {
  constructor(
    private routerService: Router,
    private authService: AuthService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (this.authService.user) {
      this.routerService.navigate(['/home/dashboard/']);
      return null;
    }
    if (!this.authService.user) {
      this.authService.clearTokenNavigate('login');
      return null;
    }
    this.routerService.navigate(['/404']);
    return null;
  }
}
