import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { AuthService } from './auth.service';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class InstanceService {
  public instance: any = null;

  private instanceUpdatedSource: Subject<void> = new Subject<void>();
  public instanceUpdated$: Observable<any> = this.instanceUpdatedSource.asObservable();

  constructor(
    private httpService: HttpClient,
    private configService: ConfigService,
    private authService: AuthService,
  ) {
  }

  public getInstance(id) {
    const url = this.configService.config.getInstance;

    return this.httpService.get(url + id + '/' + this.authService.token);
  }

  public createInstance(instance) {
    const url = this.configService.config.createInstance;

    return this.httpService.post(url, { instance, token: this.authService.token }).pipe(
      tap(() => this.instanceUpdatedSource.next())
    );
  }

  public updateInstance(instance) {
    const url = this.configService.config.updateInstance;

    return this.httpService.put(url, { instance, token: this.authService.token }).pipe(
      tap(() => this.instanceUpdatedSource.next())
    );
  }

  public deleteInstance(id) {
    const url = this.configService.config.deleteInstance;

    return this.httpService.delete(url + id + '/' + this.authService.token).pipe(
      tap(() => this.instanceUpdatedSource.next())
    );
  }
}
