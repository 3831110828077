import {Pipe, PipeTransform} from '@angular/core';
import {faCheckCircle, faCircle, IconDefinition} from '@fortawesome/free-regular-svg-icons';

@Pipe({
  name: 'activeIcon'
})
export class ActiveIconPipe implements PipeTransform {
  transform(value: boolean): IconDefinition {
    return value ? faCheckCircle : faCircle;
  }
}
