import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { AuthService } from './auth.service';

@Injectable()
export class RolesService {
  constructor(
    private authService: AuthService,
    private httpService: HttpClient,
    private configService: ConfigService,
  ) {
  }

  public getPermissions() {
    const url = this.configService.config.getPermissions;
    return this.httpService.get(url + this.authService.token);
  }

  public getRoles() {
    const url = this.configService.config.getRoles;
    return this.httpService.get(url + this.authService.token);
  }

  public deleteRole(id) {
    const url = this.configService.config.deleteRole;
    return this.httpService.delete(url + id + '/' + this.authService.token);
  }

  public createRole(role) {
    return this.httpService.post(this.configService.config.createRole,
      {
        token: this.authService.token,
        role: {
          name: role.name, description: role.description, level: role.level,
          permissions: { permissions_id: role.permissionsChecked },
        },
      },
    );
  }

  public updateRole(role) {
    return this.httpService.put(this.configService.config.updateRole,
      {
        token: this.authService.token,
        role: {
          id: role.roleId, name: role.name, description: role.description,
          level: role.level, permissions: { permissions_id: role.permissionsChecked },
        },
      },
    );
  }
}
