import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveMarkersRoutingModule} from './active-markers-routing.module';
import { ActiveMarkersComponent } from './active-markers.component';
import { RatingModule } from 'primeng/rating'
import { FormatDatePipeModule } from '../../helpers/formatDatePipe.module';


@NgModule({
    imports: [CommonModule, ActiveMarkersRoutingModule, RatingModule, FormatDatePipeModule],
    declarations: [ActiveMarkersComponent],
    exports: [ActiveMarkersComponent]
  })
  export class ActiveMarkersModule {
  }
  