import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class NoLoginRouteGuard implements CanActivate {
  private noLogin: boolean = false;

  constructor() {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkIfLoginConsist();
  }

  public checkIfLoginConsist(): boolean {
    return !this.noLogin;
  }

  public setNoLogin(isLogin: boolean): void {
    this.noLogin = isLogin;
  }
}
