import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { InstancesService } from '../services/instances.service';
import { AccountsService } from '../services/accounts.service';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';;

@Injectable()
export class HomeResolver implements Resolve<any> {
  constructor(
    private routerService: Router,
    private authService: AuthService,
    private instancesService: InstancesService,
    private accountsService: AccountsService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (this.authService.user === null) {
      this.authService.clearTokenNavigate('login');
      return null;
    }
    return forkJoin([
      this.instancesService.getInstances(), this.accountsService.getAccounts()]).pipe(
      map(
      (res: any) => {
        if (res[0] && res[1] && res[0]['message'] && res[0]['message'] === 'success' && res[1]['message'] && res[1]['message'] === 'success') {
          return true;
        }
        return true;
      }));
  }
}
