import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { WorkflowService } from './workflow.service';
import { map } from 'rxjs/operators';

@Injectable()
export class CampaignService {
  public campaign: any = null;
  public campaignData: any = null;
  public show$: Observable<any> = null;
  public showSource = new Subject<any>();
  public campaign$: Observable<any> = null;
  public campaignSource = new Subject<any>();
  public selectedId

  constructor(
    private httpService: HttpClient,
    private configService: ConfigService,
    private workflow: WorkflowService,
  ) {
    this.campaign$ = this.campaignSource.asObservable();
    this.show$ = this.showSource.asObservable();
  }

  public getCampaign(id) {
    const url = this.configService.config.getCampaignById;
    return this.httpService.get(url + id).pipe(
      map(
      (res: any) => {
        this.campaign = res;
        this.workflow.setWorkflow(this.campaign.data.recognition_type);
        this.campaignSource.next(this.campaign);
        return res;
      }));
  }

  public getCampaignWithoutSubscribe(id) {
    const url = this.configService.config.getCampaignById;
    return this.httpService.get(url + id).pipe(
      map(
      (res: any) => {
        this.campaign = res;
        this.workflow.setWorkflow(this.campaign.data.recognition_type);
        return res;
      }));
  }

  public createCampaign(campaign) {
    const url = this.configService.config.createCampaign;
    return this.httpService.post(url, { campaign });
  }

  public updateCampaignStatus(id, status) {
    const url = this.configService.config.updateCampaignStatus;
    return this.httpService.put(url, { campaign: { id, status } });
  }

  public updateCampaign(form) {
    const url = this.configService.config.updateCampaign;
    return this.httpService.put(url, { campaign: form });
  }

  public deleteCampaign(id, check) {
    const url = this.configService.config.deleteCampaign;
    check = check ? 1 : 0;
    return this.httpService.delete(url + id + '/' + check);
  }

  public syncCampaign(id) {
    const url = this.configService.config.syncCampaign;
    return this.httpService.put(url, { campaign: { id } });
  }

  public generateShortUrl(longUrl, accountId) {
    const url = this.configService.config.qrCodeGenerate;
    const query = new HttpParams()
      .set('accountId', accountId ? accountId : -1)
      .set('url', longUrl)
      .set('title', '_' + Math.random().toString(36).substr(2, 5));
    return this.httpService.get(url, { params: query });
  }

  public generateHolotwinShortUrl(longUrl, accountId) {
    const url = this.configService.config.qrCodeGenerate;
    const query = new HttpParams()
      .set('accountId', accountId ? accountId : -1)
      .set('url', longUrl)
      .set('title', '_' + Math.random().toString(36).substr(2, 5));
    return this.httpService.get(url, { params: query });
  }
}
