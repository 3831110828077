import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CampaignsService {
  public campaignsList: any = [];
  public campaigns$: Observable<any> = null;
  private campaignsSource = new Subject<any>();

  constructor(
    private httpService: HttpClient,
    private configService: ConfigService,
  ) {
    this.campaigns$ = this.campaignsSource.asObservable();
  }

  public getCampaignsByAccount(accountID, markerID) {
    const url = this.configService.config.getCampaignsByAccount;
    const query = new HttpParams()
      .set('account_id', accountID)
      .set('marker_id', markerID);
    return this.httpService.get(url, { params: query });
  }

  public getCampaignMarkers(campaignId?) {
    const url = this.configService.config.getCampaignMarkers;
    const query = new HttpParams()
      .set('campaignId', campaignId)
    return this.httpService.get(url, { params: query });
  }

  public getAllCampaigns(campaignId?) {
    let params = null
    if (campaignId) {
      params = new HttpParams().set('campaignId', campaignId)
    }
    return this.httpService.get(this.configService.config.getAllCampaigns, { params: params}).pipe(
      map(
      (res: any) => {
        if (res.message && res.message === 'success') {
          if (res.data.campaigns) {
            this.campaignsList = [];
            res.data.campaigns.forEach((item: any) => {
              item.campaigns.forEach((camp: any) => {
                this.campaignsList.push({
                  value: camp.campaign_id, label: camp.campaign_name,
                  instanceId: camp.campaign_instance_id,
                  accountId: camp.campaign_account_id,
                  status: camp.status_name,
                });
              });
            });
          }
        }
        this.campaignsSource.next(res);
        return res;
      }));
  }
}
