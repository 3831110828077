import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AnalyticsService {
  public activeChartView: string = 'totalDetails';
  public titleName: string = 'Analytics overview';
  public activeChartsType: boolean = false;
  public detailsView: boolean = false;
  public actionsDetailsView: boolean = false;
  public outerActionsDetailsView: boolean = false;
  public chartsData$: Observable<any> = null;
  public chartsDataSource = new Subject<any>();

  constructor(
    private httpService: HttpClient,
    private configService: ConfigService,
  ) {
    this.chartsData$ = this.chartsDataSource.asObservable();
  }

  public getFilters(data) {
    const url = this.configService.config.analyticsGetFilter;
    let request = url;
    if (data !== null) {
      request = url + '?' + data.category + '=' + data.value.join();
    }
    return this.httpService.get(request);
  }

  public getAnalytics(res) {
    return this.httpService.post(this.configService.config.analytics, res);
  }

  public getMarkerlessAnalytics(res) {
    return this.httpService.post(this.configService.config.analyticsMarkerless, res);
  }

  public getWorldviewAnalytics(res) {
    return this.httpService.post(this.configService.config.analyticsWorldview, res);
  }

  public getTrialUserReportAnalytics(res) {
    return this.httpService.get(this.configService.config.analyticsTrialUserReport + '?' + res);
  }

  public getJsonAnalytics(res) {
    return this.httpService.get(res);
  }

  getAnalyticsWorldview(instanceId, fromDate, toDate, holotwins, portals, things, games, type, events) {
    const dateRange = {from: fromDate, to:toDate, holotwins: holotwins, portals: portals, things: things, games: games, type: type, events: events}
    const url = this.configService.config.getAnalyticsWorldview;
    return this.httpService.post<any>(url, dateRange)
  }

  getAnalyticsMapMarkers(instanceId, fromDate, toDate, holotwins, portals, things, games, type, events) {
    const dateRange = {from: fromDate, to:toDate, holotwins: holotwins, portals: portals, things: things, games: games, type: type, events: events}
    const url = this.configService.config.getAnalyticsMapMarkers;
    return this.httpService.post<any>(url, dateRange)
  }

  getAnalyticsExperience(instanceId, fromDate, toDate) {
    const dateRange = {from: fromDate, to:toDate}
    const url = this.configService.config.getAnalyticsExperience;
    return this.httpService.post<any>(url, dateRange)
  }

  getAnalyticsExperienceBrowsers(instanceId, fromDate, toDate, holotwins, portals, things, games, type, events) {
    const dateRange = {from: fromDate, to:toDate, holotwins: holotwins, portals: portals, things: things, games: games, type: type, events: events}
    const url = this.configService.config.getAnalyticsExperienceBrowsers;
    return this.httpService.post<any>(url, dateRange)
  }

  getAnalyticsExperienceDevices(instanceId, fromDate, toDate, holotwins, portals, things, games,  type, events) {
    const dateRange = {from: fromDate, to:toDate, holotwins: holotwins, portals: portals, things: things, games: games, type: type, events: events}
    const url = this.configService.config.getAnalyticsExperienceDevices;
    return this.httpService.post<any>(url, dateRange)
  }

  getAnalyticsExperienceReferals(instanceId, fromDate, toDate, holotwins, portals, things, games,  type, events) {
    const dateRange = {from: fromDate, to:toDate, holotwins: holotwins, portals: portals, things: things, games: games, type: type, events: events}
    const url = this.configService.config.getAnalyticsExperienceReferals;
    return this.httpService.post<any>(url, dateRange)
  }

  getAnalyticsWorldviewByExperience(instanceId, fromDate, toDate) {
    const dateRange = {from: fromDate, to:toDate, instanceId}
    const url = this.configService.config.getAnalyticsWorldviewByExperience;
    return this.httpService.post<any>(url, dateRange)
  }

  getAnalyticsWorldviewDetail(fromDate, toDate, holotwins, things, portals, games) {
    const data = {from: fromDate, to:toDate, holotwins: holotwins, things: things, portals: portals, games: games}
    const url = this.configService.config.getAnalyticsWorldviewDetail;
    return this.httpService.post<any>(url, data)
  }

  createReport(data) {
    const url = this.configService.config.createReport;
    return this.httpService.post<any>(url, data)
  }

  modifyReport(id, form) {
    const data = {id: id, form: form}
    const url = this.configService.config.modifyReport;
    return this.httpService.post<any>(url, data)
  }

  deleteReport(id, userId) {
    const data = {id: id, userId: userId}
    const url = this.configService.config.deleteReport;
    return this.httpService.post<any>(url, data)
  }

  removeFromGroup(experience, group) {
    const data = {experience: experience, group: group}
    const url = this.configService.config.removeFromGroup;
    return this.httpService.post<any>(url, data)
  }

  addToReport(selectedExperiences, selectedReports) {
    const data = {selectedExperiences: selectedExperiences, selectedReports: selectedReports}
    const url = this.configService.config.addToReport;
    return this.httpService.post<any>(url, data)
  }

  getReportList(filter) {
    const url = this.configService.config.getReportList;
    return this.httpService.post<any>(url, filter)
  }

  getAccountWorldviewArrays(data) {
    const url = this.configService.config.getAccountWorldviewArrays;
    return this.httpService.post<any>(url, data)
  }

  getLeaderBoard(id) {
    const url = this.configService.config.getLeaderBoard;
    return this.httpService.get<any>(`${url}/${id}`)
  }


  public getCurrentViewTable(check) {
    if (check === 'mainTable') {
      return !this.detailsView && !this.actionsDetailsView && !this.outerActionsDetailsView;
    }
    if (check === 'detailsLevel') {
      return this.detailsView;
    }
    if (check === 'actionsLevel') {
      return this.actionsDetailsView;
    }
    if (check === 'detailsOuterLevel') {
      return this.detailsView || this.outerActionsDetailsView;
    }
    if (check === 'outerActionsLevel') {
      return this.outerActionsDetailsView;
    }
    return this.detailsView || this.actionsDetailsView || this.outerActionsDetailsView;
  }
}
