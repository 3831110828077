import { Injectable } from '@angular/core';
import { BucketsService } from '../services/buckets.service';
import { environment } from '../../environments/environment';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';

@Injectable()
export class UploadFileToS3 {
  public readonly albumBucketName: string = '';
  public readonly bucketRegion: string = '';
  private readonly identityPoolId: string = '';
  public readonly youtubeKey: string = 'AIzaSyDz8Qk4GjN8TFJAWb8D90UAOLoEvuaMQi0';
  public readonly youtubeChannelId: string = 'UC7FcoXHMSAybBwjAyXg2F9g';
  private cloudUrlStage: string = 'https://blu-files-stage.realityblu.com';
  private cloudUrlProd: string = 'https://blu-files.realityblu.com';
  private regexStage: RegExp = /https:\/\/blu-files-stage.s3.amazonaws.com/i;
  private regexProd: RegExp = /https:\/\/blu-files-prod.s3.us-east-2.amazonaws.com/i;
  public emailRegex: RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public nameRegex: RegExp = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
  public phoneRegex: RegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  public positiveNumbersRegex: RegExp = /^[1-9]\d*$/;
  public floatNumbersRegex: RegExp = /^\d*[.]?\d{0,2}$/;
  public templateModelImageSupport: string = 'http://www.clker.com/cliparts/e/3/a/1/1262039493715993513cuboadriii-th.png';
  private config: any = null;
  public percentageLoaded: string = '';

  constructor(
    private bucketsService: BucketsService,
  ) {
    if (environment.ENV === 'dev') {
      this.albumBucketName = 'dev-blu-files';
      this.bucketRegion = bucketsService.BUCKET_REGION_EU;
      this.identityPoolId = bucketsService.IDENTITY_POOL_ID_EU;
    } else if (environment.ENV === 'local') {
      this.albumBucketName = 'blu-files-local';
      this.bucketRegion = bucketsService.BUCKET_REGION_EU;
      this.identityPoolId = bucketsService.IDENTITY_POOL_ID_EU;
    } else if (environment.ENV === 'test') {
      this.albumBucketName = 'test-blu-files';
      this.bucketRegion = bucketsService.BUCKET_REGION_US;
      this.identityPoolId = bucketsService.IDENTITY_POOL_ID_US;
    } else if (environment.ENV === 'stage') {
      this.albumBucketName = 'stage-blu-files';
      this.bucketRegion = bucketsService.BUCKET_REGION_US;
      this.identityPoolId = bucketsService.IDENTITY_POOL_ID_US;
    } else if (environment.ENV === 'prod') {
      this.albumBucketName = 'prod-blu-files';
      this.bucketRegion = bucketsService.BUCKET_REGION_US;
      this.identityPoolId = bucketsService.IDENTITY_POOL_ID_US;
    }
    this.config = AWS.config.update({
      region: this.bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials(
        {
          IdentityPoolId: this.identityPoolId,
        }),
    });
  }

  public uploadFile(key, body) {
    const bucket = new S3({ params: { Bucket: this.albumBucketName } });
    const params = {
      Bucket: this.albumBucketName,
      Key: key, Body: body,
      ACL: 'public-read-write',
      headers: 'Access-Control-Allow-Origin: *',
    };
    return bucket.upload(params, () => {
    }).on('httpUploadProgress', () => {
    }).promise().then((data) => {
      if (environment.ENV === 'stage') {
        data.Location = UploadFileToS3.generationUrl(data.Location, this.regexStage, this.cloudUrlStage);
      }
      if (environment.ENV === 'prod') {
        data.Location = UploadFileToS3.generationUrl(data.Location, this.regexProd, this.cloudUrlProd);
      }
      return data;
    });
  }

  public uploadFileForMarkerless(key, body) {
    const bucket = new S3({ params: { Bucket: this.albumBucketName } });
    const params = {
      Bucket: this.albumBucketName,
      Key: key, Body: body,
      ACL: 'public-read-write',
      headers: 'Access-Control-Allow-Origin: *',
    };
    return bucket.upload(params, () => {
    }).on('httpUploadProgress', () => {
    }).promise().then((data) => {
      return data;
    });
  }

  public uploadFileAsImage(key, body) {
    const bucket = new S3({ params: { Bucket: this.albumBucketName } });
    const fileType = body.type;
    const params = {
      Bucket: this.albumBucketName,
      Key: key, Body: body,
      ACL: 'public-read-write',
      headers: 'Access-Control-Allow-Origin: *',
      Metadata: {
        'Content-Type': fileType
      },
      ContentType: fileType
    };
    return bucket.upload(params, () => {
    }).on('httpUploadProgress', () => {
    }).promise().then((data) => {
      return data;
    });
  }

  public async uploadLargeFile(key, body) {
    const bucket = new S3({ params: { Bucket: this.albumBucketName } });
    const params = {
      Bucket: this.albumBucketName,
      Key: key, 
      Body: body,
      ACL: 'public-read-write',
      headers: 'Access-Control-Allow-Origin: *',
      Metadata: {
        'Content-Type': 'video/mp4'
      },
      ContentType: 'video/mp4',
      PartSize: 1024 * 1024 * 5,
      QueueSize: 10000,
    };

    bucket.config.httpOptions.timeout = 0;
    return bucket.upload(params, {
      queueSize: 10000,
      partSize: 1024 * 1024 * 5
    }, (err, data) => {
      if (err) {
         return console.log("Error uploading video", err);
      }
      console.log("Successfully uploaded video!", data);
    }).on("httpUploadProgress", progress => {
      this.percentageLoaded = `Upload Progress: ${Math.round(progress.loaded / progress.total * 100)}%`;
      if (document.getElementsByClassName('loading-text')[0]) {
        document.getElementsByClassName('loading-text')[0].innerHTML = this.percentageLoaded;
      }
    }).promise().then((data) => {
      return data;
    });
  }


  private static generationUrl(location, regex, url) {
    return location.replace(regex, url);
  }
}
