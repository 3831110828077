import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ConfigService } from './config.service';
import { AuthService } from './auth.service';

@Injectable()
export class MarkerlessService {
  public files$: Observable<any> = null;
  private filesSource = new Subject<any>();
  public builderModel$: Observable<any> = null;
  public builderModelSource = new Subject<any>();
  public markerLess$: Observable<any> = null;
  private markerLessSource = new Subject<any>();
  public activeView: string = 'table';
  public subCategories: any = null;
  public categories: any = null;

  constructor(
    private httpService: HttpClient,
    private configService: ConfigService,
    private authService: AuthService,
  ) {
    this.files$ = this.filesSource.asObservable();
    this.builderModel$ = this.builderModelSource.asObservable();
    this.markerLess$ = this.markerLessSource.asObservable();
  }

  public getCategories(id) {
    const url = this.configService.config.getMarkerLessCategories;
    this.httpService.get(`${url}${id}/${this.authService.token}`).subscribe(
      (res: any) => {
        this.filesSource.next(res);
      });
  }

  public getMarkerlessNoCategory(id) {
    const url = this.configService.config.getMarkerlessNoCategory;
    this.httpService.get(`${url}${id}/${this.authService.token}`).subscribe(
      (res: any) => {
        this.markerLessSource.next(res);
      });
  }

  public getMarkerlessSubcategory(listId) {
    const url = this.configService.config.getMarkerlessSubcategories;
    return this.httpService.get(`${url}${listId}/${this.authService.token}`);
  }

  public updateWebarMarkerless(rawObject) {
    console.log(rawObject)
    const url = this.configService.config.updateMarkerless;
    return this.httpService.put(url, { object: rawObject });
  }

  public updateMarkerlessStatus(rawObject) {
    console.log(rawObject)
    const url = this.configService.config.updateMarkerlessStatus;
    return this.httpService.post(url, { ...rawObject });
  }

  private serializeModelObject(model) {
    console.log(model)
    return model.map(el => ({
      file: el.file,
      real_name: el.real_name ? el.real_name : el.file.split('/').pop(),
      short_url: el.short_url ? el.short_url : el.file,
      size: el.size,
      type: el.type,
    }));
  }

  public updateMarkerless(rawObject) {
    console.log(rawObject)
    const fbxObject = rawObject.models.wikitude.find(el => el.type === 'fbx');
    const wt3Object = rawObject.models.wikitude.find(el => el.type === 'wt3');
    const object = {
      id: rawObject.id,
      name: rawObject.name,
      group_id: rawObject.group_id,
      account_id: rawObject.account_id,
      obj_animations: rawObject.obj_animations,
      scale: rawObject.scale,
      position: rawObject.position,
      rotation: rawObject.rotation,
      audio_id: rawObject.audio_id,
      audio_loop: rawObject.audio_loop,
      audio_rotation: rawObject.audio_rotation,
      scale_mobile: rawObject.scale_mobile,
      image: rawObject.image,
      preview: rawObject.image ? rawObject.image : '',
      models: rawObject.models.wikitude.length > 0
        ? [...this.serializeModelObject(rawObject.models.wikitude)]
        : [
          {
            file: fbxObject.file,
            real_name: fbxObject.real_name ? fbxObject.real_name : fbxObject.file.split('/').pop(),
            short_url: fbxObject.short_url ? fbxObject.short_url : fbxObject.file,
            size: fbxObject.size,
            type: 'fbx',
          },
          {
            file: wt3Object.file,
            real_name: wt3Object.real_name ? wt3Object.real_name : wt3Object.file.split('/').pop(),
            short_url: wt3Object.short_url ? wt3Object.short_url : wt3Object.file,
            size: wt3Object.size,
            type: 'wt3',
          },
        ],
      shop_url: rawObject.shop_url,
      title: rawObject.name,
    };
    const url = this.configService.config.updateMarkerless;
    return this.httpService.put(url, { object });
  }

  public getMarkerlessObjects(groupId) {
    const url = this.configService.config.getMarkerlessObjects;
    return this.httpService.get(`${url}${groupId}/${this.authService.token}`);
  }

  public createCategory(name, image, id) {
    const url = this.configService.config.createMarkerlessCategory;
    const body = { category: { image, name, account_id: id } };
    return this.httpService.post(url, body);
  }

  public convertMarkerlessObject(modelId: any, modelType: 'glb' | 'usdz', shouldActivate) {
    const url = this.configService.config.convertMarkerlessObject;
    return this.httpService.get(`${url}?model_id=${modelId}&model_type=${modelType}&activate=${shouldActivate}`);
  }

  public updateCategoryName(id, name) {
    const url = this.configService.config.updateMarkerlessCategory;
    const body = { category: { id, name } };
    return this.httpService.put(url, body);
  }

  public updateSubCategoryName(id, name) {
    const url = this.configService.config.updateMarkerlessSubCategory;
    const body = { sub_category: { id, name } };
    return this.httpService.put(url, body);
  }

  public updateCategoryImage(id, image) {
    const url = this.configService.config.updateMarkerlessCategory;
    const body = { category: { id, image } };
    return this.httpService.put(url, body);
  }

  public deleteCategory(id) {
    const url = this.configService.config.deleteMarkerlessCategory;
    return this.httpService.delete(`${url}${id}/${this.authService.token}`);
  }

  public deleteSubCategory(id) {
    const url = this.configService.config.deleteMarkerlessSubCategory;
    return this.httpService.delete(`${url}${id}/${this.authService.token}`);
  }

  public createSubFolder(name, listId) {
    const url = this.configService.config.createMarkerlessSubCategory;
    const body = { sub_category: { name, list_id: listId } };
    return this.httpService.post(url, body);
  }

  public deleteMarkerLessObject(id) {
    const url = this.configService.config.deleteMarkerlessObject;
    return this.httpService.delete(`${url}${id}/${this.authService.token}`);
  }

  public uploadNewModel(objects, accountId) {
    console.log('uploadNewModel');
    const url = this.configService.config.createMarkerlessObject;
    const body = { accountId, objects };
    return this.httpService.post(url, body);
  }

  public deleteFileFromModel(id) {
    console.log('deleteFileFromModel');
    const url = this.configService.config.deleteFileFromModel;
    return this.httpService.delete(`${url}${id}`);
  }
}
