import { BrowserModule } from '@angular/platform-browser';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppState } from './app.service';
import { AppRoutes } from './app.routes';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServerLocationInterceptor } from './interceptor/server-request.interceptor';
// import * as jQuery from 'jquery';
// import * as Chart from 'chart.js'
// resolvers
import { HomeResolver } from './resolvers/home-resolver.service';
import { HomeResolverOutService } from './resolvers/home-resolverOut.service';
// guards
import { IsLoginRouteGuard } from './guards/is-login';
import { NoLoginRouteGuard } from './guards/no-login';
// system modules
import { SystemFilterModule } from './components/systemFilter/systemFilter.module';
import { ProofHelperModule } from './components/proofHelper/proofHelper.module';
import { WebArQrCodeModule } from './components/web-ar-qr-code/webArQrCode.module';
import { FormatDatePipeModule } from './helpers/formatDatePipe.module';
import { FormatSizePipeModule } from './helpers/formatSizePipe.module';
import { FormatTimePipeModule } from './helpers/formatTimePipe.module';
import { HomeModule } from './components/home/home.module';
import { AgmCoreModule } from '@agm/core';
import { NgIdleModule } from '@ng-idle/core';
// services
import { AuthService } from './services/auth.service';
import { AnalyticsService } from './services/analytics.service';
import { AccountsService } from './services/accounts.service';
import { AccountService } from './services/account.service';
import { BucketsService } from './services/buckets.service';
import { StripeService } from './services/stripe.service';
import { BudgetsService } from './services/budgets.service';
import { CampaignService } from './services/campaign.service';
import { CampaignsService } from './services/campaigns.service';
import { CookieService } from 'ngx-cookie-service';
import { CountriesService } from './services/countries.service';
import { ConfigService } from './services/config.service';
import { DatesService } from './services/dates.service';
import { ExperienceService } from './services/experience.service';
import { ExperiencesService } from './services/experiences.service';
import { ErrorsService } from './services/errors.service';
import { HomeService } from './services/home.service';
import { LocationService } from './services/location.sevice';
import { InstancesService } from './services/instances.service';
import { InstanceService } from './services/instance.service';
import { LoginService } from './services/login.service';
import { MarkerbasedService } from './services/markerbased.service';
import { MarkerlessService } from './services/markerless.service';
import { MobileAppService } from './services/mobile.service';
import { RolesService } from './services/roles.service';
import { SubExperiencesService } from './services/subExperiences.service';
import { SystemFiltersService } from './services/systemFilters.service';
import { QueryStringService } from './services/queryString.service';
import { UserService } from './services/user.service';
// helpers
import { MessageService } from 'primeng/api';
import { PaginatorHelper } from './helpers/paginatorHelper';
import { Proof } from './helpers/proof';
import { LocationsHelper } from './helpers/locationsHelper';
import { FormatTime } from './helpers/markerBased/formatTime';
import { FormatDatePipe } from './helpers/formatDatePipe';
import { FormatTimePipe } from './helpers/formatTimePipe';
import { WindowResizeMobile } from './helpers/windowResizeMobile';
import { RequestToDb } from './helpers/requestToDb';
import { UploadFileToS3 } from './helpers/uploadFileToS3';
import { ScrollingModule } from '@angular/cdk/scrolling'
// primeng modules

import { DropdownModule } from 'primeng/dropdown'
import { ToastModule } from 'primeng/toast'
import { TabViewModule } from 'primeng/tabview'
import { DialogModule } from 'primeng/dialog'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { TooltipModule } from 'primeng/tooltip'
import { CheckboxModule } from 'primeng/checkbox'
import { AccordionModule } from 'primeng/accordion'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { CalendarModule } from 'primeng/calendar'
import { ChartModule } from 'primeng/chart'
import { ConfirmationService } from 'primeng/api'
import { DataViewModule } from 'primeng/dataview'
import { InputSwitchModule } from 'primeng/inputswitch'
import { MultiSelectModule } from 'primeng/multiselect'
import { OrderListModule } from 'primeng/orderlist'
import { RadioButtonModule } from 'primeng/radiobutton'
import { RatingModule } from 'primeng/rating'
// import { FullCalendarModule } from '@fullcalendar/angular'
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
// import listPlugin from '@fullcalendar/list';
// import interactionPlugin from '@fullcalendar/interaction';
import { SharedModule } from 'primeng/api'
import { TableModule } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InstancesResolver } from './resolvers/instances-resolver.service';
import { AccountsResolver } from './resolvers/accounts-resolver.service';
import { UsersResolver } from './resolvers/users-resolver.service';
import { UserResolver } from './resolvers/user-resolver.service';
import { AccountResolver } from './resolvers/account-resolver.service';
import { InstanceResolver } from './resolvers/instance-resolver.service';
import { WorkflowService } from './services/workflow.service';
import { WebarAppService } from './services/webar-app.service';
import { environment } from '../environments/environment';
import { ActiveMarkersModule } from './components/active-markers/active-markers.module';


import { VectorStringPipe} from './pipes/vector-string.pipe';
import { ActiveIconPipe} from './pipes/active-icon.pipe';
import { ExtractUserPipe} from './pipes/extract-user.pipe';
import { OpenDoorDialogComponent } from './components/open-door-dialog/open-door-dialog.component';
import { YesNoDialogComponent} from './components/yes-no-dialog/yes-no-dialog.component';
import { TruncatePipe } from './pipes/truncate.pipe';
// import { APreviewComponent } from './components/a-preview/a-preview.component';

// import { ImageCropperModule } from 'ngx-image-cropper';


// FullCalendarModule.registerPlugins([
//   dayGridPlugin,
//   timeGridPlugin,
//   interactionPlugin,
//   listPlugin
// ]);

// const PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//   suppressScrollX: true,
//   wheelPropagation: true,
// };

// (window as any).jQuery = (window as any).$ = jQuery;

const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS,
  AppState,
];
declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}

const ENV = environment.ENV

console.log(ENV)
// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//   suppressScrollX: true,
// };

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    // APreviewComponent
  ],
  imports: [
    HomeModule,
    SystemFilterModule,
    ProofHelperModule,
    FormatDatePipeModule,
    FormatSizePipeModule,
    FormatTimePipeModule,
    AppRoutes,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
    CheckboxModule,
    DropdownModule,
    ReactiveFormsModule,
    TableModule,
    TooltipModule,
    TabViewModule,
    DialogModule,
    DataViewModule,
    InputSwitchModule,
    RadioButtonModule,
    DropdownModule,
    ToastModule,
    CalendarModule,
    AccordionModule,
    ConfirmDialogModule,
    OrderListModule,
    AutoCompleteModule,
    RatingModule,
    // FullCalendarModule,
    ActiveMarkersModule,
    MultiSelectModule,
    ChartModule,
    WebArQrCodeModule,
    NgbModule,
    ScrollingModule,
    NgIdleModule.forRoot(),
    
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAjd5iqF0dibawDmo39ibYLiuPkWw1iiDQ',
      libraries: ['places'], language: 'en',
    }),
  ],
  providers: [
    APP_PROVIDERS,
    environment.ENV_PROVIDERS,
    // ENV,
    InstancesResolver,
    InstanceResolver,
    AccountsResolver,
    AccountResolver,
    UsersResolver,
    UserResolver,
    InstancesService,
    InstanceService,
    AccountsService,
    AccountService,
    UserService,
    HomeService,
    AuthService,
    CookieService,
    SystemFiltersService,
    MessageService,
    LoginService,
    MobileAppService,
    CampaignService,
    CampaignsService,
    ExperienceService,
    ExperiencesService,
    MarkerbasedService,
    MarkerlessService,
    ConfigService,
    AnalyticsService,
    DatesService,
    BucketsService,
    StripeService,
    CountriesService,
    ErrorsService,
    LocationService,
    QueryStringService,
    BudgetsService,
    ConfirmationService,
    SubExperiencesService,
    WebarAppService,
    RolesService,
    HomeResolver,
    WorkflowService,
    HomeResolverOutService,
    SystemFilterModule,
    ProofHelperModule,
    WebArQrCodeModule,
    UploadFileToS3,
    RequestToDb,
    FormatDatePipe,
    FormatTimePipe,
    PaginatorHelper,
    Proof,
    LocationsHelper,
    FormatTime,
    WindowResizeMobile,
    IsLoginRouteGuard,
    NoLoginRouteGuard,
    ActiveMarkersModule,
    OpenDoorDialogComponent,
    YesNoDialogComponent,
    VectorStringPipe,
    ActiveIconPipe,
    ExtractUserPipe,
    TruncatePipe,
    // ImageCropperModule,
    // {
    //   // provide: PERFECT_SCROLLBAR_CONFIG,
    //   // useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    // },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    ServerLocationInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerLocationInterceptor, multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
