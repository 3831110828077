import { Subscription } from 'rxjs';


export class SubscriptionsPool {
  protected subscriptions: Array<Subscription> = [];

  protected unsubscribePool() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  protected addSubscription(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  protected addSubscriptions(...subs) {
    this.subscriptions.push(...subs);
  }
}
