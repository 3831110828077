import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebArQrCodeComponent } from './webArQrCode.component';
import { QRCodeModule } from 'angularx-qrcode';
import { LoaderModule } from '../loader/loader.module';import { DropdownModule } from 'primeng/dropdown'
import { ToastModule } from 'primeng/toast'
import { DialogModule } from 'primeng/dialog'
import { ReactiveFormsModule } from '@angular/forms';
import { SupportService } from '../../services/support.service';
import { ActiveMarkersModule } from '../active-markers/active-markers.module';
import { ActiveMarkersComponent } from '../active-markers/';

@NgModule({
  imports: [CommonModule, QRCodeModule, DialogModule, ToastModule,
    LoaderModule, ReactiveFormsModule, DropdownModule, ActiveMarkersModule],
  declarations: [WebArQrCodeComponent],
  exports: [WebArQrCodeComponent],
  providers: [WebArQrCodeComponent, SupportService],
})
export class WebArQrCodeModule {
}
