import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable()
export class SubExperiencesService {
  constructor(
    private configService: ConfigService,
    private httpService: HttpClient,
    private authService: AuthService,
  ) {
  }

  public getSubByExperienceId(id) {
    const url = this.configService.config.getSubByExperienceId;
    return this.httpService.get(url + id + '/' + this.authService.token);
  }

  public getBySubExperienceId(id) {
    const url = this.configService.config.getBySubExperienceId;
    return this.httpService.get(url + id);
  }

  public deleteSubExperienceById(id) {
    const url = this.configService.config.deleteSubExperience;
    return this.httpService.delete(url + id + '/' + this.authService.token);
  };

  public changeLocationSubExperience(subExperience) {
    const url = this.configService.config.changeLocationSubExperience;
    return this.httpService.put(
      url, { token: this.authService.token, sub_experience: subExperience },
    );
  }

  public updateSubExperienceById(id, name, json) {
    const url = this.configService.config.updateSubExperience;
    return this.httpService.put(
      url, { token: this.authService.token, sub_experience: { id, name, json_file: json } },
    );
  }
}
