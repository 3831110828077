import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProofHelperRoutingModule } from './proofHelper-routing.module';
import { ProofHelperComponent } from './proofHelper.component';
import { QRCodeModule } from 'angularx-qrcode';
import { LoaderModule } from '../loader/loader.module';import { DropdownModule } from 'primeng/dropdown'
import { ToastModule } from 'primeng/toast'
import { DialogModule } from 'primeng/dialog'
import { ReactiveFormsModule } from '@angular/forms';
import { SupportService } from '../../services/support.service';

@NgModule({
  imports: [CommonModule, ProofHelperRoutingModule, QRCodeModule, DialogModule, ToastModule,
    LoaderModule, ReactiveFormsModule, DropdownModule],
  declarations: [ProofHelperComponent],
  exports: [ProofHelperComponent],
  providers: [ProofHelperComponent, SupportService],
})
export class ProofHelperModule {
}
