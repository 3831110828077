import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ConfigService } from './config.service';
import { AuthService } from './auth.service';
import { QueryStringService } from './queryString.service';
import { WorkflowService } from './workflow.service';

@Injectable()
export class MarkerbasedService {
  public showUploadPopup$: Observable<any> = null;
  public showUploadPopupSource = new Subject<any>();
  public limitExperience$: Observable<any> = null;
  private limitExperienceSource = new Subject<any>();
  public showUploadPopupFromBuilder: boolean = false;
  public loadUploadPopupFromBuilderMarkers: boolean = false;
  public isChangesInGallery: boolean = false;
  public closeBuilderButton: any = null;
  public messageError$: Observable<string> = null;
  public messageErrorSource = new Subject<string>();
  public recognitionTypeQuery: HttpParams | null = null;

  constructor(
    private httpService: HttpClient,
    private configService: ConfigService,
    private queryStringService: QueryStringService,
    private authService: AuthService,
    private workflow: WorkflowService,
  ) {
    this.showUploadPopup$ = this.showUploadPopupSource.asObservable();
    this.limitExperience$ = this.limitExperienceSource.asObservable();
    this.messageError$ = this.messageErrorSource.asObservable();
  }

  public getFreeMarkers(accountId, markerId) {
    const url = this.configService.config.getFreeMarkers;
    this.recognitionTypeQuery = new HttpParams()
      .set('recognitionType', this.workflow.getWorkflow())
      .set('marker_id', markerId);
    return this.httpService.get(url + accountId + '/' + this.authService.token, { params: this.recognitionTypeQuery });
  }

  public getSubExperiences(experienceId) {
    const url = this.configService.config.getSubByExperienceId;
    // this.recognitionTypeQuery = new HttpParams().set('recognitionType', this.workflow.getWorkflow());
    return this.httpService.get(url + experienceId + '/' + this.authService.token); // , { params: this.recognitionTypeQuery }
  }

  public getFiles(id, params) {
    const url = this.configService.config.getMediaFilesList;
    this.recognitionTypeQuery = new HttpParams().set('recognitionType', this.workflow.getWorkflow());
    const paramsToBack = this.queryStringService.toQueryString(params, null);
    return this.httpService.get(url + id + '/' + this.authService.token + '?' + paramsToBack, { params: this.recognitionTypeQuery });
  }

  public getFilesForPopup(id, params, isHasRecognition?) {
    const url = this.configService.config.getMediaFilesListForPopup;
    if (isHasRecognition) {
      this.recognitionTypeQuery = new HttpParams().set('recognitionType', this.workflow.getWorkflow());
    }
    const paramsToBack = this.queryStringService.toQueryString(params, null);
    return this.httpService.get(url + id + '/' + this.authService.token + '?' + paramsToBack, isHasRecognition && { params: this.recognitionTypeQuery });
  }

  public getQuotasGallery(accountId) {
    // this.recognitionTypeQuery = new HttpParams().set('recognitionType', this.workflow.getWorkflow());
    return this.httpService.get(this.configService.config.getQuotas + 'gallery/' + accountId); // , { params: this.recognitionTypeQuery }
  }

  public getQuotasExperience(accountId) {
    // this.recognitionTypeQuery = new HttpParams().set('recognitionType', this.workflow.getWorkflow());
    this.httpService.get(this.configService.config.getQuotas + 'experience/' + accountId).subscribe( // , { params: this.recognitionTypeQuery }
      (res: any) => {
        this.limitExperienceSource.next(res);
      });
  }

  public uploadNewFile(objects, accountId, type) {
    const data = objects.map(item => Object.assign(type === 'markers' ? {recognitionType: this.workflow.getWorkflow()} : {}, item));
    data.forEach(value => delete value.logo);
    if (!this.workflow.isWebAR()) {
      if (type === 'models') {
        data.forEach((value: any) => {
          delete value.havePair;
          delete value.extension;
        });
      }
    }
    const url = this.configService.config.uploadNewFile;
    const body = { accountId, type, objects: data };
    return this.httpService.post(url, body);
  }

  public updateItems(type, object) {
    const url = this.configService.config.updateItem;
    this.recognitionTypeQuery = new HttpParams().set('recognitionType', this.workflow.getWorkflow());
    return this.httpService.put(url, { type, object, token: this.authService.token });
  }

  public deleteItem(type, id) {
    const url = this.configService.config.deleteItem;
    this.recognitionTypeQuery = new HttpParams().set('recognitionType', this.workflow.getWorkflow());
    return this.httpService.delete(url + type + '/' + id + '/' + this.authService.token, { params: this.recognitionTypeQuery });
  }

  public getMarkersRating(ids) {
    const url = this.configService.config.checkMarkersRating;
    // this.recognitionTypeQuery = new HttpParams().set('recognitionType', this.workflow.getWorkflow());
    ids = ids ? '?' + ids : '';
    return this.httpService.get(url + this.authService.token + ids); // , { params: this.recognitionTypeQuery }
  }
}
