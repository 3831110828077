import { Injectable } from '@angular/core';

@Injectable()
export class QueryStringService {
  constructor() {
  }

  public toQueryString(object, base) {
    const queryString = [];
    Object.keys(object).forEach((key) => {
      let result;
      const value = object[key];
      if (value) {
        if (base) {
          key = base + '[' + key + ']';
        }
        const type = typeof value;
        if (type === 'object') {
          if (value.length) {
            const qs = {};
            value.forEach((val, i) => {
              qs[i] = val;
            });
            result = this.toQueryString(qs, key);
          } else {
            result = this.toQueryString(value, key);
          }
        } else {
          result = key + '=' + encodeURIComponent(value);
        }
        if (result && value) {
          queryString.push(result);
        }
      }
    });
    return queryString.join('&');
  };
}
