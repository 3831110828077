import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { ConfirmationService} from 'primeng/api';
import { Message } from 'primeng/api'
import { ErrorsService } from './errors.service';
import { MessageService } from 'primeng/api';
import { UntypedFormGroup } from '@angular/forms';


@Injectable()
export class UserService {
  public user: any = null;
  public userForm: UntypedFormGroup = null;
  public msgs: Message[] = [];
  loading: any;
  public superUser: any = null;
  

  constructor(
    private httpService: HttpClient,
    private authService: AuthService,
    private configService: ConfigService,
    private confirmationService: ConfirmationService,
    private errorsService: ErrorsService,
    private messageService: MessageService,
  ) {
  }

  public getUsers() {
    const url = this.configService.config.getUsers;
    return this.httpService.get(url + this.authService.token);
  }

  public getSearchList(constraints) {
    const url = this.configService.config.getSearchList;
    return this.httpService.post(url + this.authService.token, {name:constraints[0], email:constraints[1], account:constraints[2], phone:constraints[3], instance:constraints[4], type:constraints[5]});
  }

  public getUser(id) {
    return this.httpService.get(this.configService.config.getUser + id);
  }

  public createUser(user) {
    delete user.id;
    delete user.level;
    const url = this.configService.config.createUser;
    return this.httpService.post(url, { user, token: this.authService.token });
  }

  public updateUser(user) {
    delete user.level;
    const url = this.configService.config.updateUser;
    return this.httpService.put(url, { user, token: this.authService.token });
  }

  public conversionUser(form) {
    const url = this.configService.config.conversionTrialUser;
    return this.httpService.post(url, {
      userId: form.id, instanceId: form.instanceId, roleId: form.role,
    });
  }

  public deleteUser(id) {
    const url = this.configService.config.deleteUser;
    return this.httpService.delete(url + id);
  }

  public impersonateToUser(impersonatedId, superUser?) {
    const url = this.configService.config.impersonate;
    if (superUser) {
      return this.httpService.post(url, { impersonatedId, superUser })
    }
    else {
      return this.httpService.post(url, { impersonatedId })
    };
  }

   public impersonate(id) {
    this.superUser = this.authService.user
    this.confirmationService.confirm({
      key: 'confirmImpersonate',
      // message: `Are you sure you want to impersonate ${this.userForm.get('firstName').value.bold()} ${this.userForm.get('lastName').value.bold()}?`,
      message: `Are you sure you want to impersonate this user?`,
      accept: () => {
        this.loading = true;
        this.impersonateToUser(id, false).subscribe(
          (res: any) => {
            if (res.message && res.message === 'success') {
              AuthService.setUserToken(res.data.user, res.data.user.token);
              this.authService.useJwtHelper(res.data.user);
            } else {
              this.messageService.add({severity:'error', summary:'Error', detail: this.errorsService.showErrors(res) })
            }
            this.loading = false;
          });
      },
    });
   }
}
