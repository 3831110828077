import { Component, ViewEncapsulation, Input } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from './services/auth.service';
import { ConfigService } from './services/config.service';
import { LoginService } from './services/login.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router'
import { WhiteLabelApiService } from './services/white-label-api'
import { environment } from '../environments/environment';

@Component({
  selector: 'app',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./main.scss', '../../node_modules/primeflex/primeflex.css', '../../node_modules/primeng/resources/primeng.min.css', './primeTheme.css'],
  template: `
    <router-outlet></router-outlet>
  `,
})
export class AppComponent {
  constructor(
    private readonly authService: AuthService,
    private readonly configService: ConfigService,
    private readonly location: Location,
    private readonly routerService: Router,
    private readonly loginService: LoginService,
    public whiteLabelApi: WhiteLabelApiService,
    private route: ActivatedRoute
  ) {
    this.configService.setConfig(environment.ENV);
    const curURL = this.location.path().split('/');
    this.whiteLabelApi.getWhiteLabels(null, null, window.location.hostname)
    this.whiteLabelApi.getWhiteLabelsEvent.subscribe( res => {

    let whiteLabelId
    let whiteLabelUrl
    let data

    // If not signed in, use url to set whitelabel
    if (res.activeId.length === 0) {

      const activeWhiteLabel = res.count.filter( item => item.instance_active_id === item.white_label_id)[0]

      if (activeWhiteLabel) {
      whiteLabelId = activeWhiteLabel.white_label_id
      whiteLabelUrl = activeWhiteLabel.url
      data = res.data.filter( item => item.white_label_id === whiteLabelId)
      } 
      else {
        const systemDefault = res.data.filter( item => item.name.includes('System Default') && item.instance_active_id === item.white_label_id)[0]
        if (systemDefault) {
            whiteLabelId = systemDefault.white_label_id
            whiteLabelUrl = systemDefault.url
            data = res.data.filter( item => item.white_label_id === whiteLabelId)
        }
    }


        if (data && data.length > 0) {
          this.whiteLabelApi.setWhiteLabel(data)
        }
    } 

    // if you are signed in set white label by active id
    else if (res.activeId && res.activeId.length == 1 && res.activeId[0].white_label_id > 0) {


          for (const [key, value] of Object.entries(res.count)) {
            // If a white label is selected, use that one
              if (res.activeId[0].white_label_id > 1) {
                  if (value['white_label_id'] == res.activeId[0].white_label_id) {
                        whiteLabelId = value['white_label_id']  
                        whiteLabelUrl = value['url']
                        data = res.data.filter( item => item.white_label_id === value['white_label_id'])
                  } 
                }
            // if active id is 1, use system default
              else if (res.activeId[0].white_label_id == 1) {
                if (value['white_label_id'] == res.activeId[0].white_label_id) {
                  whiteLabelId = value['white_label_id']  
                  whiteLabelUrl = value['url']
                  data = res.data.filter( item => item.white_label_id === value['white_label_id'])
                } 
              }
              //otherwise something is messed up
              else {
                  console.log('ERROR')
                  console.log(res)
              }
            }


          // If we have good data, update the white label definitions
          if (data && data.length > 0) {this.whiteLabelApi.setWhiteLabel(data)}
    }

  })

    switch (curURL[1]) {
      case 'forgot':
      case 'sign_up':
      case 'trial':
      case 'support':
      case 'proof':
        this.authService.clearTokenNavigate(curURL[1]);
        break;
      case 'desktop_version':
      case 'login':
        this.authService.clearTokenNavigate('login');
        break;
      case 'createpassword':
        this.loginService.checkPassRequest(curURL[2], curURL[3]).subscribe(
          (res: any) => {
            if (res.message && res.message === 'success') {
              this.authService.clearTokenNavigate(`${curURL[1]}/${curURL[2]}/${curURL[3]}`);
            } else {
              this.authService.clearTokenNavigate('login');
            }
          },
        );
        break;
      default:
        if (!localStorage.getItem('token')) {
          this.authService.clearTokenNavigate('login');
        } else {
          this.authService.updateToken();
          this.authService.startUpdateToken();
        }
    }
  }
}
