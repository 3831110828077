<ng-template #openDoorModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Open Door</h5>
    <button type="button" class="close" aria-label="Close" (click)="onCloseModal(null)">
      <span aria-hidden="true">x</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="table-responsive mt-3">
      <table class="table table-striped table-hover">
        <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Actions</th>
          <th scope="col" class="text-center">Thumb</th>
          <th scope="col" class="text-center">Render</th>
          <th scope="col" class="text-center">Uses</th>
          <th scope="col" class="text-center">Active</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of data">
          <!-- Name -->
          <th scope="row" class="align-middle">{{item.name}}</th>
          <!-- Actions -->
          <td class="align-middle text-nowrap">
            <!-- Preview -->
            <button class="btn btn-info btn-action" title="Preview door" (click)="onPreview(item.id)">
              <!-- <fa-icon [icon]="faQrcode" size="lg"></fa-icon> -->
            </button>
            <!-- Edit -->
            <button class="btn btn-info btn-action" title="Edit door" (click)="onEdit(item.id)">
              <!-- <fa-icon [icon]="faEdit" size="lg" transform="up-1 right-2"></fa-icon> -->
            </button>
            <!-- Delete -->
            <button class="btn btn-danger btn-action" title="Delete door" (click)="onDelete(item.id)"
                    [disabled]="item.count > 0">
              <!-- <fa-icon [icon]="faTrash" size="lg" transform="up-1"></fa-icon> -->
            </button>
          </td>
          <!-- Thumb -->
          <td class="align-middle text-center"><a href="{{item['thumb-url']}}" target="_blank" title="See thumb"><img
            src="{{item['thumb-url']}}"></a></td>
          <!-- Render -->
          <td class="align-middle text-center"><a href="{{item['render-url']}}" target="_blank" title="See render"><img
            src="{{item['render-url']}}"></a></td>
          <!-- Uses -->
          <td class="align-middle text-center">{{item.count}}</td>
          <!-- Active -->
          <td class="align-middle text-center">
            <a class="" role="button" title="Toggle active" (click)="onToggleActive(item.id)">
              <!-- <fa-icon [icon]="item.active | activeIcon"></fa-icon> -->
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

