import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RequestToDb {
  constructor(
    private httpService: HttpClient,
  ) {
  }

  public get(link) {
    return new Promise((resolve, reject) => {
      return this.httpService.get(link).subscribe(
        (res: any) => {
          if (!Object.keys(res).length || res.cause) {
            return reject({
              message: 'error',
              metadata: {
                message: res.cause ? 'Bad payload.' : 'Empty response.',
              },
              error: {
                message: res.cause ? 'Bad payload.' : 'Empty response.',
              },
            });
          }
          return res.error ? reject(res) : resolve(res);
        },
        (err: any) => reject(err),
      );
    });
  }

  public post(link, body) {
    return new Promise((resolve, reject) => {
      return this.httpService.post(link, body).subscribe(
        (res: any) => {
          console.log(res)
          if (!Object.keys(res).length || res.cause) {
            return reject({
              message: 'error',
              metadata: {
                message: res.cause ? 'Bad payload.' : 'Empty response.',
              },
              error: {
                message: res.cause ? 'Bad payload.' : 'Empty response.',
              },
            });
          }
          return res.error ? reject(res) : resolve(res);
        },
        (err: any) => reject(err),
      );
    });
  }

  public put(link, body) {
    return new Promise((resolve, reject) => {
      return this.httpService.put(link, body).subscribe(
        (res: any) => {
          if (!Object.keys(res).length || res.cause) {
            return reject({
              message: 'error',
              metadata: {
                message: res.cause ? 'Bad payload.' : 'Empty response.',
              },
              error: {
                message: res.cause ? 'Bad payload.' : 'Empty response.',
              },
            });
          }
          return res.error ? reject(res) : resolve(res);
        },
        (err: any) => reject(err),
      );
    });
  }

  public delete(link) {
    return new Promise((resolve, reject) => {
      return this.httpService.delete(link).subscribe(
        (res: any) => res.error ? reject(res) : resolve(res),
        (err: any) => reject(err),
      );
    });
  }
}
