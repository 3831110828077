import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';

@Injectable()
export class InstancesResolver implements Resolve<any> {
  constructor(
    private routerService: Router,
    private authService: AuthService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.authService.accessLevel$.pipe(
      map(
      (res: any) => {
        if (res === 'system') {
          return true;
        }
        this.routerService.navigate(['/404']);
        return false;
      }));
  }
}
