import {Component, ElementRef, ViewChild} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faEdit, faQrcode, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {PortalApiService} from '../../services/portal-api.service';
import {YesNoDialogComponent} from '../yes-no-dialog/yes-no-dialog.component';
import {Subscription} from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-open-door-dialog',
  templateUrl: './open-door-dialog.component.html',
  styleUrls: [
    './open-door-dialog.component.css',
    '../../../assets/css/bootstrap.css',]
})
export class OpenDoorDialogComponent {
  // Fort Awesome icons
  public faEdit = faEdit;
  public faQrcode = faQrcode;
  public faTrash = faTrashAlt;

  public data: any[] = [];

  private activeModal!: NgbModalRef | undefined;

  private doorsSubscription!: Subscription;
  private deleteSubscription!: Subscription;

  @ViewChild('openDoorModal') private openDoorModalRef!: ElementRef;
  @ViewChild(YesNoDialogComponent) public yesNoModal!: YesNoDialogComponent;

  constructor(private modal: NgbModal,
    private holotwinApi: PortalApiService,
    private authService: AuthService) {
  }

  public async openMe(): Promise<any> {
    this.activeModal = this.modal.open(this.openDoorModalRef, {  windowClass: 'modal-dialog modal-dialog-scrollable', size: 'xl', scrollable: true});

    this.holotwinApi.getDoors(1);
    this.handleApiResponses();

    return this.activeModal.result;
  }

  public onCloseModal(result: any): void {
    this.doorsSubscription?.unsubscribe();
    this.deleteSubscription?.unsubscribe();

    this.activeModal?.close(result);
    this.activeModal = undefined;
  }

  public onPreview(id: number): void {
    window.open(`/viewer?d=${id}`, '_blank');
  }

  public onEdit(id: number): void {
    this.onCloseModal(this.getDoorById(id));
  }

  public onToggleActive(id: number): void {
    const door = this.getDoorById(id);

    if (!door) {
      return;
    }

    // this.holotwinApi.toggleActiveDoor(id);
    door.active = !door.active;
  }

  public onDelete(id: number): void {
    const door = this.getDoorById(id);

    if (!door) {
      return;
    }

    this.yesNoModal.openMe('Delete door', `Do you want to delete the '${door.name}' door?`)
      .then((result) => {
        if (result === true) {
          this.holotwinApi.deleteDoor(id, this.authService.user.user_id);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }

  private handleApiResponses(): void {
    this.doorsSubscription = this.holotwinApi.getDoorsEvent.subscribe((res) => {
      if (res.error) {
        console.error(res);
        return;
      }

      this.data = res.data || [];
      console.log(this.data);
    });

    // this.deleteSubscription = this.holotwinApi.deleteDoorEvent.subscribe((res) => {
    //   this.holotwinApi.getDoors(true);
    // });
  }

  public getDoorById(doorId: number): any {
    for (const item of this.data) {
      if (item.id === doorId) {
        return item;
      }
    }

    return null;
  }
}
