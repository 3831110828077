import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountService } from '../services/account.service';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AccountResolver implements Resolve<any> {
  constructor(
    private accountService: AccountService,
    private routerService: Router,
    private authService: AuthService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (route.paramMap.get('id')) {
      return this.accountService.getAccount(route.paramMap.get('id')).pipe(
        map(
        (res: any) => {
          if (this.authService.accessLevel === 'system') {
            if (res.error) {
              if (res.error.code === 19) {
                this.authService.clearTokenNavigate('login');
              } else {
                this.routerService.navigate(['/404']);
              }
              return false;
            }
            this.accountService.account = res;
            return true;
          }
          if (this.authService.accessLevel === 'account') {
            if (this.authService.accessLevelId !== route.paramMap.get('id') && res.error && res.error !== 0) {
              if (res.error.code === 19) {
                this.authService.clearTokenNavigate('login');
              } else {
                this.routerService.navigate(['/404']);
              }
              return false;
            }
            this.accountService.account = res;
            return true;
          }
          this.routerService.navigate(['/404']);
          return false;
        }));
    }
    return null;
  }
}
