import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoCompleteModule } from 'primeng/autocomplete'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SystemFilterRoutingModule } from './systemFilter-routing.module';
import { SystemFilterComponent } from './systemFilter.component';

@NgModule({
  imports: [CommonModule, SystemFilterRoutingModule, AutoCompleteModule, FormsModule,
    ReactiveFormsModule],
  declarations: [SystemFilterComponent],
  exports: [SystemFilterComponent],
  providers: [SystemFilterComponent],
})
export class SystemFilterModule {
}
