import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BudgetsService {

  constructor(
    private httpService: HttpClient,
    private configService: ConfigService,
  ) {
  }

  public createPriceLevel(res) {
    return this.httpService.post(this.configService.config.createPriceLevel, res);
  }

  public getAllPriceLevels() {
    return this.httpService.get(this.configService.config.getAllPriceLevels);
  }

  public updatePriceLevel(res) {
    return this.httpService.put(this.configService.config.updatePriceLevel, res);
  }

  public deletePriceLevel(id) {
    return this.httpService.delete(this.configService.config.deletePriceLevel + id);
  }

  public createPayment(res) {
    return this.httpService.post(this.configService.config.createPayment, res);
  }

  public getAllPayments() {
    return this.httpService.get(this.configService.config.getAllPayments);
  }

  public updatePayment(res) {
    return this.httpService.put(this.configService.config.updatePayment, res);
  }

  public calculationPrice(res) {
    return this.httpService.post(this.configService.config.calculationPrice, res);
  }

  public calculationUpgradePrice(res) {
    return this.httpService.post(this.configService.config.calculationUpgradePrice, res);
  }

  public createPaymentIntents(res) {
    return this.httpService.post(this.configService.config.createPaymentIntents, res);
  }

  public checkPaymentStatus(res) {
    return this.httpService.post(this.configService.config.checkPaymentStatus, res);
  }

  public cancelTransactions(res) {
    return this.httpService.post(this.configService.config.cancelTransactions, res);
  }

  public deletePayment(id) {
    return this.httpService.delete(this.configService.config.deletePayment + id);
  }

  public getAllPurchases() {
    return this.httpService.get(this.configService.config.getAllPurchases);
  }

  public assignAmountToAccount(res) {
    return this.httpService.post(this.configService.config.assignAmountToAccount, res);
  }

  public assignAmountUpdateToAccount(res) {
    return this.httpService.put(this.configService.config.assignAmountUpdateToAccount, res);
  }

  public deleteAssignAmountFromAccount(id) {
    return this.httpService.delete(this.configService.config.assignAmountDeleteFromAccount + id);
  }

  public purchaseUpdate(res) {
    return this.httpService.put(this.configService.config.purchaseUpdate, res);
  }
}
