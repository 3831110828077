import * as THREE from 'three';
import {UntypedFormGroup} from '@angular/forms';

export class Utils {
  public static isValidExtension(fileName: string, extensions: string[]): boolean {
    const ext = fileName?.split('.')?.pop()?.toLowerCase();

    if (!ext) {
      return false;
    }

    return extensions.indexOf(ext) >= 0;
  }

  public static isValidControl(group: UntypedFormGroup, control: string): boolean {
    return group.get(control)?.valid || !group.get(control)?.dirty;
  }

  public static copyToClipboard(text: string): void {
    const input = document.body.appendChild(document.createElement('input'));
    input.value = text;
    input.focus();
    input.select();
    document.execCommand('copy');
    input.parentNode?.removeChild(input);
  }

  public static isString(str: any): boolean {
    return Object.prototype.toString.call(str) === '[object String]';
  }

  public static getErrorMessage(msg: any): string {
    let result = msg;

    while (result && !Utils.isString(result)) {
      if (result.hasOwnProperty('error')) {
        result = result.error;
      } else {
        break;
      }
    }

    if (!Utils.isString(result)) {
      result = msg?.message ?? msg?.error?.message ?? msg?.statusText ?? msg?.error?.statusText ?? 'Unknown Error.-';
    }

    return result;
  }

  public static gcd(a: number, b: number): number {
    return (b === 0) ? a : this.gcd(b, a % b);
  }

  public static getAspectRatio(width: number, height: number): any {
    const ratio = this.gcd(width, height);

    return {width: width / ratio, height: height / ratio};
  }

  public static visibleHeightAtZDepth(depth: number, camera: THREE.PerspectiveCamera): number {
    // compensate for cameras not positioned at z=0
    const cameraOffset = camera.position.z;

    if (depth < cameraOffset) {
      depth -= cameraOffset;
    } else {
      depth += cameraOffset;
    }

    // vertical fov in radians
    const vFOV = camera.fov * Math.PI / 180;

    // Math.abs to ensure the result is always positive
    return 2 * Math.tan(vFOV / 2) * Math.abs(depth);
  }

  public static visibleWidthAtZDepth(depth: number, camera: THREE.PerspectiveCamera): number {
    const height = this.visibleHeightAtZDepth(depth, camera);
    return height * camera.aspect;
  }

  public static hexToRgb(hex: string): number[] {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ] : [0, 0, 0];
  }
}
