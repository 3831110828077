import { Injectable } from '@angular/core';

@Injectable()
export class FormatTime {
  public hr: any = null;
  public min: any = null;
  public sec: any = null;
  public miliSec: any = null;

  constructor() {
  }

  public formatSecondsAsTime(secs) {
    this.hr = Math.floor(secs / 3600);
    this.min = Math.floor((secs - (this.hr * 3600)) / 60);
    this.sec = Math.floor(secs - (this.hr * 3600) - (this.min * 60));
    let str = '' + secs.toFixed(3);
    const zeroPos = str.indexOf('.');
    if (zeroPos === -1) {
      return 0;
    }
    str = str.slice(zeroPos + 1, zeroPos + 4);
    this.miliSec = str;
    if (this.miliSec.length === 1) {
      this.miliSec = '00' + this.miliSec;
    } else if (this.miliSec.length === 2) {
      this.miliSec = '0' + this.miliSec;
    }
    if (this.hr < 10) {
      this.hr = '0' + this.hr;
    }
    if (this.min < 10) {
      this.min = '0' + this.min;
    }
    if (this.sec < 10) {
      this.sec = '0' + this.sec;
    }
    if (this.hr) {
      this.hr = '00';
    }
    return this.min + ':' + this.sec + ':' + this.miliSec;
  }
}
