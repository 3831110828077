import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {PortalSendData} from '../interfaces/PortalSendData';
import { ConfigService } from './config.service';
import {Utils} from '../utils/Utils';
import { AnyRecordWithTtl } from 'dns';

@Injectable({
  providedIn: 'root'
})
export class PortalApiService {
  @Output() getPortalsEvent: EventEmitter<any> = new EventEmitter();
  @Output() postPortalEvent: EventEmitter<any> = new EventEmitter();
  @Output() postPortalProgressEvent: EventEmitter<any> = new EventEmitter();
  @Output() putPortalEvent: EventEmitter<any> = new EventEmitter();
  @Output() putPortalProgressEvent: EventEmitter<any> = new EventEmitter();
  @Output() deletePortalEvent: EventEmitter<any> = new EventEmitter();

  @Output() getDoorsEvent: EventEmitter<any> = new EventEmitter();
  @Output() postDoorEvent: EventEmitter<any> = new EventEmitter();
  @Output() putDoorEvent: EventEmitter<any> = new EventEmitter();
  @Output() deleteDoorEvent: EventEmitter<any> = new EventEmitter();
  @Output() getSplashEvent: EventEmitter<any> = new EventEmitter();
  @Output() postSplashEvent: EventEmitter<any> = new EventEmitter();
  @Output() getReticleEvent: EventEmitter<any> = new EventEmitter();
  @Output() putSplashEvent: EventEmitter<any> = new EventEmitter();
  @Output() deleteSplashEvent: EventEmitter<any> = new EventEmitter();
  @Output() toggleActiveDoorEvent: EventEmitter<any> = new EventEmitter();
  @Output() updateStatusEvent: EventEmitter<any> = new EventEmitter();

  @Output() getUserLicensesEvent: EventEmitter<any> = new EventEmitter();
  @Output() getLicensesAndUsersEvent: EventEmitter<any> = new EventEmitter();
  @Output() postLicenseEvent: EventEmitter<any> = new EventEmitter();
  @Output() deleteLicenseEvent: EventEmitter<any> = new EventEmitter();

  @Output() convertVideoEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient,
    private configService: ConfigService,) {
  }

  public convertVideo(s3Url): void {
    const sendData = new FormData();
    sendData.append('s3Url', s3Url);
    const url = this.configService.config.convertVideo
    this.http.post<any>(`${url}`, sendData, {reportProgress: true, observe: 'events'}).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.convertVideoEvent.emit({progress: event?.loaded / (event?.total ?? 1)});
        } else if (event.type === HttpEventType.Response) {
          this.convertVideoEvent.emit(event.body);
        }
      },
      (error) => {
        console.error(error);
        this.convertVideoEvent.emit({error});
      }
    );
  }

  // region Portal
  public getPortals(accountId): void {
    // TODO: Set userId
    const userId = 1;
    const url = this.configService.config.getPortals;
    this.http.get<any>(`${url}/${accountId}`).subscribe(
      (result) => {
        this.getPortalsEvent.emit(result);
      },
      (error) => {
        console.error(error);
        this.getPortalsEvent.emit({error});
      }
    );
  }

  public postPortal( userId, instanceId, name, accountId, statusId, splashId, permissionId, reticleId, doorId, autoEnter, loop, buttonId, videoUrl, shortUrl, title, description, image, buttons, buttonTypeId, autoEnterDelay, useLocation): void {
    const sendData = new FormData();
    sendData.append('userId', userId);
    sendData.append('name', name);
    sendData.append('instanceId', instanceId);
    sendData.append('accountId', accountId);
    sendData.append('statusId', statusId);
    sendData.append('splashId', splashId);
    sendData.append('permissionId', permissionId);
    sendData.append('reticleId', reticleId);
    sendData.append('doorId', doorId);
    sendData.append('autoEnter', autoEnter);
    sendData.append('loop', loop);
    sendData.append('buttonId', buttonId);
    sendData.append('videoUrl', videoUrl);
    sendData.append('shortUrl', shortUrl);
    sendData.append('title', title);
    sendData.append('description', description);
    sendData.append('image', image);
    sendData.append('buttonTypeId', buttonTypeId);
    sendData.append('autoEnterDelay', autoEnterDelay)
    sendData.append('useLocation', useLocation)
    sendData.append('buttons', JSON.stringify(buttons));




    const url = this.configService.config.postPortal;

    this.http.post<any>(`${url}`, sendData, {reportProgress: true, observe: 'events'}).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.postPortalProgressEvent.emit({progress: event?.loaded / (event?.total ?? 1)});
        } else if (event.type === HttpEventType.Response) {
          this.postPortalEvent.emit(event.body);
        }
      },
      (error) => {
        console.error(error);
        this.postPortalEvent.emit({error});
      }
    );
  }

  public putPortal( userId, instanceId, portalId, name, accountId, statusId, splashId, permissionId, reticleId, doorId, autoEnter, loop, buttonId, videoUrl, videoColor, similarity, shortUrl, title, description, image, buttons, buttonTypeId, buttonConfigId, autoEnterDelay, useLocation): void {
    const sendData = new FormData();
    sendData.append('userId', userId)
    sendData.append('name', name);
    sendData.append('instanceId', instanceId);
    sendData.append('accountId', accountId);
    sendData.append('statusId', statusId);
    sendData.append('splashId', splashId);
    sendData.append('permissionId', permissionId);
    sendData.append('reticleId', reticleId);
    sendData.append('doorId', doorId);
    sendData.append('autoEnter', autoEnter);
    sendData.append('loop', loop);
    sendData.append('buttonId', buttonId);
    sendData.append('videoUrl', videoUrl);
    sendData.append('videoColor', videoColor);
    sendData.append('shortUrl', shortUrl);
    sendData.append('similarity', similarity.toString());
    sendData.append('title', title);
    sendData.append('description', description);
    sendData.append('image', image);
    sendData.append('buttonTypeId', buttonTypeId);
    sendData.append('buttonConfigId', buttonConfigId);
    sendData.append('autoEnterDelay', autoEnterDelay);
    sendData.append('useLocation', useLocation);
    sendData.append('buttons', JSON.stringify(buttons));

    const url = this.configService.config.putPortal;
    this.http.put<any>(`${url}/portal/${portalId}`, sendData, {reportProgress: true, observe: 'events'}).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.putPortalProgressEvent.emit({progress: event?.loaded / (event?.total ?? 1)});
        } else if (event.type === HttpEventType.Response) {
          this.putPortalEvent.emit(event.body);
        }
      },
      (error) => {
        console.error(error);
        this.putPortalEvent.emit({error});
      }
    );
  }

  public deletePortal(portalId: number): void {

    const url = this.configService.config.deletePortal;
    this.http.delete<any>(`${url}/${portalId}`).subscribe(
      (result) => {
        this.deletePortalEvent.emit(result);
      },
      (error) => {
        console.error(error);
        this.deletePortalEvent.emit({error});
      }
    );
  }


  public getReticles(accountId): void {

    const url = this.configService.config.getReticle;
    this.http.get<any>(`${url}/${accountId}`)

    .subscribe(
      (result) => {
        this.getReticleEvent.emit(result);
      },
      (error) => {
        console.error(error);
        this.getReticleEvent.emit({error});
      }
    );
  }

  private getSEOImage(seoImage: unknown): File | string {
    if (Utils.isString(seoImage) && (seoImage as string).length > 0) {
      return seoImage as string;
    }

    if (seoImage instanceof File) {
      return seoImage;
    }

    return `${location.protocol}//${location.host}/assets/default-seo-image.jpg?r=${Math.floor((1 + Math.random()) * 0x10000)}`;
  }

  private getFavicon(favicon: unknown): File | string {
    if (Utils.isString(favicon) && (favicon as string).length > 0) {
      return favicon as string;
    }

    if (favicon instanceof File) {
      return favicon;
    }

    return 'favicon.ico';
  }

  // endregion

  // region Door
  public getDoors(accountId): void {

    const url = this.configService.config.getDoors;
    this.http.get<any>(`${url}/${accountId}}`).subscribe(
      (result) => {
        this.getDoorsEvent.emit(result);
      },
      (error) => {
        console.error(error);
        this.getDoorsEvent.emit({error});
      }
    );
  }

  public postDoor(userId: any, instanceId: any, accountId: any, name: string, doorScale: number, doorPos: string, openAnim: string,
                  wallDimensions: string, modelFile: File, thumbFile: string, renderFile: File, ambientLight, directionalLights, useRealtime, environmentImage): void {
    const sendData = new FormData();
    sendData.append('userId', userId);
    sendData.append('name', name);
    sendData.append('instanceId', instanceId);
    sendData.append('accountId', accountId);
    sendData.append('doorScale', doorScale.toString());
    sendData.append('doorPos', doorPos);
    sendData.append('openAnim', openAnim);
    sendData.append('wallDimensions', wallDimensions);
    sendData.append('selectedModelFile', modelFile);
    sendData.append('selectedThumbFile', thumbFile);
    sendData.append('selectedRenderFile', renderFile);
    sendData.append('ambientLight', ambientLight);
    sendData.append('directionalLights', directionalLights);
    sendData.append('useRealtime', useRealtime);
    sendData.append('environmentImage', environmentImage);


    const url = this.configService.config.postDoor;

    this.http.post<any>(`${url}`, sendData).subscribe(
      (result) => {
        this.postDoorEvent.emit(result);
      },
      (error) => {
        console.error(error);
        this.postDoorEvent.emit({error});
      }
    );
  }

  public putDoor(userId, instanceId, accountId, doorId: any, name: string, doorScale: number, doorPos: string, openAnim: string,
                 wallDimensions: string, modelFile: File | null, thumbFile: string | null, renderFile: File | null, ambientLight, directionalLights, useRealtime, environmentImage, lightingId): void {
    const sendData = new FormData();
    sendData.append('name', name);
    sendData.append('userId', userId);
    sendData.append('instanceId', instanceId);
    sendData.append('accountId', accountId);
    sendData.append('doorScale', doorScale.toString());
    sendData.append('doorPos', doorPos);
    sendData.append('openAnim', openAnim);
    sendData.append('wallDimensions', wallDimensions);
    sendData.append('doorId', doorId);
    sendData.append('ambientLight', ambientLight);
    sendData.append('directionalLights', directionalLights);
    sendData.append('useRealtime', useRealtime);
    sendData.append('environmentImage', environmentImage);
    sendData.append('lightingId', lightingId);


    if (modelFile) {
      sendData.append('selectedModelFile', modelFile);
    }

    if (thumbFile) {
      sendData.append('selectedThumbFile', thumbFile);
    }

    if (renderFile) {
      sendData.append('selectedRenderFile', renderFile);
    }


    const url = this.configService.config.putDoor;

    this.http.put<any>(`${url}`, sendData).subscribe(
      (result) => {
        this.putDoorEvent.emit(result);
      },
      (error) => {
        console.error(error);
        this.putDoorEvent.emit({error});
      }
    );
  }

  public deleteDoor(doorId: number, userId:any): void {


    const url = this.configService.config.deleteDoor;
    this.http.delete<any>(`${url}/${doorId}/${userId}`).subscribe(
      (result) => {
        this.deleteDoorEvent.emit(result);
      },
      (error) => {
        console.error(error);
        this.deleteDoorEvent.emit({error});
      }
    );
  }

  public toggleActiveDoor(doorId: number): void {


    const url = this.configService.config.getPortals;

    this.http.post<any>(`${url}/door/${doorId}/toggle-active`, null).subscribe(
      (result) => {
        this.toggleActiveDoorEvent.emit(result);
      },
      (error) => {
        console.error(error);
        this.toggleActiveDoorEvent.emit({error});
      }
    );
  }


  public postSplash(userId: any, account: any, instance: any, name: string, prim: string, sec: string, useLogo1: boolean, useLogo2:boolean, d1: File, d2: File, m1: File,
    m2: File, logo1: File, logo2: File): void {
    const sendData = new FormData();
    sendData.append('userId', userId);
    sendData.append('accountId', account);
    sendData.append('instanceId', instance);
    sendData.append('name', name);
    sendData.append('d1', d1);
    sendData.append('d2', d2);
    sendData.append('m1', m1);
    sendData.append('m2', m2);
    sendData.append('logo1', logo1);
    sendData.append('logo2', logo2);
    sendData.append('prim', prim);
    sendData.append('sec', sec);
    sendData.append('useLogo1', useLogo1 ? '1' : '0');
    sendData.append('useLogo2', useLogo2 ? '1' : '0');



    const url = this.configService.config.postSplash;
    this.http.post<any>(url, sendData)
    .subscribe(
    (result) => {
    this.postSplashEvent.emit(result);
    },
    (error) => {
    console.error(error);
    this.postSplashEvent.emit({error});
    }
    );
    }
  // endregion

  public putSplash(userId: any,account: any, instance: any, doorId: number, name: string, prim: string, sec: string, useLogo1: boolean | null, useLogo2: boolean, d1: any, d2: any, m1: any,
    m2: any, logo1: any, logo2: any): void {
      const sendData = new FormData();
      sendData.append('userId', userId);
      sendData.append('accountId', account);
      sendData.append('instanceId', instance);
      sendData.append('doorId', doorId.toString());
      sendData.append('name', name);
      sendData.append('d1', d1);
      sendData.append('d2', d2);
      sendData.append('m1', m1);
      sendData.append('m2', m2);
      sendData.append('logo1', logo1);
      sendData.append('logo2', logo2);
      sendData.append('prim', prim);
      sendData.append('sec', sec);
      sendData.append('useLogo1', useLogo1 ? '1' : '0');
      sendData.append('useLogo2', useLogo2 ? '1' : '0');


    const url = this.configService.config.putSplash;
    this.http.put<any>(url, sendData)

    .subscribe(
    (result) => {
    this.putSplashEvent.emit(result);
    },
    (error) => {
    console.error(error);
    this.putSplashEvent.emit({error});
    }
    );
    }

  public getSplashes(accountId): void {

    const url = this.configService.config.getSplashes;
    this.http.get<any>(`${url}/${accountId}`)

    .subscribe(
      (result) => {
        this.getSplashEvent.emit(result);
      },
      (error) => {
        console.error(error);
        this.getSplashEvent.emit({error});
      }
    );
  }

  public deleteSplash(doorId: number, userId?: any): void {

    const url = this.configService.config.deleteSplash;
    this.http.delete<any>(`${url}/${doorId}/user/${userId}`)

    .subscribe(
      (result) => {
        this.deleteSplashEvent.emit(result);
      },
      (error) => {
        console.error(error);
        this.deleteSplashEvent.emit({error});
      }
    );
  }

  public updateStatus(id, newStatus): void {
    const sendData = new FormData();
    sendData.append('id', id)
    sendData.append('newStatus', newStatus)
    const url = this.configService.config.updatePortalStatus

    this.http.put<any>(url, sendData).subscribe(
      (result) => {
      this.updateStatusEvent.emit(result);
      },
      (error) => {
      console.error(error);
      this.updateStatusEvent.emit({error});
      }
      );
  }


  // region License
  public getUserLicenses(): void {
    // TODO: Set userId
    const userId = 1;

    const url = this.configService.config.deleteSplash;
    this.http.get<any>(`${url}/user/${userId}/licenses`).subscribe(
      (result) => {
        this.getUserLicensesEvent.emit(result);
      },
      (error) => {
        console.error(error);
        this.getUserLicensesEvent.emit({error});
      }
    );
  }

  public getLicensesAndUsers(): void {

    const url = this.configService.config.deleteSplash;
    this.http.get<any>(`${url}/licenses/users`).subscribe(
      (result) => {
        this.getLicensesAndUsersEvent.emit(result);
      },
      (error) => {
        console.error(error);
        this.getLicensesAndUsersEvent.emit({error});
      }
    );
  }

  public postLicense(name: string, license: string, userId: number): void {
    const sendData = new FormData();
    sendData.append('name', name);
    sendData.append('license', license);
    sendData.append('userId', userId.toString());

    const url = this.configService.config.deleteSplash;

    this.http.post<any>(`${url}/license`, sendData).subscribe(
      (result) => {
        this.postLicenseEvent.emit(result);
      },
      (error) => {
        console.error(error);
        this.postLicenseEvent.emit({error});
      }
    );
  }

  public deleteLicense(licenseId: number): void {

    const url = this.configService.config.deleteSplash;
    this.http.delete<any>(`${url}/license/${licenseId}`).subscribe(
      (result) => {
        this.deleteLicenseEvent.emit(result);
      },
      (error) => {
        console.error(error);
        this.deleteLicenseEvent.emit({error});
      }
    );
  }

  // endregion
}
