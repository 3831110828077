import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarRoutingModule } from './sidebar-routing.module';
import { SidebarComponent } from './sidebar.component';
import { LoaderModule } from '../loader/loader.module';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  imports: [CommonModule, SidebarRoutingModule, LoaderModule, OverlayModule],
  declarations: [SidebarComponent],
  exports: [SidebarComponent],
})
export class SidebarModule {
}
