import {Component, ElementRef, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';

@Component({
  selector: 'app-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.css']
})
export class YesNoDialogComponent {
  public title = '';
  public message = '';

  private activeModal!: NgbModalRef | undefined;

  @ViewChild('yesNoModal') private yesNoModalRef!: ElementRef;

  constructor(private modal: NgbModal) {
  }

  public async openMe(title: string, message: string): Promise<any> {
    this.title = title;
    this.message = message;

    this.activeModal = this.modal.open(this.yesNoModalRef);

    return this.activeModal.result;
  }

  public onCloseModal(result: boolean): void {
    this.activeModal?.close(result);
    this.activeModal = undefined;
  }

  public onYes(): void {
    this.onCloseModal(true);
  }

  public onNo(): void {
    this.onCloseModal(false);
  }
}
