import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ConfigService } from './config.service';
import { AuthService } from './auth.service';
import { WorkflowService } from './workflow.service';

@Injectable()
export class ExperienceService {
  public experience: any = null;
  public experiencesByCampaign: any = null;
  public builderExperience$: Observable<any> = null;
  private builderExperienceSource = new Subject<any>();
  public builderExperienceJson$: Observable<any> = null;
  private builderExperienceJsonSource = new Subject<any>();
  public experiencesByCampaign$: Observable<any> = null;
  private experiencesByCampaignSource = new Subject<any>();
  public experienceForBuilder: any = null;
  public experienceForBuilderJson: any = null;
  public recognitionTypeQuery: HttpParams | null = null;

  constructor(
    private httpService: HttpClient,
    private configService: ConfigService,
    private authService: AuthService,
    private workflow: WorkflowService,
  ) {
    this.experiencesByCampaign$ = this.experiencesByCampaignSource.asObservable();
    this.builderExperience$ = this.builderExperienceSource.asObservable();
    this.builderExperienceJson$ = this.builderExperienceJsonSource.asObservable();
  }

  public pushExperienceToBuilder(experience) {
    const url = this.configService.config.getExperience;
    this.recognitionTypeQuery = new HttpParams().set('recognitionType', this.workflow.getWorkflow());
    this.httpService.get(url + experience.id + '/' + this.authService.token, { params: this.recognitionTypeQuery }).subscribe(
      (res: any) => {
        this.experienceForBuilder = res.data;
        if (!res.data.scenes.experience) {
          this.httpService.get(res.data.json_file).subscribe(
            (resJson: any) => {
              this.experienceForBuilderJson = resJson;
              this.builderExperienceSource.next(res.data);
              this.builderExperienceJsonSource.next(resJson);
            });
        } else {
          this.experienceForBuilderJson = res.data.scenes;
          this.builderExperienceSource.next(res.data);
          this.builderExperienceJsonSource.next(res.data.scenes);
        }
      });
  }

  public getExperiencesByCampaignId(id) {
    const url = this.configService.config.getExperiencesByCampaign;
    this.httpService.get(url + id + '/' + this.authService.token).subscribe(
      (res: any) => {
        if (res.message && res.message === 'success') {
          this.experiencesByCampaign = res.data;
        }
        this.experiencesByCampaignSource.next(res);
      });
  }

  public addExperienceToCampaign(experiences, campaignId) {
    const url = this.configService.config.addExperienceToCampaign;
    return this.httpService.put(url, { campaignId, experiences });
}

  public getExperience(id) {
    const url = this.configService.config.getExperience;
    this.recognitionTypeQuery = new HttpParams().set('recognitionType', this.workflow.getWorkflow());
    return this.httpService.get(url + id + '/' + this.authService.token, { params: this.recognitionTypeQuery });
  }

  public cloneExperience(form) {
    const url = this.configService.config.cloneExperience;
    return this.httpService.post(url, form);
  }

  public updateExperience(experience) {
    const url = this.configService.config.updateExperience;
    return this.httpService.put(url, { experience });
  }

  public getUniqueMarkerId(campaignId, markerId) {
    const url = this.configService.config.uniqueMarkerId;
    return this.httpService.get(url + campaignId + '/' + markerId + '/' + this.authService.token);
  }

  public deleteExperienceFromCampaign(id, campaignId) {
    const url = this.configService.config.deleteExperienceFromCampaign;
    const params = new HttpParams().set('campaign_id', campaignId);
    return this.httpService.delete(url + id + '/' + this.authService.token, { params });
  }

  public deleteExperience(id) {
    const url = this.configService.config.deleteExperience;
    return this.httpService.delete(url + id + '/' + this.authService.token);
  }
}
