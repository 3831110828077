import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { InstancesService } from '../../services/instances.service';
import { AccountsService } from '../../services/accounts.service';
import { CampaignsService } from '../../services/campaigns.service';
import { AuthService } from '../../services/auth.service';
import { SystemFiltersService } from '../../services/systemFilters.service';
import { InstanceService } from '../../services/instance.service';
import { SubscriptionsPool } from '../../helpers/subscriptions-pool';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'systemFilter',
  styleUrls: ['systemFilter.component.scss'],
  templateUrl: 'systemFilter.component.html',
})

export class SystemFilterComponent extends SubscriptionsPool implements OnInit, OnDestroy {
  public user: any = null;
  private instance: object = {};
  private account: object = {};
  public campaign: object = {};
  private instancesList: any = [];
  private accountsList: any = [];
  private campaignsList: any = [];
  private filteredInstances: any = [];
  private filteredAccounts: any = [];
  public filteredCampaigns: any = [];
  private accountsListFiltered: any = [];
  private campaignsListFiltered: any = [];
  private noAccessRole: any = ['Trial User', 'Instance Admin', 'Account Admin', 'Campaign Admin', 'Experience Admin', 'Editor Publisher'];
  private systemFilter: object = {instance: -1, account: -1, campaign: -1}; // using to filter entities and to set values
  public dropdownsAvailability: object = {instance: true, account: false, campaign: false};
  public dropdownsErrors: object = {instance: false, account: false, campaign: false};
  private urlsForValidity: object = {
    instance: {invalid: []},
    account: {invalid: ['/home/instances']},
    campaign: {valid: ['/home/campaigns']},
  };
  @ViewChild('instancesDropdown') instancesDropdown: ElementRef;
  @ViewChild('accountsDropdown') accountsDropdown: ElementRef;
  @ViewChild('campaignsDropdown') campaignsDropdown: ElementRef;

  constructor(
    private instancesService: InstancesService,
    private accountsService: AccountsService,
    private accountService: AccountService,
    private campaignsService: CampaignsService,
    private authService: AuthService,
    private systemFiltersService: SystemFiltersService,
    private routerService: Router,
    private instanceService: InstanceService,
  ) {
    super();
  }

  public ngOnInit() {
    this.user = this.authService.user;
    this.setParams();

    this.systemFiltersService.setSystemFilter(this.systemFilter);
    this.addSubscription(
      this.authService.user$.subscribe(
        (res: any) => {
          this.user = res;
        })
    );

    this.addSubscription(
      this.routerService.events.subscribe(
        (res: any) => {
          if (res instanceof NavigationEnd) {
            this.checkDropdownsAvailability();
            this.filterInstances();
            this.filterAccounts();
            this.filterCampaigns();
            this.checkDropdownsErrors();
          }
        })
    );

    this.addSubscription(
      this.systemFiltersService.systemFilter$.subscribe(
        (res: any) => {
          this.systemFilter = res;
          this.checkDropdownsAvailability();
          this.filterInstances();
          this.filterAccounts();
          this.filterCampaigns();
          this.checkDropdownsErrors();
        })
    );

    this.initInstances();
    this.addSubscriptions(
      this.instanceService.instanceUpdated$.subscribe(() => {
        this.initInstances();
      }),
      this.accountService.accountUpdated$.subscribe(() => {
        this.initInstances();
      })
    );

    this.campaignsService.getAllCampaigns().subscribe();
    this.addSubscription(
      this.campaignsService.campaigns$.subscribe(
        () => {
          this.campaignsList = [...this.campaignsService.campaignsList];
          if (this.systemFilter['account'] !== -1) {
            this.checkDropdownsAvailability();
            this.filterCampaigns();
          } else {
            this.campaignsListFiltered = this.campaignsList;
          }
        })
    );
  }

  public initInstances(): void {
    this.instancesService.getInstances().subscribe(
      (res: any) => {
        if (res.message && res.message === 'success') {
          this.instancesList = [...this.instancesService.instancesList];
          this.instancesList.unshift({label: 'All instances', value: -1});
          // this.instancesList = this.instancesList.filter(res => res.state !== 'Inactive');
          this.accountsList = [...this.accountsService.accountsList];
          // this.accountsList = this.accountsList.filter(res => res.state !== 'Inactive');

          if (this.systemFilter['instance'] !== -1) {
            this.instance = this.instancesList.find(instance => instance.value === this.systemFilter['instance']);
            if (this.noAccessRole.includes(this.user.role) && !localStorage.getItem('selectedInstance') && this.instance === undefined) {
              this.systemFilter['instance'] = -1;
              this.account = {label: 'All instances', value: -1};
            } else if (!this.noAccessRole.includes(this.user.role) && this.instance === undefined) {
              localStorage.removeItem('selectedInstance');
              this.systemFilter['instance'] = -1;
              this.account = {label: 'All instances', value: -1};
            }
            this.checkDropdownsAvailability();
            this.filterAccounts();
            this.checkDropdownsErrors();
          } else {
            this.accountsListFiltered = this.accountsList;
          }
        }
      }
    );
  }

  public changeCampaignsFilterValues(experience) {
    this.systemFilter['instance'] = parseInt(localStorage.getItem('selectedInstance'), 10);
    this.systemFilter['account'] = parseInt(localStorage.getItem('selectedAccount'), 10);

    if (experience.campaign_id !== null) {
      const campaign = {value: experience.campaign_id};
      this.campaignsFilterChange(campaign);
      this.dropdownsAvailability['campaign'] = false;
      this.systemFilter['campaign'] = experience.campaign_id;
      this.systemFiltersService.setSystemFilter(this.systemFilter);
    } else {
      this.systemFilter['campaign'] = -1;
      this.systemFiltersService.setSystemFilter(this.systemFilter);
      this.filterCampaigns();
    }
  }

  private setParams() {
    this.systemFilter['instance'] = parseInt(localStorage.getItem('selectedInstance'), 10) || this.user['instance_id'] || this.user['parent_instance_id'] || -1;
    this.systemFilter['account'] = parseInt(localStorage.getItem('selectedAccount'), 10) || this.user['account_id'] || -1;
    this.systemFilter['campaign'] = parseInt(localStorage.getItem('selectedCampaign'), 10) || -1;
  }

  private checkDropdownsAvailability() {
    this.dropdownsAvailability = {
      instance: true,
      account: this.systemFilter['instance'] !== -1 && !this.urlsForValidity['account']['invalid'].includes(this.routerService.url),
      campaign: this.systemFilter['account'] !== -1 && this.urlsForValidity['campaign']['valid'].includes(this.routerService.url),
    };
  }

  private checkDropdownsErrors() {
    this.dropdownsErrors = {
      instance: false,
      campaign: false,
      account: this.routerService.url.indexOf('/home/media') > -1 && this.systemFilter['account'] === -1,
    };
  }

  private instancesFilterChange(instance) {
    this.systemFilter['instance'] = instance.value;
    this.systemFilter['account'] = -1;
    this.systemFilter['campaign'] = -1;
    this.account = {label: 'All accounts', value: -1};
    this.checkDropdownsAvailability();
    this.filterAccounts();
    this.systemFiltersService.setSystemFilter(this.systemFilter);
    this.checkDropdownsErrors();
  }

  private accountsFilterChange(account) {
    this.systemFilter['account'] = account.value;
    this.systemFilter['campaign'] = -1;
    this.checkDropdownsAvailability();
    this.filterCampaigns();
    this.systemFiltersService.setSystemFilter(this.systemFilter);
    this.checkDropdownsErrors();
  }

  public campaignsFilterChange(campaign) {
    this.systemFilter['campaign'] = campaign.value;
    this.systemFiltersService.setSystemFilter(this.systemFilter);
  }

  private filterInstances() {
    this.instance = this.instancesList.find(instance => instance.value === this.systemFilter['instance']);
    if (!this.dropdownsAvailability['instance'] && this.systemFilter['instance'] === -1) {
      this.instance = [{label: 'Not available', value: -1}];
      return;
    }
  }

  private filterAccounts() {
    if (!this.dropdownsAvailability['account']) {
      this.accountsListFiltered = [{label: 'Not available', value: -1}];
      this.account = [{label: 'Not available', value: -1}];
      this.systemFilter['account'] = -1;
      localStorage.removeItem('selectedAccount');
      return;
    }
    this.accountsListFiltered = [{label: 'All accounts', value: -1}];
    if (this.dropdownsAvailability['account'] && this.systemFilter['account'] === -1) {
      this.account = {label: 'All accounts', value: -1};
    } else {
      this.account = this.accountsList.find(i => i.value === this.systemFilter['account']);
      if (this.noAccessRole.includes(this.user.role) && !localStorage.getItem('selectedAccount') && this.account === undefined) {
        this.systemFilter['account'] = -1;
        if (!this.dropdownsAvailability['account'] && this.systemFilter['account'] === -1) {
          this.accountsListFiltered = [{label: 'Not available', value: -1}];
          this.account = [{label: 'Not available', value: -1}];
          return;
        }
        this.account = {label: 'All accounts', value: -1};
      } else if (!this.noAccessRole.includes(this.user.role) && this.account === undefined) {
        localStorage.removeItem('selectedAccount');
        this.systemFilter['account'] = -1;
        if (!this.dropdownsAvailability['account'] && this.systemFilter['account'] === -1) {
          this.accountsListFiltered = [{label: 'Not available', value: -1}];
          this.account = [{label: 'Not available', value: -1}];
          return;
        }
        this.account = {label: 'All accounts', value: -1};
      }
    }
    if (this.systemFilter['instance'] && this.systemFilter['instance'] !== -1) {
      this.accountsListFiltered = this.accountsListFiltered.concat(this.accountsList.filter(
        (item) => {
          return item.instanceId === this.systemFilter['instance'];
        }));
      if (this.accountsListFiltered.length === 1) {
        this.accountsListFiltered = [{label: 'There are no accounts', value: -1}];
        this.account = {label: 'There are no accounts', value: -1};
        this.dropdownsAvailability['account'] = false;
      }
    }
  }

  private filterCampaigns() {
    if (!this.dropdownsAvailability['campaign']) {
      this.campaignsListFiltered = [{label: 'Not available', value: -1}];
      this.campaign = {label: 'Not available', value: -1};
      this.systemFilter['campaign'] = -1;
      localStorage.removeItem('selectedCampaign');
      return;
    }
    this.campaignsListFiltered = [{label: 'All campaigns', value: -1}];
    this.campaign = {label: 'All campaigns', value: -1};
    if (this.systemFilter['account'] && this.systemFilter['account'] !== -1) {
      this.campaignsListFiltered = this.campaignsListFiltered.concat(this.campaignsList.filter((item) => {
        return item.accountId === this.systemFilter['account'];
      }));
      if (this.systemFilter['campaign'] !== -1) {
        this.campaign = this.campaignsList.find(i => i.value === this.systemFilter['campaign']);
        if (this.noAccessRole.includes(this.user.role) && !localStorage.getItem('selectedCampaign') && this.campaign === undefined) {
          this.systemFilter['campaign'] = -1;
          if (!this.dropdownsAvailability['campaign']) {
            this.campaignsListFiltered = [{label: 'Not available', value: -1}];
            this.campaign = [{label: 'Not available', value: -1}];
            return;
          }
          this.campaign = {label: 'All campaigns', value: -1};
        } else if (!this.noAccessRole.includes(this.user.role) && this.campaign === undefined) {
          localStorage.removeItem('selectedCampaign');
          this.systemFilter['campaign'] = -1;
          if (!this.dropdownsAvailability['campaign'] && this.systemFilter['campaign'] === -1) {
            this.campaignsListFiltered = [{label: 'Not available', value: -1}];
            this.campaign = [{label: 'Not available', value: -1}];
            return;
          }
          this.campaign = {label: 'All campaigns', value: -1};
        }
      }
      if (this.campaignsListFiltered.length === 1) {
        this.campaignsListFiltered = [{label: 'There are no campaigns', value: -1}];
        this.campaign = {label: 'There are no campaigns', value: -1};
        this.dropdownsAvailability['campaign'] = false;
      }
    }
  }

  private autoCompleteInstances(event) {
    this.filteredInstances = [];
    this.instancesList.forEach((instance) => {
      if (instance.label.toLowerCase().indexOf(event.query.toLowerCase()) > -1) {
        this.filteredInstances.push(instance);
      }
    });
  }

  private autoCompleteAccounts(event) {
    this.filteredAccounts = [];
    this.accountsListFiltered.forEach((res: any) => {
      if (res.label.toLowerCase().indexOf(event.query.toLowerCase()) > -1) {
        this.filteredAccounts.push(res);
      }
    });
  }

  public autoCompleteCampaigns(event) {
    this.filteredCampaigns = [];
    this.campaignsListFiltered.forEach((res: any) => {
      if (res.label.toLowerCase().indexOf(event.query.toLowerCase()) > -1) {
        this.filteredCampaigns.push(res);
      }
    });
  }

  // public onDropdownClick(dropDown) {
  //   const elClass = this[dropDown]['el'].nativeElement.children[0].children[1].children[0];
  //   if (elClass.classList.contains('fa-caret-down')) {
  //     this[dropDown]['el'].nativeElement.children[0].children[2].style = 'display: block; width: 100%; max-height: 500px; z-index: 1001; opacity: 1.075; top: 36px; left: 0px;';
  //     elClass.classList.remove('fa-caret-down');
  //     elClass.classList.add('fa-caret-up');
  //     this.hideIconOtherDropDown(dropDown);
  //   } else if (elClass.classList.contains('fa-caret-up')) {
  //     this[dropDown]['el'].nativeElement.children[0].children[2].style = 'display: none; width: 100%; max-height: 500px;';
  //     elClass.classList.remove('fa-caret-up');
  //     elClass.classList.add('fa-caret-down');
  //   }
  // }

  public onBlurDropDown(dropDown, event) {
    if (event.relatedTarget === null) {
      this.addCaretDownIcon(dropDown);
    } else if (event.relatedTarget !== null) {
      if (event.relatedTarget.parentElement !== undefined && event.relatedTarget.parentElement.parentElement !== undefined) {
        if (event.relatedTarget.parentElement.parentElement.attributes.acctest === undefined ||
          event.relatedTarget.parentElement.parentElement.attributes.acctest.value !== dropDown) {
          this.addCaretDownIcon(dropDown);
        }
      } else {
        this.addCaretDownIcon(dropDown);
      }
    }
  }

  private addCaretDownIcon(res) {
    const elClass = this[res]['el'].nativeElement.children[0].children[1].children[0];
    (elClass as HTMLElement).classList.remove('fa-caret-up');
    (elClass as HTMLElement).classList.add('fa-caret-down');
  }

  private hideIconOtherDropDown(res) {
    let elInstance = null;
    let elAccount = null;
    let elCampaign = null;
    if (this.instancesDropdown !== undefined) {
      elInstance = this.instancesDropdown['el'].nativeElement.children[0].children[1].children[0];
    }
    if (this.accountsDropdown !== undefined) {
      elAccount = this.accountsDropdown['el'].nativeElement.children[0].children[1].children[0];
    }
    if (this.campaignsDropdown !== undefined) {
      elCampaign = this.campaignsDropdown['el'].nativeElement.children[0].children[1].children[0];
    }
    switch (res) {
      case 'instancesDropdown':
        if (elAccount !== null) {
          elAccount.classList.remove('fa-caret-up');
          elAccount.classList.add('fa-caret-down');
        }
        if (elCampaign !== null) {
          elCampaign.classList.remove('fa-caret-up');
          elCampaign.classList.add('fa-caret-down');
        }
        break;
      case 'accountsDropdown':
        if (elInstance !== null) {
          elInstance.classList.remove('fa-caret-up');
          elInstance.classList.add('fa-caret-down');
        }
        if (elCampaign !== null) {
          elCampaign.classList.remove('fa-caret-up');
          elCampaign.classList.add('fa-caret-down');
        }
        break;
      case 'campaignsDropdown':
        if (elInstance !== null) {
          elInstance.classList.remove('fa-caret-up');
          elInstance.classList.add('fa-caret-down');
        }
        if (elAccount !== null) {
          elAccount.classList.remove('fa-caret-up');
          elAccount.classList.add('fa-caret-down');
        }
        break;
    }
  }

  public ngOnDestroy() {
    this.unsubscribePool();
  }
}
