import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { Error500Module } from '../error500/error500.module';
import { SidebarModule } from '../sidebar/sidebar.module';
import { SystemFilterModule } from '../systemFilter/systemFilter.module';
// import { ProofHelperModule } from '../proofHelper/proofHelper.module';
import { LoaderModule } from '../loader/loader.module';

import { ToastModule } from 'primeng/toast'
import { DialogModule } from 'primeng/dialog'
import { TooltipModule } from 'primeng/tooltip'
import { CheckboxModule } from 'primeng/checkbox'

import { WebArQrCodeModule } from '../web-ar-qr-code/webArQrCode.module';

@NgModule({
  imports: [
    CommonModule,
    HomeRoutingModule,
    Error500Module,
    SidebarModule,
    ToastModule,
    SystemFilterModule,
    LoaderModule,
    // ProofHelperModule,
    DialogModule,
    CheckboxModule,
    FormsModule,
    
    TooltipModule,
    WebArQrCodeModule,
  ],
  declarations: [HomeComponent],
  exports: [HomeComponent],
})

export class HomeModule {
}
