import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { AccountsService } from '../../services/accounts.service';
import { InstancesService } from '../../services/instances.service';
import { CampaignsService } from '../../services/campaigns.service';
import { UserService } from '../../services/user.service';
import { InstanceService } from '../../services/instance.service';
import { AccountService } from '../../services/account.service';
import { ExperienceService } from '../../services/experience.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import {ConfirmationService, MessageService} from "primeng/api";
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SupportComponent } from '../support/support.component';
import html2canvas from 'html2canvas';
import { setTokenSourceMapRange } from 'typescript';
@Component({
  selector: 'sidebar',
  styleUrls: ['sidebar.component.scss'],
  templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit, OnDestroy {
  public user: any = null;
  public isSidebarCollapsed: boolean = false;
  private authSubscription: Subscription = null;
  public analyticsConstructionShow = false;
  public currentRoute
  public instance
  public account
  public wlAccess = false
  public overlayRef: OverlayRef;
  loading: any;

  constructor(
    private authService: AuthService,
    private accountsService: AccountsService,
    private instancesService: InstancesService,
    private campaignsService: CampaignsService,
    private confirmationService: ConfirmationService,
    private instanceService: InstanceService,
    private accountService: AccountService,
    private userService: UserService,
    private experienceService: ExperienceService,
    private router: Router,
    private messageService: MessageService,
    private overlay: Overlay,
  ) {
  }

  public ngOnInit() {
    this.user = this.authService.user;
    if (this.user.role_level == 2) {
    this.instanceService.getInstance(this.user.instance_id).subscribe(
      (res: any) => {
        this.instance = res.data
        this.wlAccess = this.instance.instance_wl_permission === 1 ? true : false
        }
    )} else if (this.user.role_level == 3) {
      this.accountService.getAccount(this.user.account_id).subscribe(
        (res: any) => {
          this.account = res.data
          this.wlAccess = this.account.account_white_label_permission === 1 ? true : false
          }
      )} else if (this.user.role_level == 1) {
      this.wlAccess = true
      }


    this.authSubscription = this.authService.user$.subscribe(
      (res: any) => {
        this.user = res;
      });
  }

  public logout() {
    this.accountsService.accounts = [];
    this.accountsService.accountsList = [];
    this.instancesService.instances = [];
    this.instancesService.instancesList = [];
    this.campaignsService.campaignsList = [];
    this.userService.user = null;
    this.accountService.account = null;
    this.accountService.campaignsCountAccount = null;
    this.instanceService.instance = null;
    this.experienceService.experience = null;
    this.experienceService.experiencesByCampaign = null;
    this.authService.clearTokenNavigate('login');
  }

  private leaveImpersonation() {
    const superUser = this.userService.superUser;
    this.loading = true;
    this.userService.impersonateToUser(this.userService.superUser.user_id, superUser.user_id).subscribe(
      (res: any) => {
        if (res.message && res.message === 'success') {
          AuthService.setUserToken(res.data.user, res.data.user.token);
          res.data.user.isImpersonated = false
          this.authService.useJwtHelper(res.data.user);
          this.router.navigate(["/home/users"]);
          this.loading = false;

        } else {
          console.log('error')
        }
      });
  }

  public async getScreenshot() {
    const screenshot = await this.captureScreenshot();
    return screenshot;
  }

  public async openSupport() {
    this.messageService.add({severity:'success', summary:'Opening Support', detail: 'Preparing support window.' });
    setTimeout(async () => {
        const screenshot = await this.getScreenshot();
        this.loading = true;
        const overlayConfig = {
          hasBackdrop: true,
          width: '50%',
          height: '50%',
          panelClass: 'support-overlay',
        };

        this.overlayRef = this.overlay.create(overlayConfig);
        const supportPortal = new ComponentPortal(SupportComponent);
        const supportComponent = this.overlayRef.attach(supportPortal);
        supportComponent.instance.screenshotData = screenshot;
        supportComponent.instance.requestLocation = this.router.url;
        this.loading = false;

        supportComponent.instance.closeSupportEvent.subscribe(() => {
          if (this.overlayRef) {
            this.overlayRef.detach();
          }
        });

        this.overlayRef.backdropClick().subscribe(() => {
          this.overlayRef.detach();
        });
  }, 0);
  }

  public captureScreenshot(): Promise<string> {
    return new Promise((resolve) => {
      html2canvas(document.body).then(canvas => {
        resolve(canvas.toDataURL());
      });
    });
  }

  public analyticsConstruction() {
      this.loading = true;
      this.analyticsConstructionShow = true;
  }

  public closeConstruction() {
    this.loading = false;
    this.analyticsConstructionShow = false;
  }

  public ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }
}
