import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Title } from '../../models/title';
import { HomeService } from '../../services/home.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SupportService } from '../../services/support.service';
import { AuthService } from '../../services/auth.service';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { WhiteLabelApiService } from "./../../services/white-label-api"
import { UploadFileToS3 } from '../../helpers/uploadFileToS3';

@Component({
  selector: 'support',
  styleUrls: ['./support.component.scss'],
  templateUrl: './support.component.html',
})
export class SupportComponent implements OnInit, OnDestroy {
  public msgs: Message[] = [];
  private user: any = null;
  public loading: boolean = true;
  private isSubmitted: boolean = false;
  private displayPreFilledRows: boolean = false;
  public supportForm: UntypedFormGroup = new UntypedFormGroup({});
  private routeSubscription: Subscription = null;
  private authSubscription: Subscription = null;
  @Input() screenshotData: string;
  @Input() requestLocation: string;
  @Output() closeSupportEvent = new EventEmitter<void>();

  public helpBody: string;
  public helpHeader: string;
  public helpAddress: string;
  public helpPhone: string;
  public helpEmail: string;
  public helpPage: string;
  public tosPage: string;
  public privacyPage: string;
  public pageTitle: string;
  public masthead: string;


  constructor(
    private homeService: HomeService,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private supportService: SupportService,
    private route: ActivatedRoute,
    public whiteLabelApi: WhiteLabelApiService,
    private uploadFileToS3: UploadFileToS3,
  ) {
    // setTimeout(() => {
    //   this.homeService.setTitle(new Title({
    //     name: 'Support', page: 'support', text: 'Contact us',
    //   }));
    // }, 0);
  }

  public ngOnInit() {
    this.helpBody = this.whiteLabelApi.helpBody
    this.helpAddress = this.whiteLabelApi.helpAddress;
    this.helpEmail = this.whiteLabelApi.helpEmail;
    this.helpPage = this.whiteLabelApi.helpPage;
    this.helpPhone = this.whiteLabelApi.helpPhone;
    this.helpHeader = this.whiteLabelApi.helpHeader;
    this.masthead = this.whiteLabelApi.masthead;
    this.privacyPage = this.whiteLabelApi.privacyPage;
    this.tosPage = this.whiteLabelApi.tosPage;
    this.pageTitle = this.whiteLabelApi.pageTitle;
    this.user = this.authService.user;
    this.authSubscription = this.authService.user$.subscribe(
      (res: any) => {
        this.user = res;
      });
    this.routeSubscription = this.route.queryParams.subscribe(
      (res: any) => {
        if (res && res.preFilled) {
          this.displayPreFilledRows = true;
        }
      });
    this.supportForm = this.formBuilder.group({
      firstName: new UntypedFormControl({ value: this.user.first_name, disabled: true }),
      lastName: new UntypedFormControl({ value: this.user.last_name, disabled: true }),
      email: new UntypedFormControl({ value: this.user.email, disabled: true }),
      subject: new UntypedFormControl(null, Validators.required),
      request: new UntypedFormControl(null, Validators.required),
      screenshotData: new UntypedFormControl(null),
      requestLocation: new UntypedFormControl(null),
    });
    if (this.displayPreFilledRows) {
      this.supportForm.controls['subject'].setValue('Need more experiences');
      this.supportForm.controls['request'].setValue('Dear support team. I am currently having the limit of 2 experiences, but I need to extend it to 5');
    }
    this.loading = false;
  }

  public closeSupport() {
    this.closeSupportEvent.emit();
  }

  public async onSubmitSend(form) {
    this.isSubmitted = true;
    if (this.screenshotData) {
      const key = `support/screenshot/${Date.now()}.png`;

      // Convert the screenshot data URL to a Blob
      const response = await fetch(this.screenshotData);
      const screenshotBlob = await response.blob();

      const screenshot = await this.uploadFileToS3.uploadFileForMarkerless(key, screenshotBlob);
      form['screenshotData'] = screenshot.Location;
    }
    form['requestLocation'] = this.requestLocation;
    if (form['email'] == 'd.dubina@hqsoftwarelab.com') {
      form['email'] = 'p.thomas@realityblu.com';
    }
    this.supportService.sendSupportMessage(true, form).subscribe(
      (res: any) => {
        if (res.message && res.message === 'success') {
          this.messageService.add({severity:'success', summary:'Success!', detail: 'Message was sent. Thank you' });
          this.closeSupport();
        } else {
          this.messageService.add({severity:'error', summary:'Error', detail: 'Something is wrong. Try again' });
        }
        this.isSubmitted = false;
      },
    );
  }

  public ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
}
