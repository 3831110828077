import { AfterViewChecked, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { Title } from '../../models/title';
import { AuthService } from '../../services/auth.service';
import { HomeService } from '../../services/home.service';
import { CampaignService } from '../../services/campaign.service';
import { LoginService } from '../../services/login.service';
import { SupportService } from '../../services/support.service';
import { MessageService } from 'primeng/api';
import { Message } from 'primeng/api';
import { WhiteLabelApiService } from './../../services/white-label-api'
import { environment } from '../../../environments/environment';

@Component({
  selector: 'home',
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.component.html',
  host: { '(window:scroll)': 'onScroll()', '(window:resize)': 'onScroll()' },
})

export class HomeComponent implements OnInit, OnDestroy, AfterViewChecked {
  public msgs: Message[] = [];
  public loading: boolean = false;
  private user: any = null;
  private pagePermissions: any = null;
  public isErrorMode: boolean = false;
  public showAcceptPopup: boolean = false;
  private acceptedConditions: boolean = false;
  public copyright = new Date().getFullYear();
  private timerIdle: number = (environment.ENV === 'dev' || environment.ENV === 'test') ? 15 : 120;
  private errorSubscription: Subscription = null;
  private noInternetSubscription: Subscription = null;
  private authSubscription: Subscription = null;
  private pagePermissionsSubscription: Subscription = null;
  private titleSubscription: Subscription = null;
  private idleSubscription: Subscription = null;
  public acceptPages: any = [
    'campaigns', 'accounts', 'instances', 'media-experiences', 'media', 'markerbased-ar', 'markerbased-webar', 'markerless-ar', 'markerless-webar', 'reporting'
  ];
  public masthead: string;
  public privacy: string;
  public tos: string;
  public title: Title = new Title({
    name: 'Home', accounts_count: null, users_count: null,
    rolesCount: null, itemsCount: null, state: null, page: 'home',
    experiences_count: null,
  });

  constructor(
    private location: Location,
    private loginService: LoginService,
    private authService: AuthService,
    private messageService: MessageService,
    private homeService: HomeService,
    private campaignService: CampaignService,
    private supportService: SupportService,
    public whiteLabelApi: WhiteLabelApiService,
    @Inject(Idle) private idle: Idle,
  ) {
    this.idle.setIdle(60);
    this.idle.setTimeout(60 * this.timerIdle);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idleSubscription = this.idle.onTimeout.subscribe(
      () => {
        this.authService.idleTimeout = true;
        this.idle.stop();
        this.idle.ngOnDestroy();
        this.authService.clearTokenNavigate('login');
      });
    this.idle.watch();
  }

  public ngOnInit() {
    this.masthead = this.whiteLabelApi.masthead
    this.privacy = this.whiteLabelApi.privacyPage
    this.tos = this.whiteLabelApi.tosPage
    this.user = this.authService.user;
    if (localStorage.getItem('user')) {
      !JSON.parse(localStorage.getItem('user')).accept_terms_time ? this.showAcceptPopup = true : this.showAcceptPopup = false;
    } else {
      this.showAcceptPopup = true;
    }
    this.authSubscription = this.authService.user$.subscribe(
      (res: any) => {
        this.user = res;
      });
    this.titleSubscription = this.homeService.title$.subscribe(
      (res: any) => {
        this.title = res;
      });
    this.pagePermissionsSubscription = this.homeService.pagePermissions$.subscribe(
      (res: any) => {
        this.pagePermissions = res;
      });
    this.errorSubscription = this.homeService.isErrorMode$.subscribe(
      (res: any) => {
        this.isErrorMode = res;
      });
    this.noInternetSubscription = this.homeService.noInternetConnectionMode$.subscribe(
      () => {
        this.messageService.add({severity:'warn', summary:'Internet connection', detail: 'There is no Internet connection' });
      });
  }

  public ngAfterViewChecked() {
    this.onScroll();
  }

  private showCampaignPopup() {
    this.campaignService.showSource.next(true);
  }

  private acceptConditionsPopup() {
    this.loading = true;
    this.supportService.acceptConditions(new Date()).subscribe(
      (res: any) => {
        if (res.message && res.message === 'success') {
          this.showAcceptPopup = false;
          this.authService.updateToken();
        } else {
          this.messageService.add({severity:'error', summary:'Error', detail: 'Something is wrong. Try again' })
        }
        this.loading = false;
      });
  }

  private onScroll = () => {
    const scrollHeight = Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight,
    );
    const scrollBottom = scrollHeight - window.pageYOffset - document.documentElement.clientHeight;
    const mainButtons = document.querySelector('.main-buttons');
    if (mainButtons) {
      if (scrollBottom > 60) {
        mainButtons['style'].setProperty('bottom', `20px`);
      } else if (scrollBottom > 0 && scrollBottom < 60) {
        mainButtons['style'].setProperty('bottom', `${80 - scrollBottom}px`);
      } else {
        mainButtons['style'].setProperty('bottom', '80px');
      }
    }
  };

  public ngOnDestroy() {
    this.idleSubscription.unsubscribe();
    this.idle.ngOnDestroy();
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
    if (this.noInternetSubscription) {
      this.noInternetSubscription.unsubscribe();
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.pagePermissionsSubscription) {
      this.pagePermissionsSubscription.unsubscribe();
    }
    if (this.titleSubscription) {
      this.titleSubscription.unsubscribe();
    }
  }
}
