import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ConfigService } from './config.service';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class LocationService {
  public location$: Observable<any> = null;
  private locationSource = new Subject<any>();
  public inputClearImport$: Observable<any> = null;
  public inputClearImportSource = new Subject<void>();

  constructor(
    private httpService: HttpClient,
    private configService: ConfigService,
    private authService: AuthService,
  ) {
    this.location$ = this.locationSource.asObservable();
    this.inputClearImport$ = this.inputClearImportSource.asObservable();
  }

  public getCampaignLocations(id, experienceId) {
    const url = this.configService.config.getCampaignLocations;
    this.httpService.get(url + id + '/' + experienceId).subscribe(
      (res: any) => {
        if (res.message && res.message === 'success') {
          res.data.forEach((value) => {
            value.radius = value.radius_value + ' ' + value.radius_units;
          });
        }
        this.locationSource.next(res);
      },
    );
  }

  public createLocation(res) {
    const url = this.configService.config.createCampaignLocation;
    return this.httpService.post(url, { token: this.authService.token, campaign_location: res });
  }

  public deleteCampaignLocation(id) {
    const url = this.configService.config.deleteCampaignLocation;
    return this.httpService.delete(url + id);
  }

  public updateCampaignLocationOrder(res) {
    const url = this.configService.config.updateCampaignLocationOrder;
    return this.httpService.put(url, { token: this.authService.token, campaign_location: res });
  }

  public updateCampaignLocation(res) {
    const url = this.configService.config.updateCampaignLocation;
    return this.httpService.put(url, {
      token: this.authService.token, campaign_location: {
        id: res.id, name: res.mName, address: res.mAddress, lt: res.mLat, ln: res.mLng,
        rang: res.mRang, radius_value: res.mRadius, radius_units: res.mUnits,
      },
    });
  }
}
