import { Injectable } from '@angular/core';

@Injectable()
export class BucketsService {
  public IDENTITY_POOL_ID_EU = 'eu-central-1:d6f282ea-26f1-4c22-b605-b9fcad786b6f';
  public IDENTITY_POOL_ID_US = 'us-east-1:836d5a3a-aff9-400e-936d-ea335aa93304';
  public IDENTITY_POOL_ID_US_PROD = 'us-east-2:1fd2313e-1ca9-45c9-b986-66e0b2e4af2c';
  public BUCKET_REGION_EU = 'eu-central-1';
  public BUCKET_REGION_US = 'us-east-1';
  public BUCKET_REGION_US_PROD = 'us-east-2';
}
