import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { AccountsService } from './accounts.service';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

@Injectable()
export class InstancesService {
  public instances: any = [];
  public instancesList: any = [];

  constructor(
    private httpService: HttpClient,
    private configService: ConfigService,
    private accountsService: AccountsService,
    private authService: AuthService,
  ) {
  }

  public getInstances() {
    const url = this.configService.config.getInstances;
    return this.httpService.get(url + this.authService.token).pipe(
      map((res: any) => {
        if (res.message && res.message === 'success') {
          this.instancesList = [];
          this.accountsService.accountsList = [];
          this.instances = res.data;
          this.instances.instances.forEach((itemIns: any) => {
            if (itemIns.instance_id) {
              this.instancesList.push({
                label: itemIns.instance_name,
                value: itemIns.instance_id,
                state: itemIns.instance_state,
              });
              InstancesService.sortData(this.instances.instances);
              itemIns.accounts.forEach((itemAcc: any) => {
                this.accountsService.accountsList.push({
                  value: itemAcc.account_id,
                  label: itemAcc.account_name,
                  state: itemAcc.account_state,
                  instanceId: itemIns.instance_id,
                  instanceName: itemIns.instance_name,
                });
                InstancesService.sortData(this.accountsService.accountsList);
              });
            }
          });
        }
        return res;
      }),
    );
  }

  private static sortData(data) {
    data.sort((name1, name2) => {
      if (name1.label < name2.label) {
        return -1;
      }
      if (name1.label > name2.label) {
        return 1;
      }
      return 0;
    });
  }
}
