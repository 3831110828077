<ng-template #yesNoModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="onCloseModal(false)">
      <span aria-hidden="true">x</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="white-space">{{message}}</p>
    <div class="text-right">
      <input type="button" class="btn btn-success mt-3 mr-3" value="No" ngbAutofocus (click)="onNo()"/>
      <input type="button" class="btn btn-danger mt-3" value="Yes" (click)="onYes()"/>
    </div>
  </div>
</ng-template>
