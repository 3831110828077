import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { HomeService } from '../services/home.service';
import { Router } from '@angular/router';
import { IsLoginRouteGuard } from '../guards/is-login';
import { NoLoginRouteGuard } from '../guards/no-login';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class ServerLocationInterceptor implements HttpInterceptor {
  constructor(
    // private injector: Injector,
    private homeService: HomeService,
    private routerService: Router,
    private isLoginRouteGuard: IsLoginRouteGuard,
    private noLoginRouteGuard: NoLoginRouteGuard,
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.checkPath(request.url.split('/'), request.url) && localStorage.getItem('token')) {
      request = request.clone({ setHeaders: { Authorization: localStorage.getItem('token') }});
    }
    return next.handle(request).pipe(
      tap((event: HttpResponse<any>) => {
        if (event.body && event.body.error && event.body.error.code === 19) {
          this.isLoginRouteGuard.setIsLogin(false);
          this.noLoginRouteGuard.setNoLogin(false);
          localStorage.clear();
          this.routerService.navigate(['/login'], { replaceUrl: true });
        } else {
          if (event instanceof HttpResponse && (!Object.keys(event.body).length || event.body.cause)) {
            return {
              ...event,
              body: {
                message: 'error',
                metadata: {
                  message: 'Something is wrong. Please try again.',
                },
                error: {
                  message: 'Something is wrong. Please try again.',
                },
              },
            };
          }
        }
        return event;
      }),
      catchError((error) => {
        if (!window.navigator.onLine) {
          this.homeService.noInternetConnectionModeSource.next();
        } else if ((error.status / 100) >= 5) {
          this.homeService.isErrorModeSource.next(true);
        }
        return throwError(error);
      }),
    );
  }

  private checkPath(splitUrl, url) {
    const arrays = [['login', 'checkPassRequest', 'changePassRequest', 'setPass', 'www.googleapis.com', 'youtube']];
    const hosts = ['.amazonaws.com'];
    const hostsResult = hosts.filter((host) => {
      return url.indexOf(host) !== -1;
    });
    if (hostsResult.length > 0) {
      return true;
    }
    arrays.push(splitUrl);
    const result = arrays.shift().filter((v) => {
      return arrays.every((a) => {
        return a.indexOf(v) !== -1;
      });
    });
    return result.length > 0;
  }
}
