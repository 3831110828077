import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable()
export class WebarAppService {

  constructor(
    private httpService: HttpClient,
    private configService: ConfigService,
  ) {
  }

  public getWebarApps() {
    const url = this.configService.config.getWebarApps;
    return this.httpService.get(url);
  }

  public assignWebarApp(app) {
    const url = this.configService.config.assignWebarApp;
    return this.httpService.post(url, app);
  }

  public unAssignWebarApp(app) {
    const url = this.configService.config.unAssignWebarApp;
    return this.httpService.post(url, app);
  }

  public createWebarApp(payload) {
    const url = this.configService.config.createWebarApp;
    return this.httpService.post(url, payload);
  }

  public updateWebarApp(app) {
    const url = this.configService.config.updateWebarApp;
    return this.httpService.put(url, app);
  }

  public deleteWebarApp(id) {
    const url = this.configService.config.deleteWebarApp;
    return this.httpService.delete(url + id);
  }
}
