<loader *ngIf="loading" [ngClass]="{page_loading: loading}"></loader>

<div class="panel">

  <div class="panel-content">
    <div class="panel-content-wrapper" >
      <div style = "text-align: left; position: relative; padding: 10px 0; background-color: #f8f9fa; border-bottom: 1px solid #e9ecef;">
        <h2 style="margin-left: 15px; padding: 5px;"> Support </h2>
        <!-- primeng x icon -->
        <i class="fa fa-times" aria-hidden="true" style="position: absolute; right: 15px; top: 15px; cursor: pointer; font-size: 18px;"
           (click)="closeSupport()"></i>
      </div>
      <div class="support">
        <div class="column">
          <form class="support-form" *ngIf="supportForm" [formGroup]="supportForm"
                (ngSubmit)="onSubmitSend(supportForm.getRawValue())">
            <div class="form-row" style="display: none;">
              <label class="required">
                <span>First name</span>
              </label>
              <div class="field">
                <input formControlName="firstName" type="text"/>
              </div>
            </div>
            <div class="form-row" style="display: none;">
              <label class="required">
                <span>Last Name</span>
              </label>
              <div class="field">
                <input formControlName="lastName" type="text"/>
              </div>
            </div>
            <div class="form-row" style="display: none;">
              <label class="required">
                <span>Email</span>
              </label>
              <div class="field">
                <input formControlName="email" type="text"/>
              </div>
            </div>
            <div class="form-row">
              <label class="required">
                <span>Subject</span>
              </label>
              <div class="field">
                <input type="text" formControlName="subject"
                       [ngClass]="{error : (supportForm.get('subject').touched && supportForm.get('subject').hasError('required'))}"/>
                <div class="validation-field"
                     *ngIf="supportForm.get('subject').touched && supportForm.get('subject').hasError('required')">
                  Subject is required
                </div>
                <i *ngIf="supportForm.get('subject').touched && supportForm.get('subject').hasError('required')"
                   class="fa fa-exclamation-triangle error-main-container" aria-hidden="true">
                </i>
              </div>
            </div>
            <div class="form-row">
              <label class="required">
                <span>Message</span>
              </label>
              <div class="field">
                <textarea type="text" formControlName="request"
                          [ngClass]="{error : (supportForm.get('request').touched && supportForm.get('request').hasError('required'))}">
                </textarea>
                <div class="validation-field"
                     *ngIf="supportForm.get('request').touched && supportForm.get('request').hasError('required')">
                  Message is required
                </div>
                <i *ngIf="supportForm.get('request').touched && supportForm.get('request').hasError('required')"
                   class="fa fa-exclamation-triangle error-main-container" aria-hidden="true">
                </i>
              </div>
            </div>
            <div class="btns-row">
              <loader *ngIf="isSubmitted" class="string-loading login-loader"></loader>
              <button type="button" class="btn p-button-rounded"
                      [disabled]="supportForm.invalid || isSubmitted" (click)="onSubmitSend(supportForm.getRawValue())">Send</button>
            </div>
          </form>
        </div>
        <div class="column">
          <h2>{{ helpHeader}}</h2>
          <p> {{ helpBody }}
          </p>
          <div class="contacts">
            <div class="row">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
              <span class="text">{{ helpAddress}}</span>
            </div>
            <div class="row">
              <a href="tel:{{ helpPhone }}">
                <i class="fa fa-phone" aria-hidden="true"></i>
                <span class="text">{{ helpPhone }}</span>
              </a>
            </div>
            <div class="row">
              <a href="mailto:{{ whiteLabelApi.helpEmail }} ">
                <i class="fa fa-at" aria-hidden="true"></i>
                <span class="text">{{ helpEmail}}</span>
              </a>
            </div>
            <div class="row">
              <a [href]="whiteLabelApi.helpPage" target="_blank">
                <i class="fa fa-home" aria-hidden="true"></i>
                <span class="text">{{ helpPage }}</span>
              </a>
            </div>
          </div><br><br>
          <div style="display: none">
            <h2> Screenshot: </h2>
            <img [src]="screenshotData" alt="Screenshot" *ngIf="screenshotData" style="width: 100%; height: auto;">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
