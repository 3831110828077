import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ConfigService } from './config.service';
import { WorkflowService } from './workflow.service';

@Injectable()
export class ExperiencesService {
  public experiences$: Observable<any> = null;
  public recentExperiences$: Observable<any> = null;
  private experiencesSource = new Subject<any>();
  private recentSource = new Subject<any>();
  public recognitionTypeQuery: HttpParams | null = null;

  constructor(
    private httpService: HttpClient,
    private configService: ConfigService,
    private workflow: WorkflowService,
  ) {
    this.experiences$ = this.experiencesSource.asObservable();
    this.recentExperiences$ = this.recentSource.asObservable()
  }

  public getExperiences(id, categories) {
    const workflowValue = this.workflow.getWorkflow();
    this.recognitionTypeQuery = new HttpParams().set('recognitionType', workflowValue);
    const url = this.configService.config.getExperiencesWithoutCampaign;
    let params = '?';
    if (id) {
      if (categories !== '') {
        params = params + `accountId=${id}${categories}`;
      } else {
        params = params + `accountId=${id}`;
      }
    } else {
      params = params + `limit=10`;
    }
    this.httpService.get(url + params, !!workflowValue && { params: this.recognitionTypeQuery }).subscribe(
      (res: any) => {
        this.experiencesSource.next(res);
      });
  }

  public getAllRecent(id, categories) {
    const workflowValue = this.workflow.getWorkflow();
    this.recognitionTypeQuery = new HttpParams().set('recognitionType', workflowValue);
    const url = this.configService.config.getRecent;
    let params = '?';
    if (id) {
      if (categories !== '') {
        params = params + `accountId=${id}${categories}`;
      } else {
        params = params + `accountId=${id}`;
      }
    } else {
      params = params + `limit=10`;
    }
    this.httpService.get(url + params, !!workflowValue && { params: this.recognitionTypeQuery }).subscribe(
      (res: any) => {
        this.recentSource.next(res);
      });
  }

  public getReport() {
    const url = this.configService.config.getReport;
    return this.httpService.get(url);
  }
}
