import { UntypedFormGroup } from '@angular/forms';
import { Message } from 'primeng/api';
import { Injectable } from "@angular/core";

@Injectable()
export class LocationsHelper {
  public msgs: Message[] = [];
  public campaign: any = null;
  public loading: boolean = false;
  public contentLoading: boolean = false;
  public campaignPermissions: any = null;
  public experienceID: any = null;
  public locationsList: any = [];
  public createLocation: boolean = false;
  public manageLocation: boolean = false;
  public showLocation: boolean = false;
  public deletingLocationsPopup: boolean = false;
  public errorTextDeletingLocation: any = [];
  public addressAutocompleteManageInvalid: boolean = false;
  public addressAutocompleteCreateInvalid: boolean = false;
  public createFormLocation: UntypedFormGroup = null;
  public manageFormLocation: UntypedFormGroup = null;
  public showFormLocation: UntypedFormGroup = null;
}
